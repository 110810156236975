import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';
import Scroll from '../modules/Scroll';

import guardianBackground from "../assets/images/splash/desktop_bg.jpg"
import guardianBackgroundMobile from "../assets/images/splash/mobile_bg.jpg"
import guardianTwo from "../assets/images/Banner_Guardians_Pics.png"

import '../App.css';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Spinner from '../elements/Spinner';
import Span from '../elements/Span';
import Modal from '../elements/Modal';

import Div from './Div';
import SplashSocialMediaIcons from './SplashSocialMediaIcons';
import SplashFooter from './SplashFooter';

import afterpartyLogoOnly from "../assets/images/splash/AP Logo - Icon 4.png"
import menuImage from "../assets/images/splash/Menu.png"

import fourHeaderBackgroundImage from "../assets/images/splash/FOUR2x.jpg"
import platformHeaderImage from "../assets/images/splash/Phones 1.png"
import oneAfterpartyLogo from "../assets/images/splash/Group 171.png"
import creatorToFanConnectionImage from "../assets/images/splash/Phones 2.png"
import creatorToFanConnectionGradient from "../assets/images/splash/Gradient.png"
import creatorToFanConnectionGallery from "../assets/images/splash/Fest pics.png"
import afterpartyCreatorPassGradient from "../assets/images/splash/Gradient. orange.png"
import afterpartyCreatorPassImage from "../assets/images/splash/Pass.png"
import afterpartyTokenImage from "../assets/images/splash/Party icon.png"
import oneAfterpartyFestival from "../assets/images/splash/Universe_ Fest 2.png"
import oneAfterpartyVideoCall from "../assets/images/splash/Universe_ Platform 2.png"

export default class SplashMarketplace2 extends React.Component {
    constructor(props) {
        super(props);
        this.childDiv = React.createRef();

        this.state = {
            selected: "afterparty",
            drops: [],
            recent: [],
            includes: {},
        };
        Api.getDrops(this.processMusic.bind(this));
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        this.setState({selected: pr.route ? pr.route : 'afterparty'}, () => {
            this.handleRoute(st.selected);
        });
        if(pr.includes) {
            this.setState({includes: pr.includes});
        }
        Scroll.handleScroll.bind(this)();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const selected = nextProps.route ? nextProps.route : 'afterparty';
        //console.log("INCLUDES2", nextProps.includes);
        let tempState = {};
        if(nextProps.includes) {
            tempState['includes'] = nextProps.includes;
        }
        if(this.state.selected != selected) {
            tempState['selected'] = selected;
        }
        // if(Utils.get(nextProps, "includes.presale") && !Utils.getRequest('presale')) {
        //     tempState['showPresale'] = Utils.get(nextProps, "includes.presale");
        // }
        this.setState(tempState);
    }

  processMusic(response, error) {
      const pr = this.props;
      const st = this.state;

      if(!response) {
          console.log("No response to process >>>");
          return;
      }
      let tempState;
      let dropCollections = [{id:1000},{id:1001}]; //response["data"]["drops"].slice(0,2);
      dropCollections[0]['title'] = "Guardians collection";
      dropCollections[0]['link'] = "/guardian";
      //dropCollections[0]['image_url'] = "/images/utopians/guardian_5171.jpg";
      dropCollections[0]['image_url'] = "/images/utopians/guardian_1000.jpg";
      dropCollections[1]['link'] = "/utopian";
      dropCollections[1]['title'] = "Utopians collection";
      dropCollections[1]['image_url'] = "/images/utopians/04ebe05f8bf3c1c2f5630dfe51b1e1a4.jpg";

      tempState = {
          drops: dropCollections,
          recent: response["data"]["recent"],
      };
      this.setState(tempState);
      return;
      let drops = response["data"]["drops"];
      var now = moment();
      for(var idx in drops) {
          var drop = drops[idx];
          //const secs = Math.floor(Math.random() * (drop["id"] * 16000));
          var then = moment(drop['drop_date_begin']); //moment().add(secs, 'seconds');
          if(now.isBefore(then)) {
              //drop["until"] = moment().to(then);
              drop["until"] = this.calcTimeStr(now, then);
          } else {
              drop["remaining"] = this.calcTimeStr(now, moment(drop['drop_date_end'])); //moment().to(moment(drop['drop_date_end']));
          }
          //console.log("WHEN", moment().to(then));

      }
      console.log("mediaItem response >>>", response);
  }



    handleRoute(selected) {
    }

    handleArtistClick(item) {
        let nftUrl = '/nft/'+item['name']+'-'+item['crc'];
        if(item['link']) {
            nftUrl = item['link'];
        }
        console.log("nftUrl", nftUrl);
        this.props.history.push(nftUrl);
        //this.change('event', event);
    }

    newTab(link) {
        window.open(link, "_blank");
        //this.props.history.push(`/${link}`);
    }

    newPage(link) {
        this.props.history.push(`/${link}`);
    }

    renderNewNfts(collection, portrait) {
        const pr = this.props;
        const st = this.state;
        //const collection = this.state.drops;
        //const collection = pr.user.items;
        const imageContainerStyle = portrait ? this.styles.artistTileImagePortaitContainer : this.styles.artistTileImageContainer;
        const imageStyle = portrait ? this.styles.artistTileImagePortrait : this.styles.artistTileImage;
        const sectionHeight = portrait ? 200 : 130;
        return (
            <Div>
                <Div style={{display:"flex", width:"100%",position:"relative", height:sectionHeight}}>
                    <Div style={this.styles.artistTilesContainer}>
                    {collection.map( (o) => {
                        return <Div key={`nftid-${o.id}`} style={this.styles.artistTile} onClick={this.handleArtistClick.bind(this, o)}>
                                <Div style={imageContainerStyle}>
                                    <Image style={imageStyle} src={o.image_url} />
                                </Div>
                                <Div style={this.styles.artistTileTitle}>{o.title}</Div>
                                { o.id == this.state.selectedArtistId ?
                                    <Div style={this.styles.artistTileCheck}>
                                        <i className="fa fa-check" />
                                    </Div>
                                    :
                                    null
                                }
                        </Div>
                    })}
                    </Div>
                </Div>
            </Div>
        );
    }

    renderHeaderFourSection() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const headerFourSection = StyleUtils.getMediaStyle('headerFourSection', sty.headerFourSection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const headerFourInfoContainer = StyleUtils.getMediaStyle('headerFourInfoContainer', sty.headerFourInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const oneAfterpartyLogoStyle = StyleUtils.getMediaStyle('oneAfterpartyLogoStyle', sty.oneAfterpartyLogoStyle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const fourMainTitle = StyleUtils.getMediaStyle('fourMainTitle', sty.fourMainTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const fourMainSubtitle = StyleUtils.getMediaStyle('fourMainSubtitle', sty.fourMainSubtitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="headerFourSection" style={headerFourSection}>
                <Div className="headerFourInfoContainer" style={headerFourInfoContainer}>
                    <Image
                        src={oneAfterpartyLogo}
                        style={oneAfterpartyLogoStyle}
                    />
                    <Div className="fourMainTitle" style={fourMainTitle}>
                        Creator to consumer platform
                    </Div>
                    <Div className="fourMainSubtitle" style={fourMainSubtitle}>
                        End to end toolset for creators to launch NFTs and connect with their fans
                    </Div>
                    <Div className="fourMainSubtitle" style={fourMainSubtitle}>
                        Join the community
                    </Div>

                    <SplashSocialMediaIcons
                        size={40}
                    />
                </Div>
                <Image
                    src={platformHeaderImage}
                    style={sty.platformHeaderImageStyle}
                />
            </Div>
        )
    }

    renderCreatorToFanConnection() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const creatorToFanConnection = StyleUtils.getMediaStyle('creatorToFanConnection', sty.creatorToFanConnection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const creatorToFanConnectionDescription = StyleUtils.getMediaStyle('creatorToFanConnectionDescription', sty.creatorToFanConnectionDescription, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="creatorToFanConnection" style={creatorToFanConnection}>
                <Div className="creatorToFanConnectionInner" style={sty.creatorToFanConnectionInner}>
                    <Div className="creatorToFanConnectionTitle" style={sty.creatorToFanConnectionTitle}>
                        Creator To Fan Connection
                    </Div>
                    <Div className="creatorToFanConnectionDescription" style={creatorToFanConnectionDescription}>
                        Afterparty’s NFT marketplace and streaming platform allows for direct fan to creator connection, while providing an end to end toolset for creators to launch NFTs. Afterparty will be collaborating with some of the biggest names in the crypto and entertainment space.
                    </Div>
                    <Image
                        src={creatorToFanConnectionImage}
                        style={sty.creatorToFanConnectionImageStyle}
                    />
                </Div>
            </Div>
        )
    }

    renderAfterpartyCreatorPass() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const afterpartyCreatorPassInner = StyleUtils.getMediaStyle('afterpartyCreatorPassInner', sty.afterpartyCreatorPassInner, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const afterpartyCreatorPassInfoLeft = StyleUtils.getMediaStyle('afterpartyCreatorPassInfoLeft', sty.afterpartyCreatorPassInfoLeft, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="afterpartyCreatorPass" style={sty.afterpartyCreatorPass}>
                <Div className="afterpartyCreatorPassInner" style={afterpartyCreatorPassInner}>
                    <Div className="afterpartyCreatorPassInfoLeft" style={afterpartyCreatorPassInfoLeft}>
                        <Div className="afterpartyCreatorPassTitle" style={sty.afterpartyCreatorPassTitle}>
                            Afterparty Creator Pass
                        </Div>
                        <Div className="afterpartyCreatorPassDescription" style={sty.afterpartyCreatorPassDescription}>
                            Afterparty is working with some of the most influential creators in the world developing exclusive NFT collections that serve as a passport to that creators world. The first collections will be dropping shortly.
                        </Div>
                    </Div>

                    <Image
                        className="afterpartyCreatorPassImage"
                        src={afterpartyCreatorPassImage}
                        style={sty.afterpartyCreatorPassImageStyle}
                    />
                </Div>
            </Div>
        )
    }

    renderAfterpartyToken() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const afterpartyToken = StyleUtils.getMediaStyle('afterpartyToken', sty.afterpartyToken, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const afterpartyTokenInner = StyleUtils.getMediaStyle('afterpartyTokenInner', sty.afterpartyTokenInner, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const afterpartyTokenInfoRight = StyleUtils.getMediaStyle('afterpartyTokenInfoRight', sty.afterpartyTokenInfoRight, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="afterpartyToken" style={afterpartyToken}>
                <Div className="afterpartyTokenInner" style={afterpartyTokenInner}>
                    <Image
                        className="afterpartyTokenImage"
                        src={afterpartyTokenImage}
                        style={sty.afterpartyTokenImageStyle}
                    />
                    <Div className="afterpartyTokenInfoRight" style={afterpartyTokenInfoRight}>
                        <Div className="afterpartyTokenTitle" style={sty.afterpartyTokenTitle}>
                            Afterparty Token
                        </Div>
                        <Div className="afterpartyTokenDescription" style={sty.afterpartyTokenDescription}>
                            Afterparty belongs to its community of creators and we are building towards that creator-controlled future with the $<Span style={{fontWeight: "bold"}}>PARTY</Span> token.
                        </Div>
                    </Div>
                </Div>
            </Div>
        )
    }

    renderBanner() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const containerStyle = 'banner';

        const container = StyleUtils.getMediaStyle(containerStyle, this.styles[containerStyle], sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div style={this.styles.bannerBackground}>
                <Div style={{flex:4}}>
                    <Image style={{width:"100%",maxHeight:200,maxWidth:200,}} src={guardianTwo} />
                </Div>
                <Div style={this.styles.guardianHeadline}>
                    <Div style={{marginLeft:30}}>The Guardians Collection</Div>
                </Div>
            </Div>
        )
    }

    renderTopDrops() {
        return (
            <Div style={{margin:20, fontSize: 14, marginTop:50}}>
                <Div style={this.styles.sectionHeadline}>Top Drops This Week</Div>
                <Div>
                    {this.renderNewNfts(this.state.drops, true)}
                </Div>
            </Div>
        )
    }

    renderRecentListings() {
        return (
            <Div style={{margin:20, fontSize: 14, marginTop:50,marginBottom:200}}>
                <Div style={this.styles.sectionHeadline}>Recent Listings</Div>
                <Div>
                    {this.renderNewNfts(this.state.recent)}
                </Div>
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <div ref={this.childDiv} className="afterpartyStaticHomeComponent" style={sty.container}>
                <Div style={{height:120}} />
                {this.renderBanner()}
                {this.renderTopDrops()}
                {true ?
                    this.renderRecentListings()
                    :
                    null
                }

                <SplashFooter
                    screen={pr.screen}
                    history={pr.history}
                />
            </div>
        );
    }

    styles = {
        container: {
            backgroundColor: "#0F0420",
        },
        banner: {
            // display: "inline-block",
            // position: "relative",
            backgroundImage: `url("${guardianBackground}")`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            width: "100%",
            color: "white",
            padding: 28,
            flexWrap: "wrap",
            minHeight: "100vh",
        },
        banner_md: {
            backgroundImage: `url("${guardianBackgroundMobile}")`,
            backgroundPosition: "bottom",
        },
        bannerBackground: {
            display: "flex",
            height:167,
            overflow: "hidden",
            backgroundImage: `url(/images/Orange_Background.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
        },
        guardianHeadline: {
            flex: 6,
            color: "white",
            fontFamily: "CA Mystery Girl",
            fontWeight: 400,
            fontSize: 36,
            marginTop: 51,
            marginBottom: 10,
            lineHeight: "31px",
        },
        sectionHeadline: {
            color:"white",
            fontWeight:"bold"
        },
        artistTilesContainer: {
            width:"100%",position:"absolute",
            overflowX:"auto",
            overflowY:"hidden",display:"flex",
            background:"black",borderRadius:20,
        },
        artistTile: {
            textAlign:"center",
            paddingTop: 10,
            paddingLeft: 10,
            position: "relative",
        },
        artistTileCheck: {
            width:16,
            height:16,
            borderRadius:8,
            backgroundColor:"red",
            position:"absolute",
            right: 10,
            top: 16,
            color:"white",
            fontSize: 11,
        },
        artistTileImageContainerCheck: {
            overflow: "hidden",
            boxSizing: "border-box",
            border: "1px solid red",
            margin:6,
            width:64,
            height:64,
            borderRadius:64,
            backgroundColor:"orange"
        },
        artistTileImageContainer: {
            overflow: "hidden",
            margin:6,
            width:128,
            //height:128,
            borderRadius:12,
            //backgroundColor:"orange"
        },
        artistTileImage: {
            width:128,
            height:128,
        },
        artistTileImagePortaitContainer: {
            overflow: "hidden",
            margin:6,
            width:128,
            height:208,
            borderRadius:12,
            //backgroundColor:"orange"
        },
        artistTileImagePortrait: {
            width:128,
        },
        artistTileTitle: {
            color: "white",
            position: "absolute",
            bottom: 16,
            left: 23,
            fontWeight: "bold",
            fontSize:14,
        },
    };
}
