import Utils from "./Utils";

export default {
    setHtmlScroll(canScroll, elementName = "html") {
        if(canScroll) {
            $(elementName).css("overscroll-behavior", "auto");
        } else {
            $(elementName).css("overscroll-behavior", "none");
        }
    },
    scrollChatboxBottom(elementSelector) {
        const chatboxWindow = $(elementSelector);

        chatboxWindow.animate({ scrollTop: chatboxWindow.prop("scrollHeight") }, 500);
    },
    scrollChatboxTopMessage(elementSelector1, elementSelector2) {
        const chatboxWindow = $(elementSelector1);
        const lastItem = $(elementSelector2).last();

        if (Utils.empty(chatboxWindow)) {
            return;
        }

        if(Utils.empty(lastItem)) {
            this.scrollChatboxBottom(chatboxWindow);
            return;
        }
        const chatboxWindowOffset = chatboxWindow.offset();
        const lastItemOffset = lastItem.offset();
    
        if (!chatboxWindowOffset || !lastItemOffset) {
            return;
        }
    
        // Calculate the offset of the last item relative to the chatbox window
        const lastItemOffsetTop = lastItemOffset.top - chatboxWindowOffset.top;
    
        // Animate the scroll to the top of the last item
        chatboxWindow.animate({
            scrollTop: chatboxWindow.scrollTop() + lastItemOffsetTop - 10
        }, 500);
    },
    // scroll = (event) => {
    scrollInput(event) {
        const pr = this.props;
        if(!pr.screen.mobile) {
            return null;
        }
        const inputElement = $(event.target);
        const inputOffsetTop = inputElement.offset().top;
        const inputHeight = inputElement.outerHeight();
        const windowHeight = $(window).height();
        
        // Calculate the position to scroll so the input is at the bottom of the screen
        const scrollPosition = inputOffsetTop - windowHeight + inputHeight + 20; // 20 pixels for some padding
        
        $("html, body").animate({
            scrollTop: scrollPosition
        }, 250);
    },
    handleScroll() {
        const { index, selected } = this.props;
        const _self = this;
        if (true || index === selected && _self.childDiv) {
          setTimeout(() => {
            console.log('childDiv', _self.childDiv);
            if (_self.childDiv.current) {
              _self.childDiv.current.scrollIntoView({ behavior: 'smooth' });
            }
          }, 500);
        }
    },
    scrollToLeaderboard() {
        if (Utils.getRequest("leaderboard") == "scroll") {
            setTimeout(() => {
                document.getElementById('leaderboard').scrollIntoView({
                    behavior: 'smooth'
                });
                // TODO: remove request param so upon refresh it doesn't scroll
            }, 1500);
        }
    }
}