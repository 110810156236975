import React, { Fragment } from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import StylesPlatform from '../modules/StylesPlatform';
import Scroll from '../modules/Scroll';

import '../App.css';

import Button from '../elements/Button';
import Image from '../elements/Image';
import Spinner from '../elements/Spinner';
import Span from '../elements/Span';
import Modal from '../elements/Modal';

import Div from './Div';
import RegisterFlow from './RegisterFlow';
import SplashFooter from './SplashFooter';

import afterpartyLogoOnly from "../assets/images/splash/AP Logo - Icon 4.png"
import menuImage from "../assets/images/splash/Menu.png"

import threeHeaderBackgroundImage from "../assets/images/splash/THREE2x.jpg"
import oneAfterpartyLogo from "../assets/images/splash/Group 171.png"
import festivalDate from "../assets/images/splash/festival date.png"
import threeFestivalInfoGradient from "../assets/images/splash/Graident 3.png"
import threeFestivalInfoGallery from "../assets/images/splash/Fest pics.png"
import oneAfterpartyFestival from "../assets/images/splash/Universe_ Fest 2.png"
import oneAfterpartyVideoCall from "../assets/images/splash/Universe_ Platform 2.png"
import openSeaLogo from "../assets/images/splash/open sea white logo.png"

export default class SplashFestival extends React.Component {
    constructor(props) {
        super(props);
        this.childDiv = React.createRef();
        this.state = {
            selected: "afterparty",

            showRegisterFlowModal: false,
            registerModalBody: "username",

            includes: {},
        };
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        this.setState({selected: pr.route ? pr.route : 'afterparty'}, () => {
            this.handleRoute(st.selected);
        });
        if(pr.includes) {
            this.setState({includes: pr.includes});
        }
        Scroll.handleScroll.bind(this)();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const selected = nextProps.route ? nextProps.route : 'afterparty';
        //console.log("INCLUDES2", nextProps.includes);
        let tempState = {};
        if(nextProps.includes) {
            tempState['includes'] = nextProps.includes;
        }
        if(this.state.selected != selected) {
            tempState['selected'] = selected;
        }
        // if(Utils.get(nextProps, "includes.presale") && !Utils.getRequest('presale')) {
        //     tempState['showPresale'] = Utils.get(nextProps, "includes.presale");
        // }
        this.setState(tempState);
    }

    handleRoute(selected) {
    }
    newTab(link) {
        window.open(link, "_blank");
        //this.props.history.push(`/${link}`);
    }

    newPage(link) {
        this.props.history.push(`/${link}`);
    }

    onClickSponsors() {
        const email = "sponsors@afterparty.ai";
        const subject = "Inquiry about Afterparty Festival Sponships";
        const body = "";
        let params = subject || body ? '?' : '';
        if (subject) params += `subject=${encodeURIComponent(subject)}`;
        if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
        window.open(`mailto:${email}${params}`, "_blank");
    }

    showLoginModalBody() {
        this.setState({
            showRegisterFlowModal: true,
            registerModalBody: "username"
        });
    }

    onRequestRegisterModalClose() {
        this.setState({
            showRegisterFlowModal: false,
            registerModalBody: "username",
        });
    }

    onClickEnterContest() {
        this.showLoginModalBody();
    }

    renderHeaderThreeSection() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const headerThreeSection = StyleUtils.getMediaStyle('headerThreeSection', sty.headerThreeSection, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const headerThreeInfoContainer = StyleUtils.getMediaStyle('headerThreeInfoContainer', sty.headerThreeInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const threeMainTitle = StyleUtils.getMediaStyle('threeMainTitle', sty.threeMainTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="headerThreeSection" style={headerThreeSection}>
                <Div className="headerThreeInfoContainer" style={headerThreeInfoContainer}>
                    <Image
                        src={oneAfterpartyLogo}
                        style={sty.oneAfterpartyLogoStyle}
                    />
                    <Div className="threeMainTitle" style={threeMainTitle}>
                        NFT art + music festival
                    </Div>
                    <Div className="threeMainSubtitle" style={sty.threeMainSubtitle}>
                        Las Vegas, NV
                    </Div>
                    <Image
                        src={festivalDate}
                        style={sty.festivalDateStyle}
                    />
                </Div>
            </Div>
        )
    }

    renderThreeFestivalInfo() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const threeFestivalInfo = StyleUtils.getMediaStyle('threeFestivalInfo', sty.threeFestivalInfo, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const threeFestivalInfoContainer = StyleUtils.getMediaStyle('threeFestivalInfoContainer', sty.threeFestivalInfoContainer, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const threeFestivalInfoTitle = StyleUtils.getMediaStyle('threeFestivalInfoTitle', sty.threeFestivalInfoTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const threeFestivalInfoDescription = StyleUtils.getMediaStyle('threeFestivalInfoDescription', sty.threeFestivalInfoDescription, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const festivalButton = StyleUtils.getMediaStyle('festivalButton', sty.festivalButton, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="threeFestivalInfo" style={threeFestivalInfo}>
                <Div className="threeFestivalInfoContainer" style={threeFestivalInfoContainer}>
                    <Div
                        className="threeFestivalInfoSubtitle"
                        style={sty.threeFestivalInfoSubtitle}
                    >
                        WHEN
                    </Div>
                    <Div
                        className="threeFestivalInfoTitle"
                        style={threeFestivalInfoTitle}
                    >
                        MARCH 18 + 19, <Span style={{fontFamily: "Fatient"}}>2022</Span>
                    </Div>

                    <Div
                        className="threeFestivalInfoSubtitle"
                        style={sty.threeFestivalInfoSubtitle}
                    >
                        WHERE
                    </Div>
                    <Div
                        className="threeFestivalInfoTitle"
                        style={threeFestivalInfoTitle}
                    >
                        LAS VEGAS, <Span style={{fontFamily: "Fatient"}}>NEVADA</Span>
                    </Div>

                    <Div
                        className="threeFestivalInfoDescription"
                        style={threeFestivalInfoDescription}
                    >
                        Afterparty NFT Art & Music Festival is the World's First NFT-Gated Festival NFT Art is a revolution and it deserves its own Coachella. An Art Basel with only Artist Level Access, where the 1,500 Afterparty Utopians are the only way in. Each Utopian gets a +1, and 2 NFT passes to invite or sell to anyone. There will be no tickets. Afterparty will continue to have one-of-a-kind intimate experiences at varying locations around the world and in the metaverse.Your Afterparty Utopian NFT is your access to it all.
                    </Div>

                    <Image
                        className="threeFestivalInfoGallery"
                        style={sty.threeFestivalInfoGallery}
                        src={threeFestivalInfoGallery}
                    />

                    <Button
                        className="inquireButton"
                        style={festivalButton}
                        onClick={this.onClickSponsors.bind(this)}
                    >
                        INQUIRE FOR SPONSORSHIPS
                    </Button>
                </Div>

            </Div>
        )
    }

    renderThreeFestivalGetAccess() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const threeFestivalGetAccess = StyleUtils.getMediaStyle('threeFestivalGetAccess', sty.threeFestivalGetAccess, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const getAccessTitle = StyleUtils.getMediaStyle('getAccessTitle', sty.getAccessTitle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        const threeFestivalGetAccessInner = StyleUtils.getMediaStyle('threeFestivalGetAccessInner', sty.threeFestivalGetAccessInner, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="threeFestivalGetAccess" style={threeFestivalGetAccess}>
                <Div className="threeFestivalGetAccessInner" style={threeFestivalGetAccessInner}>
                    <Div className="getAccessTitle" style={getAccessTitle}>
                        GET ACCESS
                    </Div>
                    <Div className="getAccessDescription" style={sty.getAccessDescription}>
                        {/* There are no tickets. the 1,500 Afterparty Utopians are the only way in. Each Utopian gets a +1, and 2 NFT passes to invite or sell to anyone. enter your info to be notified on the utopian drop. */}
                        There are no tickets. The 1,500 Afterparty Utopians are the only way in. Each Utopian gets Artist-Level All-Access for them and a +1 and 2 Afterparty Pass NFTs (GA access) to invite friends or sell on OpenSea.
                    </Div>

                    <Div className="getAccessButtons" style={{display: "flex", justifyContent: "center", marginBottom: 100, flexWrap: "wrap"}}>
                        <Button
                            style={{...sty.festivalButtonMagenta, ...{marginBottom: 15}}}
                            onClick={this.newTab.bind(this, "https://opensea.io/collection/afterparty-utopians")}
                        >
                            <Image src={openSeaLogo} style={{width: 35, marginRight: 12}}/> Buy a Utopian
                        </Button>
                        <Button
                            style={{...sty.festivalButtonMagenta, ...{marginBottom: 15}}}
                            onClick={this.newTab.bind(this, "https://opensea.io/collection/afterparty-pass")}
                        >
                            <Image src={openSeaLogo} style={{width: 35, marginRight: 12}}/> Buy An Afterparty Pass
                        </Button>
                    </Div>

                    <Div>
                        <Div className="getAccessTitle" style={{...getAccessTitle, ...{fontSize: 24}}}>
                            Additional Utopian Benefits
                        </Div>
                        <ul>
                            <li className="utopianBenefitsList" style={sty.utopianBenefitsList}>Artist Level All-Access to the Afterparty NFT Art & Music Festival Vegas for AT LEAST 5 years, plus access to "Utopian Only" Artist areas at the festival</li>
                            <li className="utopianBenefitsList" style={sty.utopianBenefitsList}>One (1) free Afterparty Guardian NFT airdropped</li>
                            <li className="utopianBenefitsList" style={sty.utopianBenefitsList}>Two (2) free Afterparty Passes airdopped</li>
                            <li className="utopianBenefitsList" style={sty.utopianBenefitsList}>Priority Guestlist for all upcoming Afterparty and Creator NFT drops</li>
                            <li className="utopianBenefitsList" style={sty.utopianBenefitsList}>Artist Level All Access +1 to Afterparty's Fall event in Los Angeles, plus access to "Utopian Only" Artist areas at the festival</li>
                            <li className="utopianBenefitsList" style={sty.utopianBenefitsList}>Access to selected events at the Afterparty house in Los Angeles</li>
                            <li className="utopianBenefitsList" style={sty.utopianBenefitsList}>Priority Access for Afterparty Pop-up events NFT.NYC, Art Basel Miami and more</li>
                            <li className="utopianBenefitsList" style={sty.utopianBenefitsList}>Surprise and Delight: Afterparty is going to continue to deliver world-class experiences and push the boundaries of innovation with our creator partners</li>
                        </ul>

                    </Div>

                </Div>
            </Div>
        )
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <div ref={this.childDiv} className="afterpartyStaticFestivalReactComponent" style={sty.afterpartyStaticFestivalReactComponent}>
                {this.renderHeaderThreeSection()}
                {this.renderThreeFestivalGetAccess()}
                {this.renderThreeFestivalInfo()}

                <SplashFooter
                    screen={pr.screen}
                    history={pr.history}
                    backgroundColor="black"
                />

                <RegisterFlow
                    user={pr.user}
                    screen={pr.screen}
                    usernameModalTitle={<Div>Enter the contest to win an Afterparty Utopian</Div>}
                    successModalMessage={<Div>Already have {Utils.get(pr, "eventItem.title")} social token?{Utils.get(pr, "screen.mobile") ? null : <br/>}</Div>}
                    registerModalBody={st.registerModalBody}
                    showRegisterFlowModal={st.showRegisterFlowModal}
                    eventItem={pr.eventItem}
                    isPhoneInput
                    eventType={4}
                    successMessageData={Utils.get(pr.eventItem, "json_data.successBody", {line1:"We'll notify you again as the Utopian contest approaches."})}
                    setUser={pr.setUser.bind(this)}
                    onRequestRegisterModalClose={this.onRequestRegisterModalClose.bind(this)}
                />
            </div>
        );
    }

    styles = {
        splashMagentaButton: {
            backgroundColor: Colors.magenta,
            color: Colors.splashDarkPurple,
            marginTop: 12,
            marginBottom: 12,
        },
        festivalButton: {
            fontFamily: "Proto Mono",
            fontSize: 18,
            backgroundColor: "none",
            border: "1px solid white",
            borderRadius: 200,
            paddingLeft: 90,
            paddingRight: 90,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 68,
            boxSizing: "border-box",
        },
        festivalButton_md: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        festivalButtonMagenta: {
            fontFamily: "Proto Mono",
            fontSize: 18,
            backgroundColor: Colors.magenta,
            borderRadius: 200,
            paddingLeft: 13,
            paddingRight: 35,
            marginLeft: 10,
            marginRight: 10,
            // marginTop: 68,
        },
        afterpartyStaticFestivalReactComponent: {
            backgroundColor: Colors.splashDarkPurple,
            color: "white",
        },
        headerThreeSection: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            height: 852,
            backgroundImage: `url("${threeHeaderBackgroundImage}")`,
            backgroundSize: "cover",
            backgroundPosition: "center bottom",
            backgroundRepeat: "no-repeat",
            padding: 20,
        },
        headerThreeSection_md: {
            backgroundPosition: "center bottom",
            backgroundImage: `url("${threeHeaderBackgroundImage}")`,
            height: 700,
        },
        headerThreeInfoContainer: {
            padding: 56,
            width: "100%",
            maxWidth: 870,
            width: "fit-content",
            borderRadius: 40,
            marginLeft: "auto",
        },
        headerThreeInfoContainer_md: {
            marginLeft: "auto",
            marginRight: "auto",
            padding: 0,
        },
        oneAfterpartyLogoStyle: {
            width: 254,
            marginBottom: 5,
        },
        threeMainTitle: {
            fontFamily: "Adina",
            fontWeight: 900,
            fontSize: 43,
            color: "white",
            maxWidth: 942,
            width: "100%",
            marginBottom: 20,
        },
        threeMainTitle_md: {
            fontSize: 32,
            lineHeight: "38px",
        },

        festivalDateStyle: {
            width: 297,
        },
        threeMainSubtitle: {
            fontFamily: "Adina Outlined",
            fontWeight: 400,
            color: "white",
            fontSize: 24,
            marginBottom: 20,
        },

        threeFestivalInfo: {
            width: "100%",
            minHeight: 1350,
            backgroundColor: "black",
            backgroundImage: `url("${threeFestivalInfoGradient}")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            padding: 10,
        },
        threeFestivalInfo_md: {
            padding: 20,

        },
        threeFestivalInfoContainer: {
            maxWidth: 1023,
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: 100,
            paddingBottom: 100,
        },
        threeFestivalInfoSubtitle: {
            fontFamily: "Proto Mono",
            color: "white",
            fontWeight: 100,
            fontSize: 20,
        },
        threeFestivalInfoTitle: {
            fontFamily: "Fatient",
            color: "white",
            fontSize: 48,
            paddingBottom: 35,
        },
        threeFestivalInfoTitle_md: {
            fontSize: 26,
        },
        threeFestivalInfoDescription: {
            fontFamily: "Proto Mono",
            color: "white",
            fontWeight: 100,
            fontSize: 18,
            marginTop: 30,
            marginBottom: 130,
        },
        threeFestivalInfoDescription_md: {
            fontSize: 15,
            marginBottom: 40,
        },
        threeFestivalInfoGallery: {
            width: "100%",
        },

        threeFestivalGetAccess: {
            paddingTop: 50,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 50,
            // background: "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(31,7,68,1) 100%)",
        },
        threeFestivalGetAccessInner: {
            maxWidth: 1023,
            marginLeft: "auto",
            marginRight: "auto",
            padding: 20,
        },
        threeFestivalGetAccessInner_md: {
            padding: 0,
        },
        getAccessTitle: {
            fontFamily: "Adina",
            fontSize: 36,
            color: "white",
            textAlign: "center",
            marginBottom: 30,
        },
        getAccessTitle_md: {
            fontSize: 24,
        },
        getAccessDescription: {
            fontFamily: "Proto Mono",
            fontSize: 18,
            color: "white",
            textAlign: "center",
            marginBottom: 45,
        },
        utopianBenefitsList: {
            fontFamily: "Proto Mono",
            fontSize: 16,
            color: "white",
            marginBottom: 15,
        },

        subTitleCard: {
            fontFamily: "Mont Demo",
            fontSize: 20,
            color: "white",
            textAlign: "center",
            marginBottom: 10,
        },
        oneAfterpartyUniverseImage: {
            width: "100%",
            marginBottom: 40,
        },
    };
}
