import React from 'react';
import { useEffect, useRef } from "react";
import Lottie from "lottie-react-web"

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import StylesPlatform from '../modules/StylesPlatform';
import Colors from '../modules/Colors';
import Constant from '../modules/Constant';
import Validate from '../modules/Validate';
import StyleUtils from '../modules/StyleUtils';
import Analytics from '../modules/Analytics';
import Scroll from '../modules/Scroll';

import Span from '../elements/Span';
import Input from '../elements/Input';
import Icon from '../elements/Icon';
import Button from '../elements/Button';
import Spinner from '../elements/Spinner';
import Modal from '../elements/Modal';
import Image from '../elements/Image';
import AudioChip from '../elements/AudioChip';
import WebappLoginRegister from '../elements/WebappLoginRegister';
import Checkbox from '../elements/Checkbox';
import ErrorBox from '../elements/ErrorBox';
import DebugBox from '../elements/DebugBox';
import EmailPhoneVerification from '../elements/EmailPhoneVerification';

import Post from './Post';
import Div from '../components/Div';

import moment from "moment";

import heroEventBanner from '../assets/afterparty-hero-event.jpg';
import heroEventBanner2 from '../assets/afterparty-event-hero-banner.png';
import giftImage from '../assets/images/giftImage.png';
import aiBadge from '../assets/images/ai-badge-2.png';
import aiCamera from '../assets/images/ai-camera.png';
import leaderboardTrophy from '../assets/images/leaderboard-trophy.png';
import leaderboardCloseButton from '../assets/images/leaderboard close button.png';
import chatBubbleLoad from '../assets/images/chat bubble load.png';
import waveformBig from '../assets/images/waveform-big.png';
import cameraGetPic from '../assets/images/camera-get-pic.png';
import cameraGetPicDisabled from '../assets/images/camera-get-pic-disabled.png';
import weclomePartyHorn from '../assets/images/party-horn.png';
import welcomePage2 from '../assets/images/welcome-page-2.png';
import welcomePage3 from '../assets/images/welcome-page-3.png';
import credits400 from '../assets/images/400-credits.png';
import credits100 from '../assets/images/100-credits.png';
import starsSurvey from '../assets/images/stars.png';
import getPicButton1 from '../assets/images/get-a-pic-btn-1.png';
import getPicButtonFrame from '../assets/images/get-a-pic-btn-frame.png';
import pwaNotificationsBell from '../assets/images/pwaNotificationsBell.png';
import pwaStep1 from '../assets/images/pwaStep1.png';
import pwaStep2 from '../assets/images/pwaStep2.png';
import pwaQrCode from '../assets/images/pwaQrCode.png';
import getPicButtonWelcome from '../assets/images/get-a-pic-button-welcome.png';
import startChatting from '../assets/images/start chatting.png';
import instantPremiumMessage from '../assets/images/instant-premium-message.png';

import lockWhite from '../assets/icons/lock-white.png';
import lockBlack from '../assets/icons/lock-black.png';
import photoRequest from '../assets/images/photorequest.png';
import infoIcon from '../assets/icons/info.png';
import waveformLottie from '../assets/waveform.json';
import chatLottie from '../assets/chatLottie.json';
import AfterpartyLogo from '../elements/AfterpartyLogo';
import Leaderboard from './Leaderboard';

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <Div ref={elementRef} />;
};

export default class AiChatbox extends React.Component {
    constructor(props) {
        super(props);

        let selectedConversationIdCookie;
        if(Utils.getRequest('artist_id', -1, false, true) == -1) {
            selectedConversationIdCookie = Utils.getCookie("selectedConversationId");
        }

        const artistId = Utils.getRequest('artist_id', -1, false, true);

        this.state = {
            value: '',
            wallet: null,
            mode: Utils.getRequest('host') == 1 ? 'host' : 'guest',
            post: '',
            messages: {},
            messagesHost: [],
            lastPost: '',
            posting: false,
            firstLoad: true,
            autoScroll: true,
            chatTabSelected: "fan",

            tempNewMessage: null,

            showSuperSendInfoModal: false,

            vipChatActivated: false,

            lastHostMessageId: 0,
            newHostMessageNotification: false,

            userCredits: Utils.get(props, "user.ap_token_balance"),

            artist: {
                id: Utils.getRequest('artist_id', -1, false, true),
                title: "",
                image_url: "",
            },

            artistConversations: [],
            allArtists: [],

            isArtist: true,

            aiChatIsLoading: false,

            // NOTE: MODAL BOOLEANS
            showCreateAccountModal: false,
            showCreateSignInModal: false,
            showEnterBirthdateModal: false,
            showCustomAiSelfieModal: false,
            showSendToArtistModal: false,
            showNoFollowsModal: false,
            showSurveyModal: true,
            showSurveySuccessModal: false,
            showFreeCreditsModal: true,

            showLeaderboardSidePanel: false,

            instantImageDict: {},

            buyModalType: "firstTimeBuyer",

            agreeToTerms: false,

            monthText: "",
            dayText: "",
            yearText: "",

            birthdateIsValid: false,
            birthdateErrors: [],

            showCreatorPanel: props.screen.mobile && artistId != -1 ? false : true,

            selectedCreatorIndex: 0,

            isMuted: Utils.getCookie("isMuted") == "true" ? true : false,

            conversationsDict: null,
            backendConversations: [],
            selectedConversationId: !Utils.empty(selectedConversationIdCookie) ? selectedConversationIdCookie : null,

            selectedArtist: null,
            showSelectPhotoModal: false,
            selectPhotoTypeIdx: 2,
            generatingImages: false,
            aiImageCount: 0,

            feedbackTypes: null,
            showImageFeedbackMenu: false,

            currentFeedbackMessageImageIndex: null,
            currentFeedbackMessage: null,

            showAiOfflineMessage: false,

            aiMessageCounter: 0,
            feedbackSurveyCounter: 0,
            aiPostSurveyNumber: Utils.getCookie("aiPostSurveyNumber") ?? Utils.setCookie("aiPostSurveyNumber", 1),
            surveryInteger1to5Option: [
                {
                    value: 1,
                    text: "😔",
                },
                {
                    value: 2,
                    text: "🙁",
                },
                {
                    value: 3,
                    text: "😐",
                },
                {
                    value: 4,
                    text: "😁",
                },
                {
                    value: 5,
                    text: "😍",
                },
            ],

            showWelcomeModal: Utils.getRequest("welcome"),
            welcomeModalStage: 1,

            showMoreCreditsModal: false,
            showNoCreditsModal: false,
            showThanksForFeedbackModal: false,
            showTurnOnNotificationsModal: false, //Utils.getRequest("welcome") == 1 || Utils.getRequest("test") == 1 ? false : true,

            platformIsIos: Utils.getOsName() == "Android" ? false : true,

            artistJsonTemp: {
                "aiChatWelcome": "I'm an Afterparty Creator, how are you doing?",
                "aiChatWelcomeAudio": "/images/genaudio/665a7925-337b-4050-a393-092eda7bf2fd.mp3",
                "voiceId": "NGKMLfKmtPE9mpBrYmDG",
                "aiChatImages":[{"image_url":"/images/genimages/creator_77_4113468646_2.png"}],
                "aiChatModalExampleImages": [
                    {
                        "image_url": "/images/genimages/creator_77_2617290364_3.png"
                    },
                    {
                        "image_url": "/images/genimages/creator_77_165526552_3.png"
                    },
                    {
                        "image_url": "/images/genimages/creator_77_165526552_4.png"
                    },
                ],
            },
        };

        this.audioPlayer = new Audio();
        this.audioPlayer.addEventListener('ended', this.onAudioEnded.bind(this));
        this.childAudioEndedCallbacks = [];
        // {
        //     "aiChatWelcome": "I'm Tana, how are you doing?",
        //     "aiChatWelcomeAudio": "/images/genaudio/665a7925-337b-4050-a393-092eda7bf2fd.mp3",
        //     "voiceId": "Yobtzid2PQnV6o7tD6p8",
        //     "aiChatImages":[{"image_url":"/images/genimages/creator_77_4113468646_2.png"}]
        // }
        //
        // {
        //     "aiChatWelcome": "I'm Ethan, how are you doing?",
        //     "aiChatWelcomeAudio": "/images/genaudio/665a7925-337b-4050-a393-092eda7bf2fd.mp3",
        //     "voiceId": "bM26Yp3IUfEav30efKhq",
        //     "aiChatImages":[{"image_url":"/images/genimages/creator_77_4113468646_2.png"}]
        // }
        //
        // {
        //     "aiChatWelcome": "I'm Jacob & Julia, how are you doing?",
        //     "aiChatWelcomeAudio": "/images/genaudio/665a7925-337b-4050-a393-092eda7bf2fd.mp3",
        //     "voiceId": "TPmZJUlqq4BiolzGZDO8",
        //     "aiChatImages":[{"image_url":"/images/genimages/creator_77_4113468646_2.png"}]
        // }

        if(props.isPublic) {
            Utils.setCookie("selectedConversationId", "");
        }

        this.emojis = ["🎉", "😍", "❤️", "🔥", "👏", "👍", "😂"];
    }

    playAudio(src) {
        if(src) {
            this.audioPlayer.src = src;
        }
        this.audioPlayer.play();
    }

    pauseAudio() {
        this.audioPlayer.pause();
    }

    setChildAudioEndedCallback(callback) {
        this.childAudioEndedCallbacks.push(callback);
    }

    onAudioEnded() {
        this.childAudioEndedCallbacks.forEach(callback => {
            if (callback) {
                callback();
            }
        });
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;

        window.Utils = Utils;

        this.setupHtmlDoc();
        this.scrollHeight = this.getScrollHeight();

        if(st.userCredits != undefined && pr.isPublic) {
            this.gotoPrivatePage();
        }

        if(pr.isPublic) {
            Api.getIntroAiUserAudioChip(this.getArtistId(), (res2) => {
                const introAiUserAudioChipErrors = Utils.get(res2, "errors", []);
                if(!introAiUserAudioChipErrors.length) {
                    let messages = {};
                    let introAiUserAudioData = {};

                    introAiUserAudioData = Utils.get(res2, "data");
                    introAiUserAudioData = Utils.deepCopy(introAiUserAudioData);
                    introAiUserAudioData.message_type = 3;
                    // messages.push(introAiUserAudioData);
                    messages[0] = [introAiUserAudioData];

                    this.setState({
                        messages: messages,
                        aiChatIsLoading: false,
                        firstLoad: false,
                        artist: Utils.get(res2, "data.artist"),
                    });
                }
            });
        } else if(pr.userLoaded) {
            // NOTE: is private and user is loaded
            if(pr.user.ap_token_balance) {
                // console.log("pr.user.ap_token_balance >>>>>", pr.user.ap_token_balance);
            }
            this.loadAiConversations();
        }

        if(Utils.getRequest("welcome")) {
            Utils.setCookie("selectedConversationId", "");
        }

        $("#chatboxWindow").on("scroll", this.setAutoScrollHitBottom.bind(this));
        this.getScrollDirection();

        if(Utils.getRequest("welcome")) {
            setTimeout(() => {
                $("#monthInput").focus();
            }, 250);
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const pr = this.props;
        const st = this.state;

        if(pr.isPublic && newProps.userLoaded && Utils.get(newProps, "user.email")) {
            // NOTE: it's public page and user exists
            this.gotoPrivatePage();
        } else if (!pr.isPublic && newProps.userLoaded && !Utils.get(newProps, "user.email")) {
            // NOTE: it's private page and user doesn't exist
            // this.gotoPublicPage();
            Utils.gotoRoute(pr, `register?next=private/aichat/artist_id-${st.artist.id}`);
        } else if (!pr.isPublic && newProps.userLoaded && Utils.get(newProps, "user.email")) {
            // NOTE: it's private and user exists

            if(!newProps.user.birthdate) {
                this.setState({
                    showEnterBirthdateModal: true,
                });
            } else if(Utils.getAge(newProps.user.birthdate) < 13) {
                Utils.gotoRoute(newProps, "home");
            }

            this.setState({
                userCredits: Utils.get(newProps, "user.ap_token_balance"),
            });

            this.loadAiConversations(newProps.user.following);
        }
    }

    componentWillUnmount() {
        const pr = this.props;

        this.resetHtmlDoc();

        if(!pr.isPublic) {
            if(window.refreshMessages) {
                clearInterval(window.refreshMessages);
            }
        }

        $("#chatboxWindow").off("scroll");
    }

    getArtistId() {
        const st = this.state;

        return Utils.get(st, "artist.id", -1);
    }

    incrementAiPostSurveyNumberCookie() {
        const st = this.state;

        const nextSurveyValue = parseInt(st.aiPostSurveyNumber) + 1;

        this.setState({
            aiPostSurveyNumber: nextSurveyValue,
        });
        Utils.setCookie("aiPostSurveyNumber", nextSurveyValue);
    }

    handleEndSurveyCallback(endSurveyRes) {
        const pr = this.props;

        if(Utils.get(endSurveyRes, "errors", []).length) {
            console.log("you've already completed that survey");
        } else {
            // NOTE: res.success
            Api.apiLoadProfile((profileRes) => {
                pr.setUser(profileRes, () => {
                    this.incrementAiPostSurveyNumberCookie();
                });
            });
        }
    }

    setupHtmlDoc() {
        Scroll.setHtmlScroll(false);
    }

    resetHtmlDoc() {
        Scroll.setHtmlScroll(true);
    }

    blurInput() {
        $(".aiMainChatInput").blur();
    }

    scrollWhenContainerHeightIncreases() {
        // NOTE: autoscroll=17
        const pr = this.props;
        const st = this.state;

        const currentScrollHeight = this.getScrollHeight();
        // console.log("currentScrollHeight >>>>>", currentScrollHeight);
        // console.log("this.scrollHeight >>>>>", this.scrollHeight);

        if(this.scrollHeight < currentScrollHeight) {
            this.scrollHeight = currentScrollHeight;
            this.scrollChatboxBottom();
        }
    }

    getScrollHeight() {
        return $("#chatboxWindow")[0].scrollHeight;
    }

    getScrollDirection() {
        const chatboxWindow = $("#chatboxWindow");
        let lastScrollTop = 0;

        chatboxWindow.on("scroll", () => {
            let scrollTop = chatboxWindow[0].scrollTop;

            if (scrollTop > lastScrollTop) {
                // NOTE: Scrolling down
                // console.log("Scrolling down >>>>>");
            } else {
                // NOTE: Scrolling up
                // console.log("Scrolling up >>>>>");
                this.setState({
                    autoScroll: false,
                });
            }

            lastScrollTop = scrollTop;
        });
    }

    gotoPrivatePage() {
        const pr = this.props;
        const st = this.state;

        if(Utils.get(st, "post", "").length) {
            const updatedMessage = this.getUpdatedMessage();
            this.setState({
                post: "",
            });

            let data = {
                body: updatedMessage,
                room_id: st.selectedConversationId ? st.selectedConversationId : 31,
                feedType: this.state.chatTabSelected != "host" ? 1 : 2,
            };
            //data['firstMessage'] = 11;
            console.log("newMessageData2", data);

            Api.postAiChat(data, (chatRes) => {
                const roomChatErrors = Utils.get(chatRes, "errors", []);
                if(roomChatErrors.length) {
                    // NOTE: handle errors
                    return;
                }
                Utils.gotoRoute(pr, "private/aichat");
            });
        } else {
            Utils.gotoRoute(pr, "private/aichat");
        }
    }

    gotoPublicPage() {
        const pr = this.props;
        const st = this.state;

        Utils.gotoRoute(pr, `public/aichat/artist_id-${this.getArtistId()}`);
    }

    isAtScrollBottom() {
        const chatboxWindow = $("#chatboxWindow")[0];
        const scrollDelta = chatboxWindow.scrollHeight - chatboxWindow.scrollTop;
        return scrollDelta - chatboxWindow.clientHeight < 20;
    }

    setAutoScrollHitBottom() {
         // NOTE if user scrolled to the bottom
        if(this.isAtScrollBottom()) {
            // console.log("user hit bottom >>>>>", true);
            this.setState({autoScroll: true});
        } else {
            // console.log("user hit bottom >>>>>", false);
            this.setState({autoScroll: false});
        }
    }

    scrollChatboxBottom() {
        const chatboxWindow = $("#chatboxWindow");
        chatboxWindow.animate({ scrollTop: chatboxWindow.prop("scrollHeight") }, 500);
    }

    refreshMessages(firstRefreshCall, roomId) {
        const pr = this.props;
        const st = this.state;

        if(pr.screen.mobile && st.showCreatorPanel) {
            return;
        }

        if(!roomId) {
            roomId = st.selectedConversationId;
        }

        if(Utils.empty(roomId)) {
            // NOTE: there is no conversation started yet
            // TODO: must start a new conversation!
            return;
        }

        // NOTE: this data packet isn't being used, but we are going to keep it
        // it has to do with the GET and POST and reverse proxy
        let data = {
            room_id: roomId,
            feedType: st.chatTabSelected != "host" ? 1 : 2,
        };

        if(window.logs && window.logs.length > 0) {
            data['logs'] = Utils.serializeLogs(true);
        }

        if(window.throttle != undefined) {
            window.throttle++;
            if(window.throttle % 5 != 0) {
                if(window.throttle % 5 == 1) {
                    console.log("Throttled", window.throttle);
                }
                return;
            }
        }

        // NOTE: this would be a POST except the reverse proxy chache CAN'T cache POSTs,
        // so it has to be a GET method
        Api.getAiChatLimiter(roomId, (chatRes, e, responseTime) => {

            // TODO: Refactor whole method to update both chat types, scroll, etc. and have a single set state
            // Clear the blocker for simultaneous calls
            window.postRoomChatLoading = false; // TODO: unused variable

            if(responseTime > 2000) {
                if(Utils.get(pr, "isHost") || Utils.get(pr, "isVip")) {
                    console.log("Long responseTime > 2000, but Host or VIP, so not throttling", responseTime);
                    window.throttle = undefined;
                } else {
                    console.log("Long responseTime > 2000. Throttling...", responseTime);
                    window.throttle = 0;
                }
            } else {
                window.throttle = undefined;
            }

            if(chatRes && chatRes.data) {
                let messages = Utils.deepCopy(st.messages);

                const previousMessageLength = Utils.get(messages, `${roomId}`, []).length;
                const currentMessageLength = Utils.get(chatRes, "data", []).length;
                if(previousMessageLength < currentMessageLength) {
                    // NOTE: if there is a new message that came in
                    this.scrollChatboxBottom();
                }
                messages[roomId] = chatRes.data;

                let fullMessagesLength = 1;
                if(firstRefreshCall) {
                    fullMessagesLength = 0;
                }

                // Find if there are any image messages still processing and disable the image request


                let generatingImages = false;
                let aiImageCount = 0;
                let tempState = {
                    messages: messages,
                    generatingImages: generatingImages,
                    showWelcomeModal: Utils.isObjectEmpty(messages) && st.welcomeStage < 4,
                }
                for(let idx in messages[roomId]) {
                    let msg = messages[roomId][idx];
                    if(idx < (messages[roomId].length - fullMessagesLength) ) {
                        // NOTE: set all audiochip cookies to 1 besides the most recent, which may need to be autoplayed based on other logic in renderMessageAudioStrips()
                        if(msg['message_type'] == Constant.MESSAGE_TYPE_AUDIO_CHAT) {
                            this.setFirstPlayMessage(null, msg);
                        }
                    } else {
                        // Handle last message in conversation
                        const showMarketingModalPostId = Utils.get(msg, "json_data.showMarketingModal");
                        const messageId = Utils.get(msg, "id");
                        //console.log("Last message", messageId, showMarketingModalId, msg, );

                        // if lastMessage has a showMarketingModal flag and it isn't already displaying the modal or HASN't already displayed the modal -- show it
                        const alreadyKey = 'alreadyShowModalForMsg-'+messageId
                        if(showMarketingModalPostId && !st['showMarketingModal'] && !st[alreadyKey]) {
                            if(!Utils.getCookie(alreadyKey)) {
                                tempState['showMarketingModal'] = messageId;
                                tempState['showMarketingModalPostId'] = showMarketingModalPostId;
                                Utils.setCookie(alreadyKey, 1)
                            }

                        }

                    }

                    if(msg['message_type'] == 7 && msg['status'] == 2) {
                        generatingImages = true;
                        aiImageCount = aiImageCount++;
                        break;
                    }
                }
                tempState['aiImageCount'] = aiImageCount;
                const userCredits = Utils.get(chatRes, "user.ap_token_balance", null);
                if(userCredits != null && userCredits != st.userCredits) {
                    tempState['userCredits'] = userCredits;
                }


                this.setState(tempState, () => {
                    // NOTE: autoscroll=17
                    this.scrollWhenContainerHeightIncreases();
                });
            }
        });
    }

    artistIdIsMatch() {
        const pr = this.props;
        const st = this.state;

        const artistId = Utils.get(st, "artist.id");
        const artistAis = Utils.get(pr, "user.artist_ais", {});

        return Utils.get(artistAis[artistId], "imagegen") == 1;
    }

    getUpdatedMessage() {
        const st = this.state;
        const pr = this.props;

        const urls = {
            instagram: "instagram.com",
            twitter: "twitter.com",
            twitch: "twitch.tv",
        }

        let newMessage = st.post;
        this.setState({
            post: "",
        });
        const regexForUrls = /(http|https):\/\/(www\.)?(\w+)\.(\w+)(\/(\w+))?/g

        //Hello this is a website https://instagram.com/cozyboycries and this is really cool here https://spotify.com/cool https://twitter.com/cozyboycries

        const updatedMessage = newMessage.replace(/(http|https):\/\/(www\.)?(\w+)\.(\w+)(\/(\w+))?/g, (match, http, www, websiteName, domain) => {
            websiteName = websiteName.toLowerCase();
            if(urls[websiteName]) {
                return `<a href="${match}" target="_blank">${websiteName}.${domain}/...</a>`
            }

            return match;
        });

        return updatedMessage;
    }

    incAiMessage() {
        const msgKey = 'picgennum';
        let messageNum = parseInt(Utils.getCookie(msgKey, 0));
        messageNum++;
        //Utils.setCookie(msgKey, messageNum % this.state['pictureGenQuestions'].length);
    }

    postChatMessageNetworkCall(newMessageData, isNewConversation) {
        const pr = this.props;
        const st = this.state; // NOTE: if this is commented out, weird error

        const selectedConversationId = newMessageData.room_id;
        // newMessageData.tokens = 0;
        //newMessageData['firstMessage'] = 11;
        console.log("newMessageData", newMessageData);

        const myRandom = Math.random();
        Api.postAiChat(newMessageData, (chatRes) => {
            this.incAiMessage();
            const roomChatErrors = Utils.get(chatRes, "errors", []);
            if(roomChatErrors.length) {
                // NOTE: handle errors
                return;
            }

            Analytics.triggerAmplitudeChatMessage(pr, st);

            let tempState = {};

            let messages = Utils.deepCopy(st.messages);
            let chatResData = Utils.get(chatRes, "data", []);
            messages[selectedConversationId] = Utils.deepCopy(chatResData, true);
            tempState['messages'] = messages;

            tempState['post'] = "";
            tempState['lastPost'] = st.post;
            tempState['posting'] = false;
            tempState['autoScroll'] = true;
            tempState['tempNewMessage'] = null;
            tempState['userCredits'] = Utils.get(chatRes, "includes.token_balance");

            if(isNewConversation) {
                tempState['selectedConversationId'] = selectedConversationId;
                tempState['selectedCreatorIndex'] = 0;
                tempState['artist'] = st.allArtists[0];
                // tempState['artistId'] = st.allArtists[0].id;
            }

            this.setState(tempState, () => {
                // NOTE: scroll to bottom when new chat response is received
                this.scrollChatboxBottom();
            });
        });
    }

    handleChatPost() {
        const st = this.state;
        const pr = this.props;

        if(st.post.length == 0 || st.post == st.lastPost || st.posting) {
            return;
        }

        if(!this.hasEnoughCredits(100, 1)) {
            return null;
        }


        this.blurInput();

        this.setState({
            posting: true,
            aiMessageCounter: this.state.aiMessageCounter + 1,
            showSelectPhotoModal: this.state.aiMessageCounter + 1 == 4,
        });

        const updatedMessage = this.getUpdatedMessage();

        const artistId = Utils.get(st.allArtists, `${st.selectedCreatorIndex}.id`);
        let newMessageData = {
            body: updatedMessage,
            room_id: parseInt(st.selectedConversationId),
            feedType: this.state.chatTabSelected != "host" ? 1 : 2,
            artistId: artistId,
        };

        this.setState({
            tempNewMessage: {...{id: -3}, ...newMessageData},
        }, () => {
            // NOTE: scroll when a new chat is sent
            // but remember, the new chat response has NOT been received yet
            // thus, we scroll again later
            this.scrollChatboxBottom();
        });

        if(pr.isPublic) {
            let messages = Utils.deepCopy(st.messages);
            messages[0].push(newMessageData);
            this.setState({
                aiChatIsLoading: true,
                messages: messages,
            });

            setTimeout(() => {
                this.setState({
                    showCreateAccountModal: true,
                });
            }, 2000);

            return;
        } else {
            const tempMessages = Utils.deepCopy(st.messages);

            this.setState({
                messages: tempMessages,
            });
        }

        if(!newMessageData.room_id) {
            const roomText = `${pr.user.username} conversation with ${st.artist.title}`;
            const roomTextUnderscores = `${pr.user.username}_conversation_with_${st.artist.title}`;
            const newConversationData = {
                artist_id: artistId,
                convertTitle: "FIRST CONVERSATION",
                description: roomText,
                name: roomTextUnderscores,
                owner_id: pr.user.id,
                room_chat_type: "4", // NOTE: AI Chat Type
                title: roomText,
            };

            Api.postConversationAiChat(newConversationData, (newConversationRes) => {
                const followingList = Utils.get(pr, "user.following");
                this.processAiConversations(followingList, newConversationRes);
                const newRoomId = Utils.get(newConversationRes, "data.0.id");
                newMessageData.room_id = newRoomId;
                Utils.setCookie("selectedConversationId", newRoomId);
                newMessageData['firstMessage'] = 11;
                this.postChatMessageNetworkCall(newMessageData, true);
            });
        } else {
            this.postChatMessageNetworkCall(newMessageData);
        }
    }

    isWaitingForResponse() {
        const pr = this.props;
        const st = this.state;

        const mostRecentMessage = this.getMostRecentMessage();

        if(!mostRecentMessage) {
            return false;
        }

        const messageType = Utils.get(mostRecentMessage, "message_type");
        const status = Utils.get(mostRecentMessage, "status");
        return messageType == Constant.MESSAGE_TYPE_WAITING_FOR_AI_RESPONSE || status == 2;
    }

    loadAiConversations(followingList) {
        const pr = this.props;
        const st = this.state;

        if(!followingList) {
            followingList = Utils.get(pr, "user.following");
        }

        const artistId = Utils.getRequest('artist_id', null, false, true);
        Api.getAiConversationsAvailableToUser(this.processAiConversations.bind(this, followingList), artistId);
    }

    createFullArtistList(artistConversations, followingList) {
        const pr = this.props;
        const st = this.state;

        if(!artistConversations) {
            artistConversations = st.artistConversations;
        }

        if(!artistConversations.length) {
            artistConversations = [];
        }

        if(!followingList) {
            followingList = Utils.get(pr, "user.following", []);
        }

        if(!followingList.length) {
            followingList = [];
        }

        // NOTE: get exclusion list of artist conversations
        let allArtists = Utils.deepCopy(artistConversations, []);
        let artistIds = Utils.pluck(allArtists, "id");

        for (var i = 0; i < followingList.length; i++) {
            const artist = followingList[i];
            if(Utils.get(artist, "ai_enabled") && artistIds.indexOf(artist.artist_id) == -1) {
                // NOTE: artist is not in the list of Conversations
                let tempArtist = Utils.deepCopy(artist);
                tempArtist.image_url = artist.artist_image_url;
                tempArtist.title = artist.artist_title;

                allArtists.push(tempArtist);
            }
        }

        this.setState({
            artist: allArtists[st.selectedCreatorIndex],
            allArtists: allArtists,
        });

        return allArtists;
    }

    processAiConversations(followingList, res) {
        const pr = this.props;
        const st = this.state;

        if(Utils.get(res, "errors", []).length) {
            console.log("processAiConversations.ERRORS: >>>>", res);
            return;
        }

        let selectedCreatorIndex = 0;
        const overrideArtistId = Utils.getRequest('artist_id', -1, false, true)
        let selectedConversationId = st.selectedConversationId;
        if(!selectedConversationId) {
            if(!overrideArtistId) {
                let cookieConv = Utils.getCookie("selectedConversationId");
                if(cookieConv) {
                    selectedConversationId = cookieConv;
                }
            }
        }

        const conversations = Utils.get(res, "data", []);
        this.setState({
            backendConversations: conversations,
        });
        let artistConversations = [];

        const selectedArtist = Utils.get(res, "includes.selectedArtist", null);
        if(selectedArtist) {
            artistConversations.push(selectedArtist);
        }

        for(var i = 0; i < conversations.length; i++) {
            let artist = Utils.get(conversations[i], "artist");

            if(artist) {
                artist["room_id"] = conversations[i].id;
                if(artist.room_id == selectedConversationId) {
                    selectedCreatorIndex = i;
                } else if(!selectedConversationId && artist['id'] == overrideArtistId) {
                    selectedCreatorIndex = i;
                    selectedConversationId = artist.room_id;
                }
                artist["numMessages"] = conversations[i].numMessages;
                artistConversations.push(artist);
            }
        }

        const allArtists = this.createFullArtistList(artistConversations, followingList);
        const conversationsDict = Utils.arrayToObject(conversations, "id");

        if(conversations.length > 0 && !selectedConversationId) {
            if(selectedArtist) {
                selectedConversationId = null;
            } else {
                selectedConversationId = conversations[0].id;
            }
        } else if(conversations.length == 0 && !this.aiAvailable(allArtists)) {
            this.setState({
                showNoFollowsModal: true,
            });
        }

        let tempState = {
            // NOTE: if the artistConversations doesn't have a conversation, grab artist from following list
            artist: selectedConversationId ? Utils.get(conversationsDict[selectedConversationId], "artist") : allArtists[0],
            // artistId: selectedConversationId ? Utils.get(conversationsDict[selectedConversationId], "artist.id") : allArtists[0].id,
            selectedCreatorIndex: selectedCreatorIndex,
            selectedConversationId: selectedConversationId,
            artistConversations: artistConversations, // NOTE: this is not all artistConversations, only artistConversations with conversations
            conversationsDict: conversationsDict, // NOTE: this is (room / conversation) id
            selectedArtist: selectedArtist, // NOTE: this is (room / conversation) id
            firstLoad: false,
        };

        if(Utils.get(res, "includes.feedback_types")) {
            tempState.feedbackTypes = Utils.get(res, "includes.feedback_types");
        }

        this.setState(tempState, () => {
            this.setConversation();
        });
    }

    setConversation() {
        const pr = this.props;
        const st = this.state;

        if(!pr.isPublic) {
            // NOTE: if private, clear any vestigial refreshMessages functions
            // so that the function is not called multiple times
            if(window.refreshMessages) {
                clearInterval(window.refreshMessages);
            }

            // NOTE: call refreshMessages to load initial messages
            this.refreshMessages(true);

            if(!Utils.getRequest('nochat')) {
                // NOTE: set the interval
                window.refreshMessages = setInterval(this.refreshMessages.bind(this), 1000);
            }
        }
    }

    handleToggleSelectedChatTab() {
        const pr = this.props;
        const st = this.state;

        const tab = st.chatTabSelected == "fan" ? "host" : "fan";
        const hasHostChatPermission = Utils.get(pr, "isHost") || Utils.get(pr, "isVip") || st.vipChatActivated;

        if(tab == 'host' && !hasHostChatPermission) {
            return Utils.get(pr, "handleButtonClick", () => {})('notvip');
        } else {
            pr.setChatTabSelected(tab);
            this.setState({
                chatTabSelected: tab,
                newHostMessageNotification: false,
            });
        }
    }

    hasEnoughCredits(cost, dialogNum) {
        const st = this.state;
        if(!st.userCredits || st.userCredits < cost) {
            this.setState({renderMoreCreditsModal:dialogNum, });
            return false;
        } else {
            return true;
        }
    }

    handleGenerateImages() {
        const st = this.state;

        const getPicEnabled = Utils.get(st, "artist.ai_online") && !this.isNoCredits() && !st.generatingImages;
        if(!getPicEnabled) {
            return;
        }

        if(this.hasEnoughCredits(700, 5)) {
            this.setState({showSelectPhotoModal: true});
        }
    }

    aiAvailable(allArtists) {
        for (var i = 0; i < allArtists.length; i++) {
            const artist = allArtists[i];
            if(artist.ai_enabled && artist.ai_online) {
                return true;
            }
        }
    }

    changePost(e) {
        this.setState({post: e.target.value});
    }

    keyDownPost = (e) => {
        if(e.key === 'Enter') {
            this.handleChatPost();
            return;
        }
    }

    addEmojiToChat(emoji) {
        this.setState({post: this.state.post += emoji});
    }

    showSuperSendInfo() {
        this.setState({
            showSuperSendInfoModal: true,
        });
    }

    getArtistName() {
        const pr = this.props;

        return Utils.get(pr, "artist.title", "Creator").split(" ")[0]
    }

    showCreatorPanel() {
        Utils.setCookie("selectedConversationId", "");

        this.setState({
            showCreatorPanel: true,
        });
    }

    showCreateSignInModal(isLogin) {
        this.setState({
            isLogin: isLogin,
            showCreateSignInModal: true,
        });
    }

    onLoginToggle() {
        // NOTE: most likely not needed
    }

    generalLoginSignUpHandle(user) {
        const pr = this.props;
        const st = this.state;
    }

    toggleAgreeToTerms() {
        this.setState({agreeToTerms: !this.state.agreeToTerms});
    }

    toggleFeedbackCheckbox(imageFeedbackType) {
        let tempState = this.state;

        tempState[this.getCheckboxKey(imageFeedbackType)] = !this.state[this.getCheckboxKey(imageFeedbackType)];

        this.setState(tempState);
    }

    showTermsOfServiceModal() {
        const pr = this.props;

        if(pr.showTermsOfServiceModal) {
            pr.showTermsOfServiceModal();
        } else {
            Utils.newTab("/tos");
        }
    }

    showCustomAiSelfieModal() {
        this.setState({
            showCustomAiSelfieModal: true,
        });
    }

    changBirthdayText(e) {
        const birthdateText = e.target.value;

        this.setState({
            birthdateText: birthdateText,
        });
    }

    submitBirthday() {
        const pr = this.props;
        const st = this.state;
        // step 1: validate age
        if(!st.agreeToTerms) {
            return;
        }
        // step 2: send network calls for birthday and credits

        let profileData = {
            params: {
                user: {},
            },
        };

        const validateMonth = Validate.month(st.monthText);
        const validateDay = Validate.day(st.dayText);
        const validateYear = Validate.year(st.yearText);

        let birthdateErrors = [];
        let birthdateIsValid = false;

        birthdateErrors = [
            ...validateMonth.errors,
            ...validateDay.errors,
            ...validateYear.errors,
        ];

        if(birthdateErrors.length === 0) {
            birthdateIsValid = true;
        }

        this.setState({
            birthdateIsValid: birthdateIsValid,
            birthdateErrors: birthdateErrors,
        });

        if(!birthdateIsValid) {
            return;
        }

        profileData.params.user.birthdate = Utils.formatBirthdate(st.dayText, st.monthText, st.yearText);

        Api.putProfile(profileData, (profileRes) => {
            if(Utils.get(profileRes, "success")) {
                pr.setUser(profileRes);

                const birthdate = Utils.get(profileRes, "data.user.birthdate");
                if(Utils.getAge(birthdate) < 13) {
                    Utils.gotoRoute(pr, "home");
                }

                this.setState({
                    successMessages: Utils.addErrorSuccessMessage(239858, "Birthdate updated"),
                    birthdateErrors: [],
                    showEnterBirthdateModal: false,
                    monthText: "",
                    dayText: "",
                    yearText: "",
                });
            } else {
                this.setState({
                    successMessages: [],
                    birthdateErrors: Utils.addErrorSuccessMessage(34445332, "Birthdate update failed"),
                });
            }
        });
    }

    monthChange(e) {
        let month = e.target.value;
        if(month.length > 2) {
            month = month.slice(0, 2);
        }
        if(!isNaN(month)) {
            this.setState({monthText: month});
        }
    }

    dayChange(e) {
        let day = e.target.value;
        if(day.length > 2) {
            day = day.slice(0, 2);
        }
        if(!isNaN(day)) {
            this.setState({dayText: day});
        }
    }

    yearChange(e) {
        let year = e.target.value;
        if(year.length > 4) {
            year = year.slice(0, 4);
        }
        if(!isNaN(year)) {
            this.setState({yearText: year});
        }
    }

    getMostRecentMessage() {
        const pr = this.props;
        const st = this.state;

        const messages = Utils.get(st.messages, st.selectedConversationId);
        const messagesLength = Utils.get(messages, "length", 0);
        if(!messagesLength) {
            return null;
        }

        const mostRecentMessage = messages[messages.length - 1];

        return mostRecentMessage;
    }

    playSound() {
        const st = this.state;

        let isPlaying = st.isPlaying;

        if (isPlaying) {
            st.audio.pause();
        } else {
            st.audio.play();
        }

        this.setState({ isPlaying: !isPlaying });
    }

    initAllFirstPlayCookies() {
        const pr = this.props;
        const st = this.state;

        const messagesArr = Utils.get(st.messages, `${st.selectedConversationId}`, []);

        messagesArr.map((messageObj, index) => {
            this.setFirstPlayMessage(this.getArtistId(), messageObj);
        });
    }

    firstPlay() {
        const pr = this.props;
        const st = this.state;

        // console.log("firstPlay() >>>>>", );

        if(this.getHasFirstPlay() && this.getFirstPlayMessage()) {
            // NOTE: don't play audio
        } else {
            // NOTE: play audio
            try {
                // TODO: play audio
            } catch(error) {
                // TODO: show pulse animation again
                Utils.setCookie("audioChipPulse", 0);
            }

            this.setHasFirstPlay();
            this.setFirstPlayMessage();
        }
    }

    getHasFirstPlay() {
        const pr = this.props;
        const st = this.state;
        return Utils.getCookie(`has_already_firstplay_${pr.user.id}_${this.getArtistId()}`);
    }

    setHasFirstPlay() {
        const pr = this.props;
        const st = this.state;
        Utils.setCookie(`has_already_firstplay_${pr.user.id}_${this.getArtistId()}`, 1);
    }

    setFirstPlayMessage(artistId, messageObj) {
        const pr = this.props;
        const st = this.state;

        artistId = artistId ?? this.getArtistId();
        let messageId = messageObj ? messageObj.id : -1;

        Utils.setCookie(`firstplay_${Utils.get(pr, "user.id")}_${artistId}_${messageId}`, 1);
    }

    getFirstPlayMessage(artistId, messageObj) {
        const pr = this.props;
        const st = this.state;

        artistId = artistId ?? this.getArtistId();
        let messageId = messageObj ? messageObj.id : -1;

        return Utils.getCookie(`firstplay_${Utils.get(pr, "user.id")}_${artistId}_${messageId}`);
    }

    audioPlayCallback(artistId, messageObj) {
        // console.log("audioPlayCallback >>>>>", artistId, messageObj.id);
        // this.setFirstPlayMessage(artistId, messageObj);
    }

    selectCreator(artist, artistIndex) {
        const pr = this.props;
        const st = this.state;

        if(pr.screen.mobile) {
            this.setState({
                showCreatorPanel: false,
            });
        }

        this.setState({
            artist: st.allArtists[artistIndex],
            selectedCreatorIndex: artistIndex,
            selectedConversationId: artist.room_id,
        }, () => {
            history.pushState(null, "", `/webapp/private/aichat/artist_id-${Utils.get(this.state.artist, "id")}`);
        });

        if(artist.room_id) {
            Utils.setCookie("selectedConversationId", artist.room_id);
        } else {
            Utils.setCookie("selectedConversationId", "");
        }

        setTimeout(() => {
            // NOTE: remember, whenever the scroll hits the bottom,
            // autoScroll is automatically enabled
            this.scrollHeight = this.getScrollHeight();
            this.scrollChatboxBottom();
        }, 750);
    }

    toggleAutoPlay() {
        const isMuted = Utils.getCookie("isMuted") == "true";
        if(isMuted) {
            Utils.setCookie("isMuted", "false");
        } else {
            Utils.setCookie("isMuted", "true");
        }

        this.setState({
            isMuted: !this.state.isMuted
        });
    }

    showBuyCreditsModal() {
        this.setState({
            showBuyCreditsModal: true,
        });
    }

    hideBuyCreditsModal() {
        this.setState({
            showBuyCreditsModal: false,
        });
    }

    changeVal(valPath, event) {
        //console.log("EVENT", event, valPath, event.target.value);
        let tempState = this.state;
        Utils.set(tempState, valPath, event.target.value);
        this.setState(tempState);
    }

    submitAudioFeedback(feedbackType) {}

    submitSendToFeedback(imageIndex, messageObj) {
        const st = this.state;

        const feedbackData = [{
            feedback_type_id: Constant.AI_FEEDBACK_SEND_IMAGE_TO_ARTIST,
            // feedback_type_title: `Send to ${Utils.get(st, "artist.title", []).split(" ")[0]} Trapdoor`,
            room_chat_image_index: imageIndex,
            room_chat_id: Utils.get(messageObj, "id"),
            artist_id: Utils.get(st, "artist.id"),
            feedback_value: 1,
        }];

        Api.postAiFeedback(feedbackData, (res) => {
            if(Utils.get(res, "errors", []).length) {
                console.log("ERRORS: ", Utils.get(res, "errors"));
            }

            this.setState({
                showSendToArtistModal: true,
            });
        });
    }

    getJustImageIndex() {
        const st = this.state;

        const promptTemplates = Utils.get(st.artist, "artist_imagegen_locations", []);
        for (var i = 0; i < promptTemplates.length; i++) {
            const promptTemplate = promptTemplates[i];

            if(promptTemplate.name.includes("just_")) {
                return i;
            }
        }

        return 0;
    }

    generateAiImage(message) {
        // NOTE: currently, this function is only called from the instantImage
        const pr = this.props;
        const st = this.state;

        const photoIndex = this.getJustImageIndex();
        // NOTE: st.selectPhotoTypeIdx used to be photoIndex

        if(!this.hasEnoughCredits(700, 5)) {
            return null;
        }


        let newMessageData = {
            body: `GEN-${Utils.get(st.artist.artist_imagegen_locations, `${photoIndex}.name`)}`,
            promptTemplate: Utils.get(st.artist.artist_imagegen_locations, `${photoIndex}.id`),
            room_id: st.selectedConversationId,
            feedType: st.chatTabSelected != "host" ? 1 : 2,
            artistId: this.getArtistId(),
            customImageGenName: Utils.get(st, 'customImageGenName'),
        };

        let instantImageDict = Utils.deepCopy(st.instantImageDict);
        instantImageDict[message.id] = true;

        this.setState({
            aiMessageCounter: st.aiMessageCounter + 1,
            showSelectPhotoModal: st.aiMessageCounter + 1 == 4,
            instantImageDict: instantImageDict,
        });

        if(newMessageData.room_id) {
            this.postChatMessageNetworkCall(newMessageData);
        } else {
            const roomText = `${pr.user.username} conversation with ${st.artist.title}`;
            const roomTextUnderscores = `${pr.user.username}_conversation_with_${st.artist.title}`;
            const newConversationData = {
                artist_id: this.getArtistId(),
                convertTitle: "FIRST CONVERSATION",
                description: roomText,
                name: roomTextUnderscores,
                owner_id: pr.user.id,
                room_chat_type: "4", // NOTE: AI Chat Type
                title: roomText,
            };

            Api.postConversationAiChat(newConversationData, (newConversationRes) => {
                const followingList = Utils.get(pr, "user.following");
                this.processAiConversations(followingList, newConversationRes);
                const newRoomId = Utils.get(newConversationRes, "data.0.id");
                newMessageData.room_id = newRoomId;
                Utils.setCookie("selectedConversationId", newRoomId);

                this.postChatMessageNetworkCall(newMessageData);
            });
        }

        this.setState({showSelectPhotoModal: false});
    }

    getInitArtistAssets() {
        const pr = this.props;
        const st = this.state;

        let initArtistObj = {initAudioMessage: null, initImage: null};

        try {
            const artistJson = st.allArtists[st.selectedCreatorIndex].json_data ?? st.artistJsonTemp;
            const initAudioMessage = {id: Constant.AI_INITIAL_AUDIO_ID, body: artistJson.aiChatWelcome, message_type: 3, json_data: {audio: artistJson.aiChatWelcomeAudio}};
            const initImage = {id: Constant.AI_INITIAL_IMAGE_ID, json_data: {images: [artistJson.aiChatImages[0].image_url]}, message_type: 7};

            initArtistObj = {
                initAudioMessage: initAudioMessage ?? null,
                initImage: initImage ?? null,
            };
        } catch(error) {
            return initArtistObj;
        }

        return initArtistObj;
    }

    isUserVerified() {
        const user = this.props.user;
        const isEmail = Utils.get(user, "is_email_verified") == 1;
        const isPhone = Utils.get(user, "is_phone_verified") == 1;

        return isEmail && isPhone;
    }

    showLeaderboardSidePanel() {
        this.setState({
            showLeaderboardSidePanel: true,
        });
    }

    renderPhoneEmailVerification() {
        const pr = this.props;

        return (
            <EmailPhoneVerification
                showTokenBadges
                startState="startBoth"
                screen={pr.screen}
                history={pr.history}
                user={pr.user}
                userLoaded={pr.userLoaded}
                setUser={pr.setUser.bind(this)}
                continueFunction="hideContinueButton"
                hideAfterVerified
            />
        )
    }

    renderUsername(message, customerDict) {
        let username = message['customer_id'];
        if(message['username']) {
            username = message['username'];
        } else if(message['customer_id'] in customerDict) {
            username = customerDict[message['customer_id']]['username'];
        }
        return Utils.shortenString(username, 20);
    }

    renderAvatar(message, customerDict) {
        const st = this.state;
        const sty = this.styles;

        let avatarStyle = null;
        const avatarBaseStyle = {backgroundSize: "cover", backgroundPosition: "center"};
        const defaultApLogoImage = "/images/afterparty_logo_big_landscape.jpg";
        // if(message['customer_id'] in customerDict && customerDict[message['customer_id']]['photo']) {
        if(message.photo) {
            // avatarStyle = {...this.styles.customerIcon, ...{backgroundImage: `url(${customerDict[message['customer_id']]['photo']})`},};
            avatarStyle = {...sty.customerIcon, ...avatarBaseStyle, ...{border: `2px solid ${Colors.magenta}`, backgroundImage: `url(${message.photo})`},};
        } else {
            const avatarColor = Colors.colorIndex[message['customer_id'] % Colors.colorIndex.length];
            //console.log("COLORS", Colors.colorIndex, avatarColor, message['customer_id']);
            avatarStyle = {...sty.customerIcon, ...avatarBaseStyle, ...{border: `2px solid ${avatarColor}`, backgroundImage: `url("${defaultApLogoImage}")`}};
        }
        return <Div className="avatarStyle" style={avatarStyle}></Div>
    }

    renderBadges(message, customerDict) {
        let badges = null;
        if(message['customer_id'] in customerDict && this.props.badgeTypes && customerDict[message['customer_id']]['badges'].length > 0 ) {
            const badgeList = customerDict[message['customer_id']]['badges'];
            let activeBadges = [];
            for(var idx in badgeList) {
                if(badgeList[idx] in this.props.badgeTypes) {
                    activeBadges.push(<img key={`${message.id}-${Math.random()}`} style={{width:24,height:24,padding:2}} src={this.props.badgeTypes[badgeList[idx]]['image_url']} />)
                }
            }
            if(activeBadges.length > 0) {
                badges = activeBadges;
            }
        }
        return badges;
    }

    renderNumMessages(artist) {
        const pr = this.props;
        const st = this.state;

        let numMessageText = "Not started";

        let currentMessageCount = Utils.get(st, `messages.${artist.room_id}`, []).length;

        if(currentMessageCount) {
            numMessageText = `${currentMessageCount + 2} messages`;
        } else if(artist.numMessages) {
            numMessageText = `${artist.numMessages + 2} messages`;
        }

        return numMessageText;
    }

    handleLikeDislike(isLike, messageObj) {
        const likeData = {
            isLike: isLike,
            id: messageObj.id,
        };

        Api.postAiLikePicture(likeData, (likeRes) => {
            if(likeRes.errors.length) {
                console.log("ERROR: LIKERES >>>>", likeRes.errors);
                return;
            }
        });
    }

    submitImageFeedback() {
        const pr = this.props;
        const st = this.state;

        let feedbackDataArray = [];

        const feedbackImageTypes = Utils.get(st, "feedbackTypes.2", []);
        for(let i = 0; i < feedbackImageTypes.length; ++i) {
            const feedbackImageType = feedbackImageTypes[i];
            const feedbackValue = st[this.getCheckboxKey(feedbackImageType)];

            const feedbackData = {
                feedback_type_id: feedbackImageType.id,
                feedback_type_title: feedbackImageType.title,
                room_chat_image_index: st.currentFeedbackMessageImageIndex,
                room_chat_id: Utils.get(st.currentFeedbackMessage, "id"),
                artist_id: Utils.get(st, "artist.id"),
                feedback_value: feedbackValue == true ? 1 : 0,
            };

            if(feedbackValue == true) {
                feedbackDataArray.push(feedbackData);
            }
        }

        Api.postAiFeedback(feedbackDataArray, (feedbackRes) => {
            if(feedbackRes.errors.length) {
                console.log("ERROR: LIKERES >>>>", feedbackRes.errors);
                return;
            }

            this.setState({
                showImageFeedbackMenu: false,
            });
        });
    }

    showImageFeedbackMenu(imageIndex, messageObj) {
        this.setState({
            showImageFeedbackMenu: true,
            currentFeedbackMessageImageIndex: imageIndex,
            currentFeedbackMessage: messageObj,
        });
    }

    showSendToArtistModal(imageIndex, messageObj) {
        this.setState({
            showSendToArtistModal: true,
            currentFeedbackMessageImageIndex: imageIndex,
            currentFeedbackMessage: messageObj,
        });
    }

    handleInstantCustomMessageClick(message) {
        const pr = this.props;
        const st = this.state;

        let instantImageDict = Utils.deepCopy(st.instantImageDict);
        instantImageDict[message.id] = true;

        this.setState({
            instantImageDict: instantImageDict,
        });

        //Utils.gotoRoute(pr, `/p/first_time_offer/artist_id-${st.artist.id}`);
        Utils.gotoRoute(pr, `/p/custom_shoutouts_modal/artist_id-94`);
    }

    getCheckboxKey(imageFeedbackType) {
        const st = this.state;

        const checkboxKey = `feedbackChecked_${imageFeedbackType.id}_${st.currentFeedbackMessage.id}_${st.currentFeedbackMessageImageIndex}`;
        return checkboxKey;
    }

    renderImageFeedbackOptions(imageIndex, messageObj) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(messageObj.id == Constant.AI_INITIAL_IMAGE_ID) {
            return null;
        }

        return (
            <Div
                className="imageFeedbackTypeContainer"
                style={{...sty.imageFeedbackTypeContainer, ...StylesPlatform.cursorPointer}}
                onClick={this.showImageFeedbackMenu.bind(this, imageIndex, messageObj)}
            >
                {/* <Div
                    className="sendToArtist"
                    style={{...sty.imageFeedbackMenuButton, ...StylesPlatform.cursorPointer, ...{marginLeft: "auto", fontWeight: 300}}}
                    onClick={this.submitSendToFeedback.bind(this, imageIndex, messageObj)}
                >
                    <Icon
                        icon="paper-plane"
                        size={12}
                        style={{marginRight: 6}}
                    />
                </Div> */}
                <Div
                    className="imageFeedbackMenuButton"
                    style={{...sty.imageFeedbackMenuButton, ...StylesPlatform.cursorPointer, ...{marginLeft: "auto"}}}
                >
                    <Icon
                        icon="expand"
                        size={15}
                        style={{marginRight: 2, marginLeft: 2}}
                    />
                </Div>
            </Div>
        );
    }

    renderLikeDislikeModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const imageFeedbackTypes = Utils.get(st, "feedbackTypes.2", []);

        return (
            <Modal
                screen={pr.screen}
                className="likeDislikeModal"
                onRequestClose={ () => { this.setState({showImageFeedbackMenu: false}); } }
                color="indigo"
                style={{maxWidth: 500}}
                // title="Give Your Feedback"
            >
                <>
                    <Image
                        className="artistFocusImage"
                        src={Utils.get(st.currentFeedbackMessage, `json_data.images.${st.currentFeedbackMessageImageIndex}`)}
                        style={{maxWidth: 500, width: "100%", marginBottom: 16, borderRadius: 10}}
                    />
                    {/* <Div className="imageFeedbackMenuContainer" style={sty.imageFeedbackMenuContainer}>
                        {imageFeedbackTypes.map((imageFeedbackType, index) => {
                            if(!st.showImageFeedbackMenu) {
                                return null;
                            }

                            let lastItem = null;
                            if(imageFeedbackTypes.length - 1 == index) {
                                lastItem = {border: "none"};
                            }

                            return (
                                <Div
                                    key={`imageFeedbackTypeRow-${index}`}
                                    className="imageFeedbackTypeRow"
                                    style={{...sty.imageFeedbackTypeRow, ...StylesPlatform.cursorPointer, ...lastItem}}
                                >
                                    <Div className="checkBoxRow" style={{display: "flex", gap: 10}}>
                                        <Checkbox
                                            color="white"
                                            checked={st[this.getCheckboxKey(imageFeedbackType)]}
                                            style={{marginRight: 4}}
                                            onClick={this.toggleFeedbackCheckbox.bind(this, imageFeedbackType)}
                                        />

                                        {imageFeedbackType.title.replace("${artist.title}", Utils.get(st.artist, "title"))}
                                    </Div>
                                </Div>
                            );
                        })}
                    </Div>
                    <Button
                        color="pinkGradient"
                        style={{marginTop: 16}}
                        onClick={this.submitImageFeedback.bind(this)}
                    >
                        Submit Feedback
                    </Button> */}
                </>
            </Modal>
        );
    }

    renderAiOfflineMessage() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="aiNotAvailable" style={{color: Colors.redError, fontSize: 20, fontWeight: 300, textAlign: "center"}}>
                The {Utils.get(st, "artist.title")} AI {pr.screen.mobile ? <br/> : null} is not available right now
            </Div>
        )
    }

    renderMessageImageStrip(message) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const aiImageStyle = StyleUtils.getMStyle(sty, 'aiImageStyle', pr.screen.width, "md");

        const imageUrl1 = Utils.get(message, 'json_data.images.0');
        const imageUrl2 = Utils.get(message, 'json_data.images.1');
        const imageUrl3 = Utils.get(message, 'json_data.images.2');
        const imageUrl4 = Utils.get(message, 'json_data.images.3');

        const msgKey = 'picgennum';
        let messageNum = parseInt(Utils.getCookie(msgKey, 0));

        let backgroundImage1 = {backgroundImage: `url(${imageUrl1})`};
        let backgroundImage2 = {backgroundImage: `url(${imageUrl2})`};
        let backgroundImage3 = {backgroundImage: `url(${imageUrl3})`};
        let backgroundImage4 = {backgroundImage: `url(${imageUrl4})`};

        return (
            <Div key={`msg-${message['id']}-image`} className="messageImageStrip" style={{marginTop:12,marginBottom:12, display: "flex", alignItems: "end"}}>
                <Image
                    src={Utils.get(st, "artist.image_url")}
                    className="artistAvatarSmall"
                    style={sty.artistAvatarSmall}
                />
                { Utils.empty(imageUrl1) || ( !Utils.empty(imageUrl1) && Utils.get(message, 'status') != 1) ?
                    <Div className="photoRequestContainer" style={sty.photoRequestContainer}>
                        <Image
                            className="photoRequest"
                            style={{width: 100, marginBottom: 4}}
                            src={photoRequest}

                        />
                        <Div className="photoRequestText" style={{fontWeight: 300, fontSize: 16}}>
                            Generating images...
                        </Div>
                    </Div>
                    :
                    <Div className="aiImagesContainer" style={sty.aiImagesContainer}>
                        <Div className="aiImage1" style={{...aiImageStyle, ...backgroundImage1}}>
                            {this.renderImageFeedbackOptions(0, message)}
                        </Div>
                        {imageUrl2 ?
                            <Div className="aiImage2" style={{...aiImageStyle, ...backgroundImage2}}>
                                {this.renderImageFeedbackOptions(1, message)}
                            </Div>
                            :
                            null
                        }
                        {imageUrl3 ?
                            <Div className="aiImage3" style={{...aiImageStyle, ...backgroundImage3}}>
                                {this.renderImageFeedbackOptions(2, message)}
                            </Div>
                            :
                            null
                        }
                        {imageUrl4 ?
                            <Div className="aiImage4" style={{...aiImageStyle, ...backgroundImage4}}>
                                {this.renderImageFeedbackOptions(3, message)}
                            </Div>
                            :
                            null
                        }
                    </Div>

                }
            </Div>
        );
    }

    isNoCredits() {
        return this.state.userCredits <= 0;
    }

    renderMessageAudioStrips(message, messages, messageIndex) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let audioChipText = null;
        if(!pr.isPublic && messages.length >= 2) {
            audioChipText = Utils.get(messages[messageIndex - 1], "body");
        }

        let isMostRecentMessage = false;
        if(messageIndex == messages.length + 1) {
            isMostRecentMessage = true;
        }
        const audioUrl = Utils.get(message, "json_data.audio");

        if(message.id == Constant.AI_INITIAL_AUDIO_ID) {
            if(!st.showWelcomeModal) {
                this.firstPlay();
            }
        }

        let playFlag = this.getFirstPlayMessage(null, message);

        const instantImage = Utils.get(message, "json_data.instantImage");
        const instantCustomMessage = Utils.get(message, "json_data.instantCustomMessage");

        const instantImageAlreadyClicked = st.instantImageDict[message.id];
        let instantImageClickedStyle = null;

        if(instantImageAlreadyClicked || this.isNoCredits()) {
            instantImageClickedStyle = {opacity: 0.5};
        }

        return (
            <Div
                key={`msg-${message['id']}-${audioUrl}`}
                className="audioChipContainer"
                style={sty.audioChipContainer}
            >
                <AudioChip
                    audioPlayer={st.audioPlayer}
                    playAudio={this.playAudio.bind(this)}
                    pauseAudio={this.pauseAudio.bind(this)}
                    setAudioEndedCallback={this.setChildAudioEndedCallback.bind(this)}
                    user={pr.user}
                    src={audioUrl}
                    body={message.body}
                    roomChat={message}
                    text={audioChipText}
                    artist={st.artist}
                    backgroundImage={Utils.get(st, "artist.audio_chip_background_image_url")}
                    avatar={Utils.get(st, "artist.image_url")}
                    screen={pr.screen}
                    autoPlay={!st.isMuted}
                    feedback={st.feedbackTypes}
                    submitFeedback={this.submitAudioFeedback.bind(this)}
                    audioPlayCallback={this.audioPlayCallback.bind(this)}
                    playFlag={playFlag}
                    isWelcome={st.showWelcomeModal}
                />
                {instantImage == 1 ?
                    <Div
                        className="instantImageContainer"
                        style={{...sty.instantImageContainer, ...instantImageClickedStyle, ...StylesPlatform.cursorPointer}}
                        onClick={instantImageAlreadyClicked ? () => {} : this.generateAiImage.bind(this, message)}
                    >
                        <Image
                            className="instantImageImage"
                            style={sty.instantImageImage}
                            src={Utils.get(st, "artist.image_url")}
                        />
                        <Div className="instantImageTextContainer" style={sty.instantImageTextContainer}>
                            <Div className="instantImageTitle" style={sty.instantImageTitle}>
                                {Utils.get(st, "artist.title")}
                            </Div>
                            <Div className="lowerSnapContainer" style={sty.lowerSnapContainer}>
                                <Div className="instantImageTitle" style={sty.instantImageSquare}></Div>
                                <Div className="instantImageTitle" style={sty.instantImageNewImage}>
                                    New Image Sent
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                    :
                    null
                }
                {instantCustomMessage == 1 ?
                    <Div
                        className="instantImageContainer"
                        style={{...sty.instantImageContainer, ...instantImageClickedStyle, ...StylesPlatform.cursorPointer}}
                        onClick={instantImageAlreadyClicked ? () => {} : this.handleInstantCustomMessageClick.bind(this, message)}
                    >
                        <Image
                            className="instantImageImage"
                            style={sty.instantImageImage}
                            src={instantPremiumMessage}
                        />
                        <Div className="instantImageTextContainer" style={sty.instantImageTextContainer}>
                            <Div className="instantImageTitle" style={sty.instantImageTitle}>
                                Premium Custom Message
                            </Div>
                            <Div className="lowerSnapContainer" style={sty.lowerSnapContainer}>
                                <Div className="instantImageTitle" style={sty.instantImageSquare}></Div>
                                <Div className="instantImageTitle" style={sty.instantImageNewImage}>
                                    Unlock Now →
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                    :
                    null
                }
            </Div>
        );
    }

    renderLottieWaiting(message) {
        return (
            <Div className="lottieWaiting" key={`lottie-waiting-${message['id']}`}>
                {this.renderChatBubbleLottie()}
            </Div>
        )
    }

    renderChatBubbleLottie() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Lottie
                width={50}
                options={{
                    animationData: chatLottie,
                    loop: true,
                    autoplay: true,
                }}
                speed={1}
                style={{margin: 0}}
            />
        );
    }

    renderChatMessages() {
        // NOTE: customerDict is not used yet, might need later
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const customerDict = Utils.arrayToObject(pr.customers, "id");

        let messages = [];
        if(pr.isPublic) {
            messages = st.messages[0];
        } else {
            messages = st.messages[st.selectedConversationId] ?? [];
        }

        const messageContainer = StyleUtils.getMStyle(sty, 'messageContainer', pr.screen.width, "md");
        const messageStyle = StyleUtils.getMStyle(sty, 'messageStyle', pr.screen.width, "md");

        let messagesCopy = Utils.deepCopy(messages, true);

        if(st.allArtists.length) {
            const initArtistObj = this.getInitArtistAssets();

            messagesCopy.unshift(initArtistObj.initImage, initArtistObj.initAudioMessage);
        }

        if(st.tempNewMessage) {
            messagesCopy.push(st.tempNewMessage);
            // NOTE: don't know why this isn't working
            messagesCopy.push({id: -4, message_type: 4});
        }

        return messagesCopy.map((messageUnused, messageIndex) => {
            let message = messagesCopy[messageIndex];
            if(!message) {
                return null;
            }
            const status = Utils.get(message, "status");

            const messageTime = moment.utc(message['created_at']).local().format("LT");

            const isSuperChat = Utils.get(message, "message_type") == Constant.MESSAGE_TYPE_SUPER_CHAT;
            const isAudioChip = Utils.get(message, "message_type") == Constant.MESSAGE_TYPE_AUDIO_CHAT;
            const isImageStrip = Utils.get(message, "message_type") == Constant.MESSAGE_TYPE_IMAGE_STRIP;
            const isWaitingForResponse = Utils.get(message, "message_type") == Constant.MESSAGE_TYPE_WAITING_FOR_AI_RESPONSE;
            const isTopicReset = Utils.get(message, "message_type") == Constant.MESSAGE_TYPE_TOPIC_RESET;

            if(messageIndex == 0) {
                return null;
            } else if(isSuperChat) {
                return (
                    <Div
                        key={`msg-${message['id']}`}
                        style={ {...messageContainer, ...sty.messageSuperStyle} }
                        className="messageContainer superChatContainer gradientBorderPinkOrangeCss"
                    >
                        <Div className="superSendImageUsernameContainer" style={sty.superSendImageUsernameContainer}>
                            {message.body.length ?
                                null
                                :
                                this.renderAvatar(message, customerDict)
                            }
                            <Image
                                className="superChatImageFromProdChat"
                                style={{width: 89, marginRight: 8}}
                                src="https://dhwk6gixvyfl3.cloudfront.net/upload_2671c3fca8cbe9e9a3308c1d3c6bd221.png"
                            />
                            <Div style={sty.superChatCost} className="superChatCost">
                                ${Utils.get(message, "json_data.paidAmount")}
                            </Div>
                            <Div className="usernameFromDict">
                                &nbsp;by @{this.renderUsername(message, customerDict)}
                            </Div>
                            <Image
                                style={{...sty.infoIcon, ...StylesPlatform.cursorPointer}}
                                className="infoIcon"
                                src={infoIcon}
                                onClick={this.showSuperSendInfo.bind(this)}
                            />
                        </Div>
                        {message.body.length ?
                            <Div className="customerInfoContainer" style={sty.customerInfoContainer}>
                                {this.renderBadges(message, customerDict)}
                                {this.renderAvatar(message, customerDict)}
                                <Div className="customerName" style={sty.customerName} data-id={Utils.get(message, "customer_id")}>
                                    <Div className="messageStyleSuperContainer" style={messageStyle} dangerouslySetInnerHTML={{__html: message.body}} />
                                </Div>
                            </Div>
                            :
                            null
                        }
                    </Div>
                );
            } else if(isWaitingForResponse || status == 2) {
                // NOTE: messages type 4
                return this.renderLottieWaiting(message);
            } else if(status == 3 || status == 5 || status == 6) {
                return null;
            }  else if(isAudioChip) { // NOTE: message type 3
                return this.renderMessageAudioStrips(message, messages, messageIndex);
            } else if(isImageStrip) { // NOTE: message type 7
                return this.renderMessageImageStrip(message);
            }else if(isTopicReset) { // NOTE: messages type 5
                // share-up
                return(
                    <Div key={`msg-${message['id']}`} className="topicResetContainer" style={sty.topicResetContainer}>
                        <Div className="topicResetLine" style={sty.topicResetLine}></Div>
                        <Div className="topicResetText" style={sty.topicResetText}>
                            Topic Reset on June 12th, 2:30pm
                        </Div>
                        <Div className="topicResetLine" style={sty.topicResetLine}></Div>
                    </Div>
                )
            } else {
                return (
                    <React.Fragment key={`msg-${message['id']}-text-fragmentContainer`}>
                        <Div
                            key={`msg-${message['id']}-text`}
                            className="messageStyleContainer"
                            style={messageStyle}
                            dangerouslySetInnerHTML={{__html: message.body}}
                        />
                        {false && status == 4 ? this.renderLottieWaiting(message) : null}
                    </React.Fragment>
                );
            }
        });
    }

    renderCreatorPanelHeader() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div
                className="creatorPanelHeader"
                style={sty.creatorPanelHeader}
            >
                <Div
                    className="waveformInboxContainer"
                    style={{
                        display: "flex",
                        color: "white",
                        fontWeight: 300,
                        fontSize: 17,
                        alignItems: "center"
                    }}
                >
                    Inbox
                </Div>
                {/* {this.renderAudioToggleSwitch()} */}
                <Div
                    className="creditContainer"
                    style={{...sty.creditContainer, ...StylesPlatform.cursorPointer, ...{color: st.userCredits < 0 ? Colors.redError : "white"}}}
                    onClick={this.showBuyCreditsModal.bind(this)}
                >
                    <Div className="creditDot" style={sty.creditDot} />
                    <Div className="creditsText">
                        {st.userCredits > 0 ? Utils.commas(st.userCredits) : 0} credits
                    </Div>
                </Div>
            </Div>
        )
    }

    renderAiBadge() {
        return (
            <Image
                className="aiBadge"
                style={{width: 22, height: 21}}
                src={aiBadge}
            />
        );
    }

    renderViewLeaderboardButton() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const getPicImageUrl = Utils.get(st.artist, "json_data.getPicImage_url");

        const getPicEnabled = Utils.get(st, "artist.ai_online") && !this.isNoCredits() && !st.generatingImages;

        return (
            <Div
                id="leaderboardbutton"
                className="leaderboardButton"
                style={{...sty.getPicOuterStyle, ...StylesPlatform.cursorPointer, ...{width: pr.screen.mobile ? "100%" : "auto"}}}
                onClick={this.showLeaderboardSidePanel.bind(this)}
            >
                <Div
                    className="getPicText"
                    style={{fontSize: 17, fontWeight: 300}}
                >
                    Leaderboard
                </Div>
                <Image
                    className="getPicInnerStyle"
                    src={leaderboardTrophy}
                    style={sty.getPicInnerStyle}
                />
            </Div>
        )
    }

    renderGetPicturesButton() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!this.artistIdIsMatch()) {
            return <Div style={{height: 48}}></Div>;
        }

        const getPicImageUrl = Utils.get(st.artist, "json_data.getPicImage_url");

        const getPicEnabled = Utils.get(st, "artist.ai_online") && !this.isNoCredits() && !st.generatingImages;

        return (
            <Div
                id="getapic"
                className="getPicOuterStyle"
                style={{...sty.getPicOuterStyle, ...{opacity: getPicEnabled ? 1 : 0.25}, ...StylesPlatform.cursorPointer, ...{width: pr.screen.mobile ? "100%" : "auto"}}}
                onClick={this.handleGenerateImages.bind(this)}
            >
                <Div
                    className="getPicText"
                    style={{fontSize: 17, fontWeight: 300}}
                >
                    Get a Pic
                </Div>
                <Image
                    className="getPicInnerStyle"
                    src={aiCamera}
                    style={sty.getPicInnerStyle}
                />
            </Div>
        )
    }

    renderAudioToggleSwitch() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let audioMuteButton = StyleUtils.getMStyle(sty, 'audioMuteButton', pr.screen.width, "md");

        return (
            <>
                {!st.isMuted ?
                    <Icon
                        icon="audio"
                        size={24}
                        style={audioMuteButton}
                        onClick={this.toggleAutoPlay.bind(this)}
                    />

                    :
                    <Icon
                        icon="audio-slash"
                        style={{...audioMuteButton, ...StylesPlatform.cursorPointer}}
                        size={24}
                        onClick={this.toggleAutoPlay.bind(this)}
                    />
                }
            </>
        )
    }

    postClickHandler(target, value) {
        return;
        console.log("CLICK", target, value);
        if(target == 'chooseTip') {
            let tempState = {};
            tempState['showTipModalType'] = "tipchooser";
            //Analytics.triggerGa();
            this.setState(tempState);
        } else if(target == 'selectTip') {
            let tempState = {};
            const tipAmount = parseInt(value.replace("option",""));
            tempState['tipAmount'] = tipAmount;
            tempState['showTipModalType'] = "paymentTip";
            tempState['noChatSignals'] = true;
            console.log("CLICK setState", tempState, );
            this.setState(tempState);
        } else if(target == 'upgradeVipChat') {
            let tempState = {};
            tempState['noChatSignals'] = true;
            tempState['showTipModalType'] = "paymentActivateChatHost";
            this.setState(tempState);
        } else if(target == 'paidTip') {
            let tempState = {};
            tempState['noChatSignals'] = false;
            tempState['showTipModalType'] = "tipentermsg";
            this.setState(tempState);
        } else if(target == 'paidActivateChatHost') {
            // Deprecated. Remove once stripe is activated.
            let tempState = {};
            tempState['noChatSignals'] = false;
            tempState['showTipModalType'] = false;
            const roomId = Utils.get(this.props.roomItem, "id");
            Api.postUpgradeVipChat({'room_id':roomId}, (o) => {
                this.setState(tempState);
            });
        } else if(target == 'submitMessage') {
            //console.log("value", value);
            let tempState = {};
            tempState['noChatSignals'] = false;
            tempState['showTipModalType'] = false;
            this.setState(tempState);
            this.handleChatPost(value, 2, this.state.tipAmount);
        }
    }

    renderBuyCreditsModal(type) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let showPhoneEmailVerification = false;

        const modalType = st.buyModalType;
        let body;
        const localGlobalProps = {
            //setUser: pr.setUser.bind(this),
            screen: pr.screen,
            history: pr.history,
            user: pr.user,
            //change: this.change.bind(this),
            userLoaded: pr.userLoaded,
            selected: pr.selected,
        }

        let postId;
        let isDragon = false;
        let hideCloseButton = false;

        if(modalType == 'tipinfo') {
            postId = 130;
        } else if(modalType == 'tipinfo2') {
            postId = 134;
        } else if(modalType == 'chatboxinfo') {
            postId = 132;
        } else if(modalType == 'tipchooser') {
            postId = 128;
        } else if(modalType == 'firstTimeBuyer') {
            postId = 197;
            showPhoneEmailVerification = true;
        } else if(modalType == 'paymentTip') {
            body = (
                <Div className="paymentsWrapper" style={{width: "100%"}}>
                    <Payments
                        roomId = {Utils.get(this.props, "roomItem.id")}
                        amount={st.tipAmount}
                        paymentType={"tipPayment"}
                        completePayment={ () => { console.log('here'); this.postClickHandler('paidTip'); } }
                    />
                    { false ?
                        <Button onClick={() => { this.postClickHandler('paidTip') }}>Pay</Button>
                        :
                        null
                    }
                </Div>
            );
        } else if(modalType == 'paymentActivateChatHost') {
            body = (
                <Div className="paymentsWrapper" style={{width: "100%"}}>
                    <Payments
                        roomId = {Utils.get(this.props, "roomItem.id")}
                        amount={3.99}
                        paymentType={"upgradeVipChatPayment"}
                        completePayment={
                            () => {
                                console.log('PAYPAL complete');
                                this.setState({showTipModalType: false, tabSwitchSignal:1, noChatSignals:false});
                            }
                        }

                    />
                    { false ?
                        <Button onClick={() => { this.postClickHandler('paidActivateChatHost') }}>Pay</Button>
                        :
                        null
                    }
                </Div>
            );
        } else if(modalType == 'tipentermsg') {
            postId = 129;
            hideCloseButton = true;
        } else if(modalType == 'notvip') {
            postId = 131;
            isDragon = true;
        } else {
            // return null;
        }

        if(postId) {
            body = (
                <Div className="divPostBody">
                    <Post
                        {...localGlobalProps}
                        postId={postId}
                        postClickHandler={this.postClickHandler.bind(this)}
                    />
                </Div>
            );
        }

        return (
            <Modal
                key={`tip_modal_${postId}`}
                screen={pr.screen}
                className="postModalBodyForShopMoreTokens"
                hideCloseButton={hideCloseButton}
                onRequestClose={ () => { this.setState({showBuyCreditsModal: false}); } }
                color="indigo"
                style={{maxWidth: 500, backgroundColor: isDragon ? Colors.dragon : Colors.indigo}}
            >
                {body}
                {/* <Div style={{height: 800, width: "100%", backgroundColor: "red"}}/> */}
                {showPhoneEmailVerification ?
                    this.renderPhoneEmailVerification()
                    :
                    null
                }
            </Modal>
        );
    }

    nextWelcomeButton() {
        const welcomeStage = this.state.welcomeModalStage + 1;
        let showWelcomeModal = true;

        if(welcomeStage > 4) {
            showWelcomeModal = false;
        }
        this.setState({
            welcomeModalStage: welcomeStage,
            showWelcomeModal: showWelcomeModal,
        });
    }

    renderWelcomeStage() {
        const st = this.state;
        const sty = this.styles;

        const welcomeStages = [1, 2, 3, 4];
        const selectedWelcomeStageStyle = {opacity: 1};

        return (
            <>
                <Button
                    className="nextWelcomeButton"
                    color="pinkGradient"
                    style={{width: "100%", marginTop: "auto"}}
                    onClick={this.nextWelcomeButton.bind(this)}
                >
                    Next
                </Button>
                <Div className="welcomeStages" style={{display: "flex", alignItems: "center", justifyContent: "center", gap: 8}}>
                    {welcomeStages.map((currentWelcomeStage, index) => {
                        let welcomeStyle = sty.welcomeStageStyle;
                        if(st.welcomeModalStage == currentWelcomeStage) {
                            welcomeStyle = {...sty.welcomeStageStyle, ...selectedWelcomeStageStyle};
                        }
                        return (
                            <Div
                                className="welcomeStageStyle"
                                style={welcomeStyle}
                            >
                            </Div>
                        );
                    })}
                </Div>
            </>
        )
    }

    renderWelcomeModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let body = null;

        const initArtistObj = this.getInitArtistAssets();
        if(!initArtistObj.initAudioMessage) {
            return null;
        }
        const message = initArtistObj.initAudioMessage;
        const audioUrl = Utils.get(message, "json_data.audio");

        if(!st.showWelcomeModal || st.welcomeModalStage > 4) {
            return null;
        }

        const artistTitle = Utils.get(st, "artist.title", "").split(" ")[0];
        let playFlag = this.getFirstPlayMessage(null, message);

        switch(st.welcomeModalStage) {
            case 1:
                body = (
                    <>
                        <Image
                            className="welcomePage1 weclomePartyHorn"
                            src={weclomePartyHorn}
                            style={{width: 40, maxWidth: 40, height: 40}}
                            key="unique-key-1"
                        />
                        <Div style={{textAlign: "center", fontSize: 18, fontWeight: 500}}>
                            Welcome To Afterparty AI
                        </Div>
                        <Div style={{fontWeight: 300, textAlign: "center"}}>
                            Use your free credits for messages and photos from {artistTitle}. Your feedback is vital so please answer any survey questions when prompted.
                        </Div>
                        {this.renderWelcomeStage()}
                    </>
                );
                break;
            case 2:
                body = (
                    <>
                        <AudioChip
                            audioPlayer={st.audioPlayer}
                            playAudio={this.playAudio.bind(this)}
                            pauseAudio={this.pauseAudio.bind(this)}
                            user={pr.user}
                            src={audioUrl}
                            roomChat={message}
                            artist={st.artist}
                            backgroundImage={Utils.get(st, "artist.audio_chip_background_image_url")}
                            avatar={Utils.get(st, "artist.image_url")}
                            screen={pr.screen}
                            feedback={st.feedbackTypes}
                            submitFeedback={this.submitAudioFeedback.bind(this)}
                            playFlag={playFlag}
                            isWelcome={st.showWelcomeModal}
                            hideShare
                        />
                        <Div style={{fontSize: 18, textAlign: "center", marginTop: 0, fontWeight: 500}}>
                            Send Messages to Get An Audio Reply From {artistTitle}
                        </Div>
                        {this.renderWelcomeStage()}
                    </>
                );
                break;
            case 3:
                body = (
                    <>
                        {this.renderModalExampleImages()}
                        <Image
                            className="getPicButtonWelcome"
                            src={getPicButtonWelcome}
                            style={{width: 127, marginTop: -56, zIndex: 2}}
                        />
                        <Div style={{fontSize: 18, textAlign: "center", fontWeight: 500,  }}>
                            Tap “Get a Pic” Icon To Request<br/>Unique Pictures, Just For You
                        </Div>
                        {this.renderWelcomeStage()}
                    </>
                );
                break;
            case 4:
                body = (
                    <>
                        <AudioChip
                            audioPlayer={st.audioPlayer}
                            playAudio={this.playAudio.bind(this)}
                            pauseAudio={this.pauseAudio.bind(this)}
                            user={pr.user}
                            src={audioUrl}
                            roomChat={message}
                            artist={st.artist}
                            backgroundImage={Utils.get(st, "artist.audio_chip_background_image_url")}
                            avatar={Utils.get(st, "artist.image_url")}
                            screen={pr.screen}
                            feedback={st.feedbackTypes}
                            submitFeedback={this.submitAudioFeedback.bind(this)}
                            playFlag={playFlag}
                            isWelcome={st.showWelcomeModal}
                            hideShare
                        />
                        <Div style={{fontSize: 18, textAlign: "center", marginTop: 0, fontWeight: 500}}>
                            Ask For any custom message from {artistTitle} for friends and family!
                        </Div>
                        {this.renderWelcomeStage()}
                    </>
                );
                break;
            default:
                body = (
                    <>
                        No welcome stage
                    </>
                );
        }

        return (
            <Modal
                screen={pr.screen}
                className="welcomeModal"
                color="indigoLight"
                style={{maxWidth: 375, minHeight: 360, gap: 16, justifyContent: "center", }}
                onRequestClose={this.closeWelcomeModal.bind(this)}
            >
                {body}
            </Modal>
        );
    }

    closeWelcomeModal() {
        this.setState({
            showWelcomeModal: false,
        });
    }

    surveySubmit(feedbackType, value) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let messages = Utils.deepCopy(st.messages);
        let mostRecentMessage = {id: -1};

        if(Utils.get(st, "messsages.length", [])) {
            mostRecentMessage = st.messages[st.messages.length - 1]
        }

        const feedbackData = [{
            feedback_type_id: feedbackType.id,
            feedback_type_title: feedbackType.title,
            room_chat_id: Utils.get(mostRecentMessage, "id"),
            artist_id: Utils.get(st.artist, "id"),
            feedback_value: value,
        }];

        Api.postAiFeedback(feedbackData, (res) => {
            if(Utils.get(res, "errors", []).length) {
                console.log("ERRORS: ", Utils.get(res, "errors"));
            }

            this.setState({
                feedbackSurveyCounter: this.state.feedbackSurveyCounter + 1,
                showSurveySuccessModal: true,
                aiMessageCounter: this.state.aiMessageCounter + 1,
            });
        });
    }

    renderAiSurveryBoolean(feedbackType) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={{padding: "8px 16px", backgroundColor: "rgba(255, 255, 255, 0.1)", display: "flex", gap: 38, borderRadius: 100,}}>
                <Icon
                    icon="thumbs-up"
                    color="white"
                    style={{...{opacity: .6}, ...StylesPlatform.cursorPointer}}
                    size={34}
                    onClick={this.surveySubmit.bind(this, feedbackType, 1)}
                />
                <Icon
                    icon="thumbs-down"
                    color="white"
                    style={{...{opacity: .6}, ...StylesPlatform.cursorPointer}}
                    size={34}
                    onClick={this.surveySubmit.bind(this, feedbackType, 0)}
                />
            </Div>
        );
    }

    renderAiSurveyInteger1to5(feedbackType) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div style={{padding: "8px 16px", display: "flex", gap: 8, borderRadius: 100}}>
                {st.surveryInteger1to5Option.map((option, index) => {
                    return (
                        <Div
                            className="numberOption"
                            style={{...sty.numberOption, ...StylesPlatform.cursorPointer}}
                            onClick={this.surveySubmit.bind(this, feedbackType, option.value)}
                        >
                            <Div className="bigNumberOption" style={{fontSize: 30, fontWeight: 300}}>
                                {option.text}
                            </Div>
                        </Div>
                    );
                })}
            </Div>
        );
    }

    renderMarketingModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const postId = st['showMarketingModalPostId'];

        return (
            <Modal
                screen={pr.screen}
                className="marketingModal"
                color="indigoLight"
                style={{maxWidth: 500, gap: 0, textAlign: "center", minHeight: 286, justifyContent: "center"}}
                onRequestClose={ () => {
                    let tempState = {showMarketingModal: false };
                    const shownMessageId = st['showMarketingModal'];
                    tempState['alreadyShowModalForMsg-'+shownMessageId] = true;
                    this.setState(tempState);
                } }
            >
                <Post
                    {...this.getLocalGlobalProps()}
                    postId={postId}
                    postClickHandler={this.postClickHandler.bind(this)}
                />
            </Modal>
        );
    }

    renderSurveyModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const feedbackSurveyTypes = Utils.get(st, "feedbackTypes.3", []);
        const feedbackSurveyTypesLen = feedbackSurveyTypes.length;
        const feedbackSurveyIndex = st.feedbackSurveyCounter % feedbackSurveyTypesLen;
        const feedbackType = feedbackSurveyTypes[feedbackSurveyIndex];

        if(st.aiMessageCounter % 6 != 5) {
            // NOTE: every fifth message will show the survey
            return null;
        }

        let surveyBody = null;

        switch(Utils.get(feedbackType, "return_data_type")) {
            case Constant.AI_SURVEY_BOOLEAN:
                surveyBody = this.renderAiSurveryBoolean(feedbackType);
                break;
            case Constant.AI_SURVEY_INTEGER:
                surveyBody = <Div>Constant.AI_SURVEY_INTEGER</Div>;
                break;
            case Constant.AI_SURVEY_TEXT:
                surveyBody = <Div>Constant.AI_SURVEY_TEXT</Div>;
                break;
            case Constant.AI_SURVEY_INTEGER_1_TO_5:
                surveyBody = this.renderAiSurveyInteger1to5(feedbackType);
                break;
        }

        return (
            <Modal
                screen={pr.screen}
                className="surveyModal"
                color="indigoLight"
                style={{maxWidth: 500, gap: 25, textAlign: "center", minHeight: 286, justifyContent: "center"}}
                hideCloseButton
            >
                <>
                    <Div
                        className="feedbackSurveyModalTitle"
                        style={{fontSize: 18, maxWidth: 280}}
                    >
                        {Utils.get(feedbackType, "title", "").replace("${artist.title}", Utils.get(st, "artist.title"))}
                    </Div>
                    {surveyBody}
                    <Div style={{fontWeight: 300, width: 184, opacity: 0.5}}>
                        Your response helps improve Afterparty AI experience
                    </Div>
                </>
            </Modal>
        );
    }

    renderSurveySuccessModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Modal
                screen={pr.screen}
                className="surveySuccessModal"
                color="indigoLight"
                style={{maxWidth: 375, gap: 16, textAlign: "center"}}
                onRequestClose={() => { this.setState({showSurveySuccessModal: false}); }}
            >
                <>
                    <Div style={{fontSize: 18}}>
                        Thanks For The Feedback!
                    </Div>
                    <Button
                        className="surveryDoneButton"
                        style={{width: "100%"}}
                        onClick={() => { this.setState({showSurveySuccessModal: false}); }}
                        color="white"
                    >
                        Done
                    </Button>
                </>
            </Modal>
        );
    }

    getLocalGlobalProps() {
        const pr = this.props;
        const localGlobalProps = {
            //setUser: pr.setUser.bind(this),
            screen: pr.screen,
            history: pr.history,
            user: pr.user,
            //change: this.change.bind(this),
            userLoaded: pr.userLoaded,
            selected: pr.selected,
        }
        return localGlobalProps;
    }


    renderMoreCreditsModal(dialogNum) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const localGlobalProps = {
            //setUser: pr.setUser.bind(this),
            screen: pr.screen,
            history: pr.history,
            user: pr.user,
            //change: this.change.bind(this),
            userLoaded: pr.userLoaded,
            selected: pr.selected,
        }

        let aiPostSurveyNumber = st.aiPostSurveyNumber;
        let hideCloseButton = st.aiPostSurveyNumber < 4;
        if(dialogNum) {
            aiPostSurveyNumber = dialogNum;
            hideCloseButton = false;
        } else  if(aiPostSurveyNumber > 4) {
            return null;
        }

        return (
            <Modal
                screen={pr.screen}
                className="moreCreditsModal"
                color="indigoLight"
                style={{maxWidth: 500, gap: 16, textAlign: "center"}}
                onRequestClose={() => {
                    if(dialogNum) {
                        this.setState({renderMoreCreditsModal:false});
                    } else {
                        this.incrementAiPostSurveyNumberCookie();
                    }
                }}
                hideCloseButton={hideCloseButton}
            >
                <Post
                    {...localGlobalProps}
                    // TODO: `/p/ai_mid_survey_${Utils.get(st.artist, "")}`
                    pathName={`/p/jaden_ai_mid_survey_${aiPostSurveyNumber}`}
                    postApiCallClickHandler={this.handleEndSurveyCallback.bind(this)}
                />
                {this.renderPhoneEmailVerification()}
                {/* <Image
                    src={credits400}
                    style={{width: 94}}
                />
                <Div style={{fontSize: 18}}>
                    Get 400 More Credits!
                </Div>
                <Div style={{fontWeight: 300, width: 300, color: "#E4D5FA"}}>
                    Thanks for testing out our AI chat conversations
                </Div>
                <Div style={{fontWeight: 300, width: 300, color: "#E4D5FA"}}>
                    For An extra 400 Credits, please fill out our Feedback Survey by clicking the link below!
                </Div>
                <Button
                    className="startFeedbackSurvey"
                    style={{width: "100%"}}
                    onClick={() => { this.setState({showMoreCreditsModal: false}); }}
                    color="pinkGradient"
                    onClick={Utils.gotoRoute.bind(this, pr, "p/jaden_ai_beta_exit_survey")}
                >
                    Start Feedback Survey
                </Button> */}
            </Modal>
        );
    }

    renderThanksForFeedbackModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Modal
                screen={pr.screen}
                className="thanksForFeedbackModal"
                color="indigoLight"
                style={{maxWidth: 375, gap: 16, textAlign: "center"}}
                onRequestClose={() => { this.setState({showThanksForFeedbackModal: false}); }}
            >
                <>
                    <Image
                        src={credits100}
                        style={{width: 94, marginTop: -40}}
                    />
                    <Div style={{fontSize: 18}}>
                        Thanks For The Feedback!
                    </Div>
                    <Button
                        className="startFeedbackSurvey"
                        style={{width: "100%"}}
                        onClick={() => { this.setState({showSurveySuccessModal: false}); }}
                        color="white"
                    >
                        Done
                    </Button>
                </>
            </Modal>
        );
    }

    renderCustomAiSelfieModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Modal
                screen={pr.screen}
                className="customAiSelfieModal"
                color="indigoLight"
                style={{maxWidth: 375, gap: 16, textAlign: "center"}}
                onRequestClose={() => { this.setState({showCustomAiSelfieModal: false}); }}
            >
                <>
                    {/* <Image
                        src={credits100}
                        style={{width: 94, marginTop: -40}}
                    /> */}
                    <Div style={{fontSize: 18, }}>
                        We can’t wait to share this feature with you!
                    </Div>
                    <Div style={{fontWeight: 300, width: 300, color: "#E4D5FA"}}>
                        We can't wait to share this technology with you
                    </Div>
                    <Button
                        className="customAiSelfieButtonClose"
                        style={{width: "100%"}}
                        onClick={() => { this.setState({showCustomAiSelfieModal: false}); }}
                        color="pinkGradient"
                    >
                        OK!
                    </Button>
                </>
            </Modal>
        );
    }

    renderSendToArtistModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Modal
                screen={pr.screen}
                className="sendToArtistModal"
                color="indigoLight"
                style={{maxWidth: 375, gap: 16, textAlign: "center"}}
                onRequestClose={() => { this.setState({showSendToArtistModal: false}); }}
            >
                <>
                    <Div style={{fontSize: 24, marginTop: -16}}>
                        Image sent to {Utils.get(st, "artist.title", "Creator")}!
                    </Div>
                    <Button
                        className="customAiSelfieButtonClose"
                        style={{width: "100%"}}
                        onClick={() => { this.setState({showSendToArtistModal: false}); }}
                        color="pinkGradient"
                    >
                        OK!
                    </Button>
                </>
            </Modal>
        );
    }

    renderModalExampleImages() {
        const pr = this.props;
        const st = this.state;
        const sty = Utils.deepCopy(this.styles);

        const artistImages = Utils.get(st.artist, "json_data.aiChatModalExampleImages");
        if(!artistImages) {
            return null;
        }
        const imageRotateSize = pr.screen.mobile ? 120 : 140;
        const imageRotateMarginTop = pr.screen.mobile ? 20 : 30;
        let rotateLeftStyle = {
            transform: "rotate(-19.278deg)",
            marginRight: -60,
            marginTop: imageRotateMarginTop,
            width: imageRotateSize,
            height: imageRotateSize,
            zIndex: 1,
        };

        let rotateRightStyle = {
            transform: "rotate(19.278deg)",
            marginLeft: -60,
            marginTop: imageRotateMarginTop,
            width: imageRotateSize,
            height: imageRotateSize,
            zIndex: 1,
        };

        return (
            <Div className="modalExampleImagesContainer" style={sty.modalExampleImagesContainer}>
                {artistImages.map((exampleImage, exampleImageIndex) => {
                    let modalExampleImageContainerStyle = StyleUtils.getMStyle(sty, 'modalExampleImageContainer', pr.screen.width, "md");


                    if(exampleImageIndex == 0) {
                        modalExampleImageContainerStyle = {
                            ...sty.modalExampleImageContainer,
                            ...rotateLeftStyle,
                        };
                    } else if(exampleImageIndex == 2) {
                        modalExampleImageContainerStyle = {
                            ...sty.modalExampleImageContainer,
                            ...rotateRightStyle,
                        };
                    }

                    return (
                        <Div
                            key={`modalExampleImages-${exampleImageIndex}`}
                            className="modalExampleImageContainer"
                            style={modalExampleImageContainerStyle}
                        >
                            <Image
                                src={Utils.get(exampleImage, "image_url")}
                                style={{width: "100%", borderRadius: 10}}
                            />
                        </Div>
                    );
                })}
            </Div>
        );
    }

    renderSelectPhotoModal(type) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const artistId = Utils.get(st, "artist.id");

        if(!this.artistIdIsMatch()) {
            return null;
        }

        const modalType = st.buyModalType;
        let body;
        const localGlobalProps = {
            screen: pr.screen,
            history: pr.history,
            user: pr.user,
            userLoaded: pr.userLoaded,
            selected: pr.selected,
        }

        let postId;
        let isDragon = false;
        let hideCloseButton = false;

        const jadenCustomOption = {id: 6, title: "Custom T-Shirt", name: "custom"};

        const radioStyleBase = {
            border: "1px solid " + Colors.indigo,
            background: Colors.indigoLighter,
            fontWeight: 400,
            borderRadius: 8,
            padding: pr.screen.mobile ? "20px 20px" : "30px 20px",
            width:"100%",
            textAlign:"center",
            cursor:"pointer",
            fontSize: 16,
        };

        let radioStyle = radioStyleBase;
        let radioStyleSelect = {
            ...radioStyleBase,
            ...{border: "3px solid white"},
        };

        const itemCustomStyle = -1 == st.selectPhotoTypeIdx ? radioStyleSelect : radioStyle;
        let itemCustomTitle = jadenCustomOption['title'];

        const artistTitle = Utils.get(st.allArtists, ""+st.selectedCreatorIndex+".title", "");
        itemCustomTitle = itemCustomTitle.replace("Jaden", artistTitle);

        return (
            <Modal
                key={`photo_modal_${postId}`}
                screen={pr.screen}
                className="getAiPicModal"
                hideCloseButton={hideCloseButton}
                onRequestClose={ () => { this.setState({showSelectPhotoModal: false}); } }
                color="indigo"
                style={{maxWidth: 500, maxHeight: pr.screen.mobile ? 600 : 800, backgroundColor: isDragon ? Colors.dragon : Colors.indigo, minHeight: 500}}
                fullScreen={pr.screen.mobile}
            >
                <Div style={{display: "flex", flexDirection: "column", gap: 8, alignItems: "center", marginBottom: 16}}>
                    <Icon
                        icon="camera"
                        size={24}
                        style={{color: "#762CE5"}}
                    />
                    <Div style={{fontSize: 18}}>One Of A Kind Pics</Div>
                    <Div style={{fontSize: 15, fontWeight: 300}}>Uniquely generated, just for you!</Div>
                </Div>

                {this.renderModalExampleImages()}

                <Div className="aiPictureOptions hide-scrollbar" style={sty.aiPictureOptions}>
                    {Utils.get(st, "artist.artist_imagegen_locations", []).map((item, idx) => {
                        const itemStyle = idx == st.selectPhotoTypeIdx ? radioStyleSelect : radioStyle;
                        let itemTitle = item['title'];

                        itemTitle = item.title;

                        let itemBackgroundImage = null;
                        if(item.published_image_url) {
                            itemBackgroundImage = {
                                backgroundImage: `url(${item.published_image_url})`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                opacity: 1,
                            };
                        }

                        console.log("ITEMPHOTO", item);
                        return (
                            <Div key={`locations-${item['id']}`} className={`aiPictureOption-${item['id']}`} style={{width:"100%"}}>
                                <Div
                                    onClick={() => {this.setState({selectPhotoTypeIdx: idx})}}
                                    style={{...itemStyle, ...itemBackgroundImage}}
                                >
                                    {itemTitle}
                                </Div>
                                {idx == st.selectPhotoTypeIdx && item['name'].indexOf("custom") != -1 ?
                                    <Input
                                        value={Utils.get(this.state, `customImageGenName`, '')}
                                        onChange={this.changeVal.bind(this, `customImageGenName`)}
                                        placeholder="Enter your name..."
                                        style={{marginTop: 10}}
                                    />
                                    :
                                    null
                                }
                            </Div>
                        );
                    })}
                    {/* <Div
                        className="aiSelfieOption"
                        style={{...radioStyle, ...{background: Colors.pinkGradient, color: "white"}}}
                        onClick={this.showCustomAiSelfieModal.bind(this)}
                        id="customaiselfie"
                    >
                        Custom AI Selfie
                    </Div> */}
                </Div>

                <Div className="creditsText" style={sty.creditsText}>
                    This will use 200 credits
                </Div>
                <Button
                    className="getPicSubmitButton"
                    color="white"
                    style={{width: "100%", fontWeight: 500}}
                    onClick={
                        () => {
                            let newMessageData = {
                                body: "GEN-"+Utils.get(st.artist.artist_imagegen_locations, st.selectPhotoTypeIdx+".name"),
                                promptTemplate: Utils.get(st.artist.artist_imagegen_locations, st.selectPhotoTypeIdx+".id"),
                                room_id: st.selectedConversationId,
                                feedType: st.chatTabSelected != "host" ? 1 : 2,
                                artistId: this.getArtistId(),
                                customImageGenName: Utils.get(st, 'customImageGenName'),
                            };

                            this.setState({
                                aiMessageCounter: this.state.aiMessageCounter + 1,
                                showSelectPhotoModal: this.state.aiMessageCounter + 1 == 4,
                            });

                            if(newMessageData.room_id) {
                                this.postChatMessageNetworkCall(newMessageData);
                            } else {
                                const roomText = `${pr.user.username} conversation with ${st.artist.title}`;
                                const roomTextUnderscores = `${pr.user.username}_conversation_with_${st.artist.title}`;
                                const newConversationData = {
                                    artist_id: this.getArtistId(),
                                    convertTitle: "FIRST CONVERSATION",
                                    description: roomText,
                                    name: roomTextUnderscores,
                                    owner_id: pr.user.id,
                                    room_chat_type: "4", // NOTE: AI Chat Type
                                    title: roomText,
                                };

                                Api.postConversationAiChat(newConversationData, (newConversationRes) => {
                                    const followingList = Utils.get(pr, "user.following");
                                    this.processAiConversations(followingList, newConversationRes);
                                    const newRoomId = Utils.get(newConversationRes, "data.0.id");
                                    newMessageData.room_id = newRoomId;
                                    Utils.setCookie("selectedConversationId", newRoomId);
                                    console.log("FIRST MESSAGE of First conversation");
                                    newMessageData['firstMessage'] = true;
                                    this.postChatMessageNetworkCall(newMessageData);
                                });
                            }

                            this.setState({showSelectPhotoModal: false});
                        }
                    }
                >
                    Submit
                </Button>
                {/* <Div className="getPicParagraphText" style={sty.getPicParagraphText}> */}
                    {/* <Div>By clicking submit, you agree to spend 500 credits to have an AI Image generated</Div> */}
                    {/* <Div> You may get results that are unexpected. Please respond with a thumbs up or thumbs down so we can improve the Afterparty Experience.</Div> */}
                {/* </Div> */}
            </Modal>
        )
    }

    selectPlatform(isIos) {
        this.setState({
            platformIsIos: isIos,
        });
    }

    renderTurnOnNotificationsModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let selectedPlatformStyle = null;
        let defaultPlatformDStyle = null;
        let platformStyle = null;

        defaultPlatformDStyle = {
            color: "white",
            background: Colors.indigoLight,
            borderRadius: 100,
            padding: "6px 8px",
        };

        selectedPlatformStyle = {
            ...defaultPlatformDStyle,
            ...{
                color: Colors.indigo,
                background: "white",
            },
        }

        return (
            <Modal
                screen={pr.screen}
                className="turnOnNotificationsModal"
                color="indigo"
                style={{maxWidth: 375, gap: 16, textAlign: "center", border: "1px solid #4E3A6E", fontWeight: 400}}
                onRequestClose={() => { this.setState({showTurnOnNotificationsModal: false}); }}
            >
                <>
                    <Image
                        src={pwaNotificationsBell}
                        style={{width: 52, marginBottom: 8}}
                    />
                    <Div style={{fontSize: 24, marginTop: -16}}>
                        Turn on Notifications
                        <br/>
                        and Earn 2500 Credits
                    </Div>
                    <Div className="orangeBanner" style={sty.orangeBanner}>
                        Get +50  Interactions
                    </Div>
                    <Div className="togglePlatform" style={{...sty.togglePlatform, ...StylesPlatform.cursorPointer}}>
                        <Div
                            className="forIos"
                            onClick={this.selectPlatform.bind(this, true)}
                            style={st.platformIsIos ? selectedPlatformStyle : defaultPlatformDStyle}
                        >
                            For iOS
                        </Div>
                        <Div
                            className="forAndroid"
                            onClick={this.selectPlatform.bind(this, false)}
                            style={!st.platformIsIos ? selectedPlatformStyle : defaultPlatformDStyle}
                        >
                            For Android
                        </Div>
                    </Div>

                    <Div className="pwaStepRow1" style={sty.pwaStepRow}>
                        <Image src={pwaStep1} style={{width: 24}} />
                        <Div>
                            Scan QR Below with your {st.platformIsIos ? "iOS" : "Android"} Device
                        </Div>
                    </Div>
                    <Div className="pwaStepRow2" style={sty.pwaStepRow}>
                        <Image src={pwaStep2} style={{width: 24}} />
                        <Div>
                            Follow Prompts to enable notifications
                            {!st.platformIsIos ? " on your browser" : null}
                        </Div>
                    </Div>

                    <Div
                        style={{
                            borderRadius: 16,
                            background: "linear-gradient(180deg, #6321EF 0%, #8600B1 100%)",
                            padding: "11px 11px 7px 11px",
                        }}
                    >
                        <Image
                            className="pwaQrCode"
                            style={{width: 84, borderRadius: 8}}
                            src={pwaQrCode}
                        />
                    </Div>

                    <Div className="codeDoesNotWork" style={{maxWidth: 266}}>
                        If the code doesn’t work, visit <Span style={{...StylesPlatform.cursorPointer, ...{textDecoration: "underline"}}} onClick={Utils.gotoRoute.bind(this, pr, "pwa?next=private/aichat")}>afterparty.com/pwa</Span> on Mobile
                    </Div>
                </>
            </Modal>
        );
    }

    renderInfoBanner() {
        const pr = this.props;
        const st = this.state;
        let sty = Utils.deepCopy(this.styles);

        let pwaBanner = sty.pwaBanner;
        let bannerBody = null;
        let bannerFunction = Utils.gotoRoute.bind(this, pr, "p/first_time_offer/artist_id-94");

        if(st.userCredits <= 0) { // NOTE: no credits
            pwaBanner.background = "linear-gradient(180deg, #FF007E 0%, #FF3D00 100%";
            bannerBody = (
                <>
                    <Div>You've run out of credits</Div>
                    <Div>Get Credits →</Div>
                </>
            );
        } else if(st.userCredits <= 200 && false) { // NOTE: low credits
            pwaBanner.background = "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%";
            bannerBody = (
                <>
                    <Div>You're running low on credits</Div>
                    <Div>Get Credits →</Div>
                </>
            );
        } else { // NOTE: show PWA banner
            return null;
            if(Utils.getCookie("pwaNotificationStatus") == 1) {
                return null;
            }
            pwaBanner.background = "linear-gradient(180deg, #6321EF 0%, #8600B1 100%)";
            bannerFunction = Utils.gotoRoute.bind(this, pr, "pwa");
            if(Utils.getOsName() == "Android") {
                bannerBody = (
                    <>
                        <Div>Enable notifications and earn 2500 credits</Div>
                        <Div>Enable →</Div>
                    </>
                );
            } else {
                bannerBody = (
                    <>
                        <Div>Install Afterparty & get 2500 Credits</Div>
                        <Div>Install →</Div>
                    </>
                );
            }
        }

        return (
            <Div
                className="pwaBanner"
                style={{...pwaBanner, ...StylesPlatform.cursorPointer}}
                onClick={bannerFunction}
            >
                {bannerBody}
            </Div>
        );
    }

    renderAutoScrollInfo() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="autoScrollTesting" style={sty.autoScrollTesting}>
                st.autoScroll: {Utils.renderGreenRed(st.autoScroll)}<br/>
                this.scrollHeight: {this.scrollHeight}
            </Div>
        );
    }

    renderFreeCreditsModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const localGlobalProps = {
            screen: pr.screen,
            history: pr.history,
            user: pr.user,
            userLoaded: pr.userLoaded,
            selected: pr.selected,
        }

        return (
            <Modal
                screen={pr.screen}
                className="showFreeCreditsModal"
                onRequestClose={ () => { this.setState({showFreeCreditsModal: false}); } }
                style={{maxWidth: 360, paddingBottom: 20}}
                color="indigoLight"
            >
                <Post
                    {...localGlobalProps}
                    postId={240}
                    postApiCallClickHandler={() => {
                        Api.apiLoadProfile((profileRes) => {
                            pr.setUser(profileRes, () => {
                                this.setState({showFreeCreditsModal: false});
                            });
                        });
                    }}
                />
            </Modal>
        );
    }

    renderNoFollowsModal() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Modal
                screen={pr.screen}
                className="showNoFollowsModal"
                // title="Find New Creators"
                onRequestClose={ () => { this.setState({showNoFollowsModal: false}); } }
                style={{maxWidth: 360, paddingBottom: 20}}
                color="indigoLight"
                hideCloseButton
            >
                <Image
                    className="startChatting"
                    src={startChatting}
                    style={{width: 300, marginTop: -20}}
                />
                <Button
                    onClick={Utils.gotoRoute.bind(this, pr, "p/creator_directory")}
                    color="pinkGradient"
                    style={{width: "100%"}}
                >
                    Chat with Creators
                </Button>
            </Modal>
        );
    }

    render() {
        const pr = this.props;
        const st = this.state;
        let sty = Utils.deepCopy(this.styles);

        const customerDict = Utils.arrayToObject(pr.customers, "id");
        const iconStyle = st.posting ? {...StylesPlatform.cursorDefault, ...{opacity: 0.5}} : {...StylesPlatform.cursorPointer};

        const artistId = Utils.get(st, "artist.id");

        let aiChatboxComponent = StyleUtils.getMStyle(sty, 'aiChatboxComponent', pr.screen.width, "md");
        let aiChatboxSidePanel = StyleUtils.getMStyle(sty, 'aiChatboxSidePanel', pr.screen.width, "md");
        let aiChatboxContainer = StyleUtils.getMStyle(sty, 'aiChatboxContainer', pr.screen.width, "md");
        let aiChatHeader = StyleUtils.getMStyle(sty, 'aiChatHeader', pr.screen.width, "md");
        let aiScrollContainer = StyleUtils.getMStyle(sty, 'aiScrollContainer', pr.screen.width, "md");
        let aiInputContainer = StyleUtils.getMStyle(sty, 'aiInputContainer', pr.screen.width, "md");
        let chatboxInputBelow = StyleUtils.getMStyle(sty, 'chatboxInputBelow', pr.screen.width, "md");
        let getPicIconContainer = StyleUtils.getMStyle(sty, 'getPicIconContainer', pr.screen.width, "md");
        let creatorFollowingList = StyleUtils.getMStyle(sty, 'creatorFollowingList', pr.screen.width, "md");
        let artistNameHeaderContainer = StyleUtils.getMStyle(sty, 'artistNameHeaderContainer', pr.screen.width, "md");

        aiChatboxComponent.height = Utils.cssCalc(144);
        creatorFollowingList.height = Utils.cssCalc(155);
        aiScrollContainer.height = Utils.cssCalc("#chatboxWindow", ".aiInputContainer");
        aiChatboxSidePanel.height = Utils.cssCalc(".aiChatboxSidePanel");

        const inputReadOnly = st.firstLoad || st.aiChatIsLoading || st.posting || st.userCredits <= 0 || (st.userCredits <= 0 && st.aiPostSurveyNumber >= 4);
        const readOnlyInputStyle = inputReadOnly ? sty.readOnlyInputStyle : null;

        const isConversations = Utils.get(st, "backendConversations.length", 0) == 0;
        const isBalanceZero = Utils.get(pr, "user.ap_token_balance", 0) == 0;

        const chatboxMessageInputBelow = (
            <>
                {pr.screen.mobile ?
                    this.renderInfoBanner()
                    :
                    null
                }

                <Div className="aiInputContainer" style={aiInputContainer}>
                    {Utils.get(st, "artist.ai_online") == 0 ?
                        this.renderAiOfflineMessage()
                        :
                        <Div className="inputAndSendIconContainer" style={sty.inputAndSendIconContainer}>
                            <Div className="chatboxAndSendContainer" style={sty.chatboxAndSendContainer}>
                                <Input
                                    className="chatboxInputBelow1 aiMainChatInput"
                                    value={st.post}
                                    onChange={this.changePost.bind(this)}
                                    onFocus={() => {
                                        setTimeout(() => {
                                            //console.log("Utils.cssCalc(0) >>>>", Utils.cssCalc(0));
                                            // $("body").css("height", (Utils.cssCalc(0) - 450) + "px");
                                            // $(".aiChatboxComponent").css("height", (Utils.cssCalc(0) - 450) + "px");
                                        }, 3000);
                                    }}
                                    onKeyDown={this.keyDownPost}
                                    style={{...chatboxInputBelow, ...{fontSize: 16, backgroundColor: Colors.indigoLight, ...readOnlyInputStyle}}}
                                    // placeholder={`Reply to ${Utils.get(st, "artist.title", "...")}`}
                                    placeholder="Reply"
                                    readOnly={inputReadOnly}
                                />
                                <Icon
                                    icon="paper-plane"
                                    color="white"
                                    size={24}
                                    onClick={this.handleChatPost.bind(this)}
                                    style={{...sty.sendIconStyle, ...iconStyle}}
                                />
                            </Div>
                        </Div>
                    }
                </Div>
            </>
        );

        return (
            <Div className="aiChatboxComponent" style={{...aiChatboxComponent, ...pr.style}}>
                {Utils.getRequest("autoscroll") == 17 ?
                    this.renderAutoScrollInfo()
                    :
                    null
                }

                {st.showCreatorPanel ?
                    <Div className="aiChatboxSidePanel" style={aiChatboxSidePanel}>
                        <DebugBox show={this.state} />
                        {this.renderCreatorPanelHeader()}

                        <Div className="creatorFollowingList" style={creatorFollowingList}>
                            {st.allArtists.map((artist, artistIndex) => {
                                const selectedCreatorStyle = st.selectedCreatorIndex == artistIndex && !pr.screen.mobile ? sty.selectedCreatorStyle : null;

                                return (
                                    <Div
                                        key={`artistAiChat-${artistIndex}`}
                                        className="creatorFollowingRow"
                                        style={{...sty.creatorFollowingRow, ...selectedCreatorStyle, ...StylesPlatform.cursorPointer}}
                                        onClick={this.selectCreator.bind(this, artist, artistIndex)}
                                    >
                                        <Image
                                            src={artist.image_url}
                                            style={{borderRadius: 100, width: 40, height: 40, }}
                                        />
                                        <Div style={{marginLeft: 16, marginRight: "auto"}}>
                                            <Div className="rowInfoContainerAi" style={sty.rowInfoContinerAi}>
                                                {artist.title}
                                                {this.renderAiBadge()}
                                                {this.renderNumMessages(artist)}
                                            </Div>
                                            <Image
                                                className="waveformBig"
                                                src={waveformBig}
                                                style={{width: 86, opacity: 0.5}}
                                            />
                                        </Div>

                                        <Icon
                                            icon="ap-chevron-right"
                                            size={24}
                                            style={{marginLeft: 20}}
                                        />
                                    </Div>
                                );
                            })}
                            {/* <Div className="findCreatorsButton" style={sty.findCreatorsButton}>
                                <Button
                                    onClick={Utils.gotoRoute.bind(this, pr, "p/creator_directory")}
                                    color="pinkGradient"
                                >
                                    Find creators to have conversations with
                                </Button>
                            </Div> */}
                        </Div>
                    </Div>
                    :
                    null
                }

                <Div className="aiChatboxContainer" style={aiChatboxContainer}>
                    {pr.screen.mobile && !st.showCreatorPanel ?
                        <Div
                            className="aiChatboxBackButton"
                            onClick={this.showCreatorPanel.bind(this)}
                            style={sty.aiChatboxBackButton}
                        >
                            <Icon
                                size={24}
                                icon="ap-chevron-left"
                                style={StylesPlatform.cursorPointer}
                            />
                        </Div>
                        :
                        null
                    }

                    {pr.screen.mobile && false ?
                        <Div
                            className="audioToggleSwitchMobile"
                            style={{position: "absolute", top: 60, left: 6}}
                        >
                            {this.renderAudioToggleSwitch()}
                        </Div>
                        :
                        null
                    }

                    <Div className="aiChatHeader" style={{...aiChatHeader, ...{borderTop: !st.showCreatorPanel && pr.screen.mobile ? "none" : Colors.aiBorder}}}>
                        {st.showCreatorPanel && pr.screen.mobile ?
                            <AfterpartyLogo
                                width={156}
                                style={{...artistNameHeaderContainer, ...{marginLeft: 0}}}
                                history={pr.history}
                                screen={pr.screen}
                            />
                            :
                            <Div className="artistNameHeaderContainer" style={artistNameHeaderContainer}>
                                {Utils.get(st, "artist.image_url") ?
                                    <Image
                                        className="artistAvatar"
                                        style={sty.artistAvatar}
                                        src={Utils.get(st, "artist.image_url")}
                                    />
                                    :
                                    <Div style={{height: 32}} />
                                }

                                <Div style={{display: "flex", gap: 10}}>
                                    <Div
                                        className="artistName"
                                        style={sty.artistName}
                                    >
                                        {Utils.get(st, "artist.title").split(" ")[0]}
                                    </Div>
                                    {this.renderAiBadge()}
                                </Div>
                            </Div>
                        }

                        {Utils.getRequest('delete') == 17 ?
                            <Div>
                                <Button
                                    style={{marginLeft: 50}}
                                    onClick={
                                        () => {
                                            var roomId = st.selectedConversationId;
                                            Api.deleteRoom(roomId, () => {
                                                alert("Deleted.");
                                                Utils.setCookie("selectedConversationId", "");
                                                window.location.reload();
                                            });
                                        }
                                    }
                                >
                                    Delete Room
                                </Button>
                            </Div>
                            :
                            null
                        }
                        <Div className="getPicIconContainer" style={{...getPicIconContainer, ...StylesPlatform.cursorPointer}}>
                            {this.renderViewLeaderboardButton()}
                            {this.renderGetPicturesButton()}
                        </Div>
                    </Div>

                    <Div id="chatboxWindow" className="aiScrollContainer" style={aiScrollContainer}>
                        {st.firstLoad ?
                            <Spinner
                                message="Loading Messages"
                                style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 40}}
                            />
                            :
                            this.renderChatMessages()
                        }

                        {st.aiChatIsLoading ?
                            <Image
                                src={chatBubbleLoad}
                                style={{width: 44, marginTop: 16}}
                            />
                            :
                            null
                        }
                    </Div>

                    {chatboxMessageInputBelow}
                </Div>

                {st.showLeaderboardSidePanel ?
                    <Div className="showLeaderboardSidePanel" style={{...sty.showLeaderboardSidePanel, ...{height: Utils.cssCalc(72)}}}>
                        <Div className="leaderboardHeaderPanel" style={sty.leaderboardHeaderPanel}>
                            <Div style={{color: "white", fontWeight: 300, fontSize: 17}}>
                                Leaderboard
                            </Div>
                            <Image
                                style={{...{width: 33}, ...StylesPlatform.cursorPointer}}
                                src={leaderboardCloseButton}
                                onClick={() => {this.setState({showLeaderboardSidePanel: false})} }
                            />
                        </Div>
                        <Leaderboard
                            screen={pr.screen}
                            type={2}
                            artistId={artistId}
                            user={pr.user}
                            style={{paddingTop: 0}}
                        />
                    </Div>
                    :
                    null
                }

                {st.showCreateAccountModal ?
                    <Modal
                        screen={pr.screen}
                        className="showCreateAccountModal"
                        title="Create an Account to see Tana's reply"
                        onRequestClose={ () => { this.setState({showCreateAccountModal: false}); } }
                        hideCloseButton
                        style={{maxWidth: 360}}
                    >
                        <Button
                            className="createAnAccountButton"
                            style={{width: "100%", marginTop: 16, marginBottom: 8}}
                            color="pinkGradient"
                            onClick={this.showCreateSignInModal.bind(this, false)}
                        >
                            Create an Account
                        </Button>
                        <Button
                            className="signInButton"
                            style={{width: "100%"}}
                            type="outlined"
                            onClick={this.showCreateSignInModal.bind(this, true)}
                        >
                            Sign in
                        </Button>
                    </Modal>
                    :
                    null
                }

                {st.showCreateSignInModal ?
                    <Modal
                        screen={pr.screen}
                        className="showCreateSignInModal"
                        onRequestClose={ () => { this.setState({showCreateSignInModal: false}); } }
                    >
                        <WebappLoginRegister
                            isLogin={st.isLogin}
                            style={{minHeight: "auto"}}
                            screen={pr.screen}
                            history={pr.history}
                            user={pr.user}
                            userLoaded={pr.userLoaded}
                            setUser={pr.setUser.bind(this)}
                            onLoginToggle={this.onLoginToggle.bind(this)}
                            onLoginPhone={() => {}}
                            onLoginEmail={this.generalLoginSignUpHandle.bind(this)}
                            onSignUpPhone={() => {}}
                            onConfirmVerificationCodeLogin={this.generalLoginSignUpHandle.bind(this)}
                            onConfirmVerificationCodeSignUp={this.generalLoginSignUpHandle.bind(this)}
                        />
                    </Modal>
                    :
                    null
                }

                {st.showEnterBirthdateModal ?
                    <Modal
                        overlayId="welcome_age_modal"
                        overlayStyle={{display: "none"}}
                        className="showCreateSignInModal"
                        screen={pr.screen}
                        style={{
                            maxWidth: 343,
                            border: "1px solid rgba(255, 248, 237, 0.30)",
                            alignItems: "start",
                            padding: 16,
                        }}
                        title="Please enter your birthday"
                        hideCloseButton
                    >
                        <Div style={{gap: 10, display: "flex", maxWidth: 287, marginBottom: 10}}>
                            <Input
                                id="monthInput"
                                inputType="placeholderLabel"
                                value={st.monthText}
                                style={sty.inputStyleBirthday}
                                placeholder="Month"
                                type="tel"
                                onChange={this.monthChange.bind(this)}
                            />
                            <Input
                                inputType="placeholderLabel"
                                value={st.dayText}
                                style={sty.inputStyleBirthday}
                                placeholder="Day"
                                type="tel"
                                onChange={this.dayChange.bind(this)}
                            />
                            <Input
                                inputType="placeholderLabel"
                                value={st.yearText}
                                style={sty.inputStyleBirthday}
                                placeholder="Year"
                                type="tel"
                                onChange={this.yearChange.bind(this)}
                            />
                        </Div>
                        <Div className="agreeToTermsContainer" style={sty.agreeToTermsContainer}>
                            <Checkbox
                                color="white"
                                checked={st.agreeToTerms}
                                style={{marginLeft: 10, marginRight: 10, borderRadius: 100}}
                                onClick={this.toggleAgreeToTerms.bind(this)}
                            />
                            <Div style={{display: "flex"}}>
                                I Agree to the &nbsp;<Button type="text" style={{fontWeight: 300}} onClick={this.showTermsOfServiceModal.bind(this)}>Terms of Service</Button>
                            </Div>
                        </Div>
                        <Button
                            color="white"
                            style={{width: "100%"}}
                            onClick={this.submitBirthday.bind(this)}
                            disabled={!st.agreeToTerms}
                        >
                            Submit
                        </Button>

                        <ErrorBox
                            style={{marginTop: 20, width: "100%"}}
                            errorMessages={st.birthdateErrors}
                        />
                    </Modal>
                    :
                    null
                }

                {st.showSelectPhotoModal ?
                    this.renderSelectPhotoModal()
                    :
                    null
                }

                {st.showBuyCreditsModal ?
                    this.renderBuyCreditsModal()
                    :
                    null
                }

                {st.showImageFeedbackMenu ?
                    this.renderLikeDislikeModal()
                    :
                    null
                }

                {st.showWelcomeModal ?
                    this.renderWelcomeModal()
                    :
                    null
                }
                {st.showSurveyModal ?
                    this.renderSurveyModal()
                    :
                    null
                }
                {st.showMarketingModal ?
                    this.renderMarketingModal()
                    :
                    null
                }
                {st.showSurveySuccessModal ?
                    this.renderSurveySuccessModal()
                    :
                    null
                }
                {st.userCredits <= 0 && !pr.screen.mobile ?
                    this.renderMoreCreditsModal()
                    :
                    null
                }
                {/* {st.renderMoreCreditsModal ?
                    this.renderMoreCreditsModal(st.renderMoreCreditsModal)
                    :
                    null
                } */}
                {st.showThanksForFeedbackModal ?
                    this.renderThanksForFeedbackModal()
                    :
                    null
                }
                {st.showCustomAiSelfieModal ?
                    this.renderCustomAiSelfieModal()
                    :
                    null
                }
                {st.showSendToArtistModal ?
                    this.renderSendToArtistModal()
                    :
                    null
                }
                {st.showTurnOnNotificationsModal && !pr.screen.mobile ?
                    this.renderTurnOnNotificationsModal()
                    :
                    null
                }

                {false && st.showNoFollowsModal ?
                    this.renderNoFollowsModal()
                    :
                    null
                }

                {isConversations && isBalanceZero && st.showFreeCreditsModal && pr.userLoaded ?
                    this.renderFreeCreditsModal()
                    :
                    null
                }

                {/* {st.showSuperSendInfoModal ?
                    <Modal
                        screen={pr.screen}
                        className="showSuperSendInfoModal"
                        onRequestClose={ () => { this.setState({showSuperSendInfoModal: false}); } }
                    >
                        <Div style={{maxWidth: 343}}>
                            <Image
                                className="superChatImageFromProd"
                                style={{width: 128}}
                                src="https://dhwk6gixvyfl3.cloudfront.net/upload_2671c3fca8cbe9e9a3308c1d3c6bd221.png"
                            />
                            <Div className="superChatInfoTitle" style={{fontSize: 23, fontWeight: 400}}>
                                Get your message seen and celebrate {this.getArtistName()}!
                            </Div>
                            <br />
                            <Div className="superChatInfoDescription" style={{fontSize: 17, fontWeight: 300}}>
                                You can send a personalized message to {this.getArtistName()} along with your SuperSend, letting him know how much you appreciate his content.
                            </Div>
                            <br />
                            <Div className="superChatInfoDescription" style={{fontSize: 17, fontWeight: 300}}>
                                Grab the creator's attention and making it more likely for them to respond and engage with you. Try SuperSend today and start celebrating your favorite creators in a whole new way!
                            </Div>
                        </Div>
                    </Modal>
                    :
                    null
                } */}
            </Div>
        );
    }

    styles = {
        aiChatboxComponent: {
            display: "flex",
            backgroundColor: Colors.indigo,
            marginTop: 72,
            width: "100%",
        },
        aiChatboxComponent_md: {
            border: "none",
        },
        aiChatboxContainer: {
            color: "white",
            backgroundColor: "indigoLight",
            overflow: "hidden",
            width: "100%",
        },
        aiChatboxSidePanel: {
            maxWidth: 427,
            width: "100%",
            borderRight: Colors.aiBorder,
            backgroundColor: Colors.indigo,
            color: "white",
            fontWeight: 300,
        },
        aiChatboxSidePanel_md: {
            position: "fixed",
            maxWidth: "auto",
            top: 72,
            left: 0,
            zIndex: 3,
            borderRight: "none",
        },
        aiChatboxBackButton: {
            position: "fixed",
            zIndex: 1000,
            left: 0,
            top: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            paddingTop: 16,
            paddingLeft: 10,
            paddingRight: 20,
        },
        aiScrollContainer: {
            fontSize: 14,
            overflowY: "scroll",
            padding: "16px 16px 0 16px",
            backgroundColor: Colors.indigo,
        },
        aiScrollContainer_md: {
            position: "fixed",
            width: "100%",
            padding: "4px 16px 0",
            // marginTop: 102,
            paddingTop: 32,
        },
        aiChatHeader: {
            display: "flex",
            padding: "28px 16px",
            gap: 8,
            width: "100%",
            borderBottom: Colors.aiBorder,
            borderTop: Colors.aiBorder,
        },
        aiChatHeader_md: {
            padding: "0 16px 16px 16px",
            flexDirection: "column",
        },
        artistAvatar: {
            width: 32,
            height: 32,
            borderRadius: 200,
            backgroundColor: "white",
            marginRight: 8,
        },
        artistName: {
            fontSize: 17,
            fontWeight: 300,
        },
        messageContainer: {
            color: "white",
            marginBottom: 20,
            wordWrap: "break-word",
        },
        customerInfoContainer: {
            display: "flex",
            marginTop: 5,
        },
        customerIcon: {
            overflow: "hidden",
            backgroundSize: "cover",
            flexShrink: 0,
            width: 32,
            height: 32,
            backgroundColor: Colors.purpleLight,
            marginRight: 10,
            borderRadius: 100,
        },
        customerName: {
            marginRight: "auto",
            fontSize: 12,
            fontWeight: 500,
        },
        messageDate: {
            color: "white",
            fontSize: 10,
            whiteSpace: "nowrap"
        },
        messageStyle: {
            padding: "8px 16px",
            fontSize: 16,
            color: "white",
            borderRadius: "16px 16px 0px 16px",
            fontWeight: 300,
            background: Colors.blueGradient,
            width: "60%",
            marginLeft: "auto",
            maxWidth: 450,
            width: "fit-content",
            marginBottom: 16,
        },
        messageStyle_md: {
            maxWidth: 212,
        },
        aiInputContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            paddingRight: 10,
            paddingLeft: 10,
            backgroundColor: Colors.indigo,
            height: 72,
            position: "fixed",
            width: "calc(100% - 427px)",
            bottom: 0,
            zIndex: 1,
            borderTopLeftRadius: 37,
            borderTopRightRadius: 37,
            borderTop: Colors.aiBorder,
        },
        aiInputContainer_md: {
            width: "100%",
        },
        inputAndSendIconContainer: {
            display: "flex",
            width: "100%",
            alignItems: "center",
            fontSize: 12,
        },
        chatboxInputBelow: {
            marginBottom: 0,
            fontSize: 12,
            paddingTop: 18,
            paddingBottom: 18,
            paddingRight: 44,
            paddingLeft: 24,
            backgroundColor: Colors.indigo,
            border: "none",
            color: "white",
        },
        // chatboxInputBelow_md: {
        //     marginBottom: 0,
        //     marginRight: 14,
        //     fontSize: 12,
        //     padding: 10,
        // },
        chatboxInputAbove: {
            marginBottom: 0,
            marginRight: 14,
            fontSize: 12,
            padding: 10,
            backgroundColor: Colors.indigoLighter,
            border: "none",
            color: "white",
        },
        emojiRow: {
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            fontSize: 24,
            paddingBottom: 10,
            maxWidth: 342,
            alignSelf: "baseline",
        },
        emoji: {
            padding: "0 8px",
        },
        chatTab: {
            padding: 3,
            borderRadius: 28,
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            userSelect: "none",
        },
        fanToFanChatContainer: {
            cursor: "pointer",
            padding: 4,
            borderRadius: 28,
            width: "100%",
            background: Colors.purple,
            display: "flex",
            flexDirection: "row",
            // maxWidth: 342,
        },
        fanToFanChatIconContainer: {
            display: "flex",
            width: "100%",
            marginTop: 10,
        },
        qestionMarkStyle: {
            display: "flex",
            marginLeft: 8,
            alignSelf: "center",
        },
        chatboxAndSendContainer: {
            position: "relative",
            width: "100%",
        },
        sendIconStyle: {
            position: "absolute",
            top: 14,
            right: -10,
            width: 48,
        },
        lockIconStyle: {
            width: 9,
        },

        messageSuperStyle: {
            background: "rgba(248, 124, 85, 0.3)",
            borderRadius: 22,
            padding: 13,
        },
        superSendImageUsernameContainer: {
            display: "flex",
            alignItems: "center",
        },

        infoIcon: {
            width: 17,
            marginLeft: 8,
        },

        chatWithArtistNameContainer: {
            marginRight: 6,
            maxWidth: 130,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        superChatCost: {
            fontSize: 18,
            fontWeight: 700,
            background: "linear-gradient(180deg, #F4A868 0%, #F87B55 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
            webkitTextStroke: "1px white",
        },
        audioChipContainer: {
            // width: "fit-content",
            marginBottom: 16,
            position: "relative",
        },
        bdayTitle: {
            color: Colors.lemon,
            textAlign: "left",
            marginBottom: 8,
        },
        bdaySubTitle: {
            marginBottom: 16,
            color: "rgba(255, 255, 255, 0.5)",
            fontWeight: 400,
        },

        agreeToTermsContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: 18,
            color: Colors.lemon,
            fontWeight: 300,
        },
        readOnlyInputStyle: {
            backgroundColor: "#333",
        },
        audioMuteButton: {
            marginLeft: 10,
            flexShrink: 0,
            alignSelf: "center",
        },
        audioMuteButton_md: {
            // position: "absolute",
            // left: 0,
            // top: 67,
        },
        creditContainer: {
            backgroundColor: Colors.indigoLight,
            padding: 8,
            borderRadius: 10,
            width: "fit-content",
            display: "flex",
            alignItems: "center",
        },
        creditDot: {
            borderRadius: 100,
            border: "1px solid rgba(255, 255, 255, 0.30)",
            background: "#E8B365",
            marginRight: 6,
            width: 10,
            height: 10,
            boxShadow: "0px 2px 7px 0px rgba(236, 219, 161, 0.44)",
        },
        creatorFollowingRow: {
            display: "flex",
            alignItems: "center",
            padding: 16,
            borderBottom: "1px solid #282231",
            minHeight: 90,
        },
        topicResetLine: {
            width: "100%",
            height: 1,
            color: "white",
            opacity: 0.5,
        },
        topicResetText: {
            color: "white",
            opacity: 0.5,
            fontSize: 14,
            fontWeight: 400,
            padding: 8,
        },
        topicResetContainer: {
            display: "flex",
            alignItems: "center",
        },
        selectedCreatorStyle: {
            backgroundColor: Colors.indigoLight,
        },
        creatorFollowingList: {
            overflowY: "scroll",
        },
        creatorFollowingList_md: {
        },
        findCreatorsButton: {
            padding: 20,
            display: "flex",
            justifyContent: "center",
            borderTop: "1px solid rgb(40, 34, 49)",
        },

        getPicIconContainer: {
            display: "flex",
            gap: 12,
            width: "100%",
            justifyContent: "end",
        },
        getPicIconContainer_md: {
            justifyContent: "center",
        },
        getPicParagraphText: {
            display: "flex",
            flexDirection: "column",
            gap: 16,
            alignItems: "center",
            marginTop: 16,
            fontSize: 12,
            textAlign: "center",
            fontWeight: 300,
            maxWidth: 287,
            color: "#B1A7C2",
        },
        likeDislikeContainer: {
            marginTop: "auto",
            marginLeft: "auto",
            marginBottom: 5,
            marginRight: 5,
            gap: 5,
            display: "flex",
        },
        thumbsIconContainer: {
            padding: 10,
            borderRadius: 8,
            backgroundColor: "rgba(39,29,55,0.6)",
        },
        aiImagesContainer: {
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
        },
        aiImageStyle: {
            width: 280,
            aspectRatio: "1",
            borderRadius: 16,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            flexShrink: 0,
        },
        aiImageStyle_md: {
            maxWidth: 212,
        },
        imageFeedbackTypeContainer: {
            display: "flex",
            width: "100%",
            alignItems: "flex-start",
        },
        imageFeedbackMenuButton: {
            padding: 10,
            backgroundColor: "rgb(15, 4, 32, 0.6)",
            borderRadius: 12,
            marginTop: 4,
            marginRight: 4,
            fontSize: 13,
        },
        imageFeedbackMenuContainer: {
            borderRadius: 8,
            backgroundColor: Colors.indigoLighter,
            zIndex: 1000,
            width: "100%",
            maxWidth: 280,
        },
        imageFeedbackTypeRow: {
            padding: 10,
            fontWeight: 400,
            borderBottom: "1px solid rgba(255,255,255,0.3)",
        },
        creditsText: {
            fontWeight: 300,
            color: "#B1A7C2",
            fontSize: 14,
            marginTop: 20,
            marginBottom: 16,
        },
        modalExampleImagesContainer: {
            display: "flex",
            gap: 16,
            marginTop: 4,
            marginBottom: 8,
        },
        photoRequestContainer: {
            background: Colors.blueGradient,
            padding: "10px 10px",
            borderRadius: "16px 16px 0px 16px",
            width: "fit-content",
            minWidth: 200,
            maxWidth: 280,
            marginLeft: "auto",
        },
        modalExampleImageContainer: {
            marginBottom: 10,
            border: "5px solid white",
            borderRadius: 14,
            width: 190,
            height: 190,
            zIndex: 2,
        },
        modalExampleImageContainer_md: {
            width: 150,
            height: 150,
        },
        welcomeStageStyle: {
            width: 16,
            height: 4,
            borderRadius: 100,
            backgroundColor: "white",
            opacity: 0.3,
        },
        numberOption: {
            paddingTop: 5,
            paddingBottom: 3,
            borderRadius: 8,
            border: "1px solid #B1A7C2",
            width: 60,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: Colors.indigoLighter,
            color: "#B1A7C2",
        },
        aiPictureOptions: {
            display: "flex",
            flexDirection: "column",
            gap: 8,
            alignItems: "center",
            marginBottom: 8,
            fontSize: 12,
            width: "100%",
            maxHeight: 400,
            overflowX: "scroll",
        },
        getPicOuterStyle: {
            display: "flex",
            gap: 5,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: Colors.indigoLight,
            padding: "12px 16px",
            borderRadius: 12,
            justifyContent: "center",
            alignItems: "center",
        },
        getPicInnerStyle: {
            width: 24,
            height: 24,
            borderRadius: 100,
        },

        instantImageContainer: {
            borderRadius: 200,
            backgroundColor: Colors.indigoLighter,
            marginTop: 16,
            display: "flex",
            padding: 8,
            paddingRight: 32,
            color: "white",
            alignItems: "center",
            width: "fit-content",
        },
        instantImageImage: {
            width: 50,
            height: 50,
            borderRadius: 200,
        },
        instantImageTextContainer: {
            display: "flex",
            flexDirection: "column",
            marginLeft: 8,
            fontWeight: 300,
        },
        instantImageTitle: {
            fontSize: 17,
            color: Colors.indigoLightest,
        },
        instantImageSquare: {
            backgroundColor: "#AD80EF",
            width: 13,
            height: 13,
            borderRadius: 2,
            marginRight: 5,
        },
        instantImageNewImage: {
            fontSize: 15,
        },
        lowerSnapContainer: {
            display: "flex",
            alignItems: "center",
            color: "#AD80EF",
        },
        pwaBanner: {
            position: "fixed",
            top: 172,
            zIndex: 1,
            background: "linear-gradient(180deg, #6321EF 0%, #8600B1 100%)",
            width: "100%",
            height: 46,
            fontWeight: 400,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 16px",
        },
        orangeBanner: {
            borderRadius: 88,
            background: "linear-gradient(180deg, #E8B365 0%, #E98D07 100%)",
            padding: "2px 10px",
        },
        togglePlatform: {
            padding: 4,
            borderRadius: 100,
            display: "flex",
            background: Colors.indigoLight,
        },
        pwaStepRow: {
            display: "flex",
            marginLeft: 24,
            marginRight: "auto",
            gap: 8,
            alignItems: "center",
            textAlign: "left",
        },
        pwaStepRowCenter: {
            marginLeft: "auto",
            marginRight: "auto",
        },
        autoScrollTesting: {
            position: "absolute",
            width: "100%",
            backgroundColor: "beige",
            top: 0,
            left: 0,
            zIndex: 1000,
            color: "black",
            fontWeight: 300,
        },
        artistAvatarSmall: {
            width: 20,
            height: 20,
            borderRadius: 200,
            backgroundColor: "black",
            marginRight: 4,
        },
        rowInfoContinerAi: {
            alignItems: "center",
            display: "flex",
            alignItems: "center",
            gap: 10,
            fontSize: 17,
        },
        artistNameHeaderContainer: {
            display: "flex",
            alignItems: "center",
        },
        artistNameHeaderContainer_md: {
            marginLeft: 28,
            position: "fixed",
            zIndex: 500,
            top: 22,
        },
        creatorPanelHeader: {
            padding: "24px 16px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid rgb(65, 56, 80)",
            borderTop: "1px solid rgb(65, 56, 80)",
        },
        showLeaderboardSidePanel: {
            position: "fixed",
            zIndex: 400,
            right: 0,
            maxWidth: 427,
            backgroundColor: Colors.indigo,
            padding: 16,
            paddingTop: 32,
            overflowY: "scroll",
            borderTop: Colors.aiBorder,
            borderLeft: Colors.aiBorder,
            width: "100%",
        },
        leaderboardHeaderPanel: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 32,
        },
    };
}
