import React from 'react';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/utils';
import intlTelInput from 'intl-tel-input';
import DeviceDetector from "device-detector-js";

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import Validate from '../modules/Validate';
import Errors from '../modules/Errors';
import Constant from '../modules/Constant';
import Analytics from '../modules/Analytics';
import Browser from '../modules/Browser';

import Button from './Button';
import Image from './Image';
import Modal from './Modal';
import Input from './Input';
import Checkbox from './Checkbox';
import Icon from './Icon';
import Span from './Span';
// NOTE: Not working. Using standard button until we can get this working.
//import GoogleCustomLoginButton from './GoogleCustomLoginButton';
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';

import Div from '../components/Div';

import errorIcon from "../assets/images/ticketflow/error icon.png";
import paperPlaneInApp from "../assets/images/paper-plane-in-app.png";

export default class WebappLoginRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            webappBody: props.webappBody ? props.webappBody : "phoneEmailPrompt", //phoneEmailPrompt, verifyCode

            fullNameText: "",
            firstNameText: "",
            lastNameText: "",
            phoneNumberText: "",
            editPhoneNumberText: "",
            emailText: "",
            monthText: "",
            dayText: "",
            yearText: "",
            passwordText: "",
            agreeToTerms: false,
            verificationCode: "",

            phoneEmailPromptIsValid: false,
            phoneEmailPromptErrors: [],

            isLogin: props.isLogin == undefined ? true : props.isLogin,

            resendCodePending: false,
            isEditPhoneNumber: false,

            loginResponse: null,
            inAppResponse: null,
            showCheckEmailPage: false,
            uiVersion: "240201",
            // Dev
            //googleLoginClientId: "454754480106-76pk3ujina636v46bb2p064pdm2jogvq.apps.googleusercontent.com",
            // Prod
            googleLoginClientId: "366725186618-ee22q0r09cu70dducfiop45m99ksvgdr.apps.googleusercontent.com",
        }

        if(Utils.getRequest("fillin") == 17) {
            const randNum = Utils.randInt();
            this.state["firstNameText"] = "Testuser";
            this.state["lastNameText"] = `Testuser_${randNum}`;
            this.state["phoneNumberText"] = `310555${randNum}`;
            this.state["editPhoneNumberText"] = `310555${randNum}`;
            this.state["emailText"] = `my${randNum}@aol.com`;
            this.state["monthText"] = "";
            this.state["dayText"] = "";
            this.state["yearText"] = "";
            this.state["passwordText"] = `MY${randNum}`;
            this.state["agreeToTerms"] = true;
        }

        this.phoneInputRef = React.createRef();
        this.phoneInputRefLogin = React.createRef();
        this.phoneInputRefEditNumber = React.createRef();
    }

    componentDidMount() {
        //Api.postGetRegisterToken();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {}

    componentWillUnmount() {
        // this.phoneInputRef.destroy();
        // this.phoneInputRefLogin.destroy();
    }

    loginToggle() {
        const pr = this.props;
        const st = this.state;

        const isLogin = !this.state.isLogin;
        this.setState({
            isLogin: isLogin,
        }, () => {
            if(pr.onLoginToggle) {
                pr.onLoginToggle(isLogin);
            }
        });
    }

    firstNameChange(e) {
        this.setState({firstNameText: e.target.value});
    }

    lastNameChange(e) {
        this.setState({lastNameText: e.target.value});
    }

    fullNameChange(e) {
        this.setState({fullNameText: e.target.value});
    }

    phoneNumberChange(e) {
        this.setState({phoneNumberText: e.target.value});
    }

    phoneNumberEditChange(e) {
        this.setState({editPhoneNumberText: e.target.value});
    }

    emailChange(e) {
        this.setState({emailText: e.target.value});
    }

    monthChange(e) {
        let month = e.target.value;
        if(month.length > 2) {
            month = month.slice(0, 2);
        }
        if(!isNaN(month)) {
            this.setState({monthText: month});
        }
    }

    encryptRegisterCookie() {
        let keysB = Utils.getCookie('kb');
        let keysS = Utils.getCookie('ks');
        let keysA = Utils.symmetricalEncode(keysB, keysS);
        console.log("CRYPT", keysB, keysS, keysA)
        return keysA;
    }

    dayChange(e) {
        let day = e.target.value;
        if(day.length > 2) {
            day = day.slice(0, 2);
        }
        if(!isNaN(day)) {
            this.setState({dayText: day});
        }
    }

    yearChange(e) {
        let year = e.target.value;
        if(year.length > 4) {
            year = year.slice(0, 4);
        }
        if(!isNaN(year)) {
            this.setState({yearText: year});
        }
    }

    passwordChange(e) {
        this.setState({passwordText: e.target.value});
    }

    handleKeyDown(e) {
        const st = this.state;

        if(!(st.phoneNumberText.length >= 3 || st.emailText.length >= 3)) {
            return;
        }

        if (e.key === 'Enter') {
            this.ticketLoginSetPhone();
        }
    }

    toggleAgreeToTerms() {
        this.setState({agreeToTerms: !this.state.agreeToTerms});
    }

    showTermsOfServiceModal() {
        if(this.props.showTermsOfServiceModal) {
            this.props.showTermsOfServiceModal();
        } else {
            Utils.newTab("/tos");
            ll._("MISSING showTermsOfServiceModal binding", "red");
        }
    }

    smsVerificationEnabled() {
        const pr = this.props;

        return Utils.get(pr.user, "smsVerificationEnabled", 0);
    }

    verificationCodeNewChange(e) {
        this.setState({verificationCode: e.target.value});
    }

    validateInApp() {
        const st = this.state;

        let phoneEmailPromptErrors = [];
        let phoneEmailPromptIsValid = false;

        if(st.emailText.length > 0) {
            const validateEmail = Validate.email(st.emailText);

            phoneEmailPromptErrors = [
                ...validateEmail.errors,
            ];
        }

        if(phoneEmailPromptErrors.length === 0) {
            phoneEmailPromptIsValid = true;
        }

        this.setState({
            phoneEmailPromptIsValid: phoneEmailPromptIsValid,
            phoneEmailPromptErrors: phoneEmailPromptErrors,
        });

        return phoneEmailPromptIsValid;
    }

    validateLogin() {
        const st = this.state;

        let phoneEmailPromptErrors = [];
        let phoneEmailPromptIsValid = false;

        if(st.phoneNumberText.length > 0) {
            const validatePhone = Validate.phone(st.phoneNumberText);

            phoneEmailPromptErrors = [
                ...validatePhone.errors,
            ];
        } else if(st.emailText.length > 0) {
            const validateEmail = Validate.email(st.emailText);
            const validatePassword = Validate.minLength(st.passwordText);

            phoneEmailPromptErrors = [
                ...validateEmail.errors,
                ...validatePassword.errors,
            ];
        }

        if(phoneEmailPromptErrors.length === 0) {
            phoneEmailPromptIsValid = true;
        }

        this.setState({
            phoneEmailPromptIsValid: phoneEmailPromptIsValid,
            phoneEmailPromptErrors: phoneEmailPromptErrors,
        });

        return phoneEmailPromptIsValid;
    }

    validatePhoneEmailPrompt() {
        const st = this.state;

        let phoneEmailPromptErrors = [];
        let phoneEmailPromptIsValid = false;

        const validateFirstName = Validate.minLength(st.firstNameText);
        const validateLastName = Validate.minLength(st.lastNameText);
        const validatePhone = Validate.phone(st.phoneNumberText);
        const validateEmail = Validate.email(st.emailText);
        const validatePassword = Validate.minLength(st.passwordText);

        // const validateMonth = Validate.month(st.monthText);
        // const validateDay = Validate.day(st.dayText);
        // const validateYear = Validate.year(st.yearText);


        phoneEmailPromptErrors = [
            ...validateFirstName.errors,
            ...validateLastName.errors,
            ...validatePhone.errors,
            ...validateEmail.errors,
            ...validatePassword.errors,
            // ...validateMonth.errors,
            // ...validateDay.errors,
            // ...validateYear.errors,
        ];

        if(phoneEmailPromptErrors.length === 0) {
            phoneEmailPromptIsValid = true;
        }

        this.setState({
            phoneEmailPromptIsValid: phoneEmailPromptIsValid,
            phoneEmailPromptErrors: phoneEmailPromptErrors,
        });

        return phoneEmailPromptIsValid;
    }

    handleInputChange(event) {
        this.setState({ phoneNumber: event.target.value });
    }

    getIntlPhoneNumber() {
        const st = this.state;

        let formattedPhoneNumber = "";

        if(st.isEditPhoneNumber) {
            formattedPhoneNumber = this.telInputEditNumber.getNumber();
        } else {
            if(st.isLogin) {
                formattedPhoneNumber = this.telInputLogin.getNumber();
            } else {
                formattedPhoneNumber = this.telInput.getNumber();
            }
        }

        const phoneDialCode = this.getPhoneDialCode();
        const countryCode = this.getCountryCode();

        this.setState({
            phoneDialCode: phoneDialCode,
            countryCode: countryCode,
        });

        if(!formattedPhoneNumber.includes("+")) {
            formattedPhoneNumber = `+${phoneDialCode}${formattedPhoneNumber}`
        }

        return formattedPhoneNumber;
    }

    getPhoneDialCode() {
        // const phoneDialCode = $(".iti__active").attr("data-dial-code");
        const phoneDialCodeTitle = $(".iti__selected-flag").attr("title");

        const phoneDialCode = phoneDialCodeTitle ? phoneDialCodeTitle.split("+")[1] : "";
        return phoneDialCode;
    }

    getCountryCode() {
        // const countryCode = $(".iti__active").attr("data-country-code");
        const countryCode = $(".iti__flag").attr("class").replaceAll("iti__", "").split(" ")[1];
        return countryCode;
    }

    confirmVerificationCode() {
        const st = this.state;

        if(st.isLogin) {
            this.confirmVerificationCodeLogin()
        } else {
            this.confirmVerificationCodeSignUp()
        }
    }

    triggerStripeIntent(sourceString) {
        const pr = this.props;
        // necessary to render StripePayment within Post.js right w/in paypal modal
        if (pr.triggerStripeIntent) {
            pr.triggerStripeIntent()
        }
    }

    confirmVerificationCodeSignUp() {
        const pr = this.props;
        const st = this.state;

        this.setState({
            phoneEmailPromptErrors: [],
        });

        const verifyCodeData = { ...{
            code: st.verificationCode,
            customerId: st.newCustomerId,
        }, ...pr.verifyCodeData};

        Api.postVerifyCode(verifyCodeData, (response) => {
            if(Utils.get(response, "success") == 1) {
                Api.apiLoadProfile((profileResponse) => {
                    pr.setUser(profileResponse);
                    pr.onConfirmVerificationCodeSignUp(response);
                    this.triggerStripeIntent('phone_ver_signup')
                });
            } else {
                const errors = Utils.get(response, "errors");
                this.setState({
                    phoneEmailPromptErrors: errors,
                }, () => {
                    pr.onConfirmVerificationCodeSignUp(response);
                });
            }
        });
    }

    confirmVerificationCodeLogin() {
        const pr = this.props;
        const st = this.state;

        this.setState({
            phoneEmailPromptErrors: [],
        });

        const verifyCodeData = { ...{
            code: st.verificationCode,
            primary_phone1: st.phoneNumberText,
        }, ...pr.verifyCodeData};

        Api.postVerifyCode(verifyCodeData, (response) => {

            if(Utils.get(response, "success") == 1) {
                Api.apiLoadProfile((profileResponse) => {
                    pr.setUser(profileResponse);
                    pr.onConfirmVerificationCodeLogin(response);
                    this.triggerStripeIntent('phone_ver_login')
                });
            } else {
                const errors = Utils.get(response, "errors");
                this.setState({
                    phoneEmailPromptErrors: errors,
                }, () => {
                    pr.onConfirmVerificationCodeLogin(response);
                });
            }
        });
    }

    resendVerificationCode() {
        const pr = this.props;
        const st = this.state;

        this.setResendCodePending();

        const resendCodeData = {
            // customerId: st.newCustomerId,
            primary_phone1: st.phoneNumberText,
            //keya: this.encryptRegisterCookie(),
        };

        Api.resendVerifyCode(resendCodeData, (res) => {
            // alert(`Verify code has been resent to ${Utils.formatPhoneNumber(Utils.get(pr.user, "primary_phone1", ""))}!  If you didn't receive the message, email support@afterparty.com`);
            alert(`Verify code has been resent!  If you didn't receive the message, email support@afterparty.com`);
            console.log(res);
        });
    }

    setResendCodePending() {
        this.setState({
            resendCodePending: true,
        });

        setTimeout(() => {
            this.setState({
                resendCodePending: false,
            });
        }, 7000);
    }

    ticketLoginSetPhone() {
        const st = this.state;

        if(st.phoneNumberText.length && this.smsVerificationEnabled()) {
            this.setState({
                phoneEmailPromptErrors: [],
                phoneNumberText: this.getIntlPhoneNumber(),
            }, () => {
                this.ticketLogin();
            });
        } else {
            this.ticketLogin();
        }
    }

    ticketInApp() {
        const pr = this.props;
        const st = this.state;

        this.setState({
            phoneEmailPromptErrors: [],
        });
        const phoneEmailPromptIsValid = this.validateInApp();

        if(!phoneEmailPromptIsValid) {
            return;
        }

        let inAppFormData = { ...{
            email: st.emailText,
            next_url: Utils.getRequest("next") ?? "home",
            format: "json",
        }, ...pr.loginFormData}; // TODO: not sure if i need this line

        Api.postRegisterInApp(inAppFormData, (inAppResponse) => {
            this.setState({inAppResponse: inAppResponse});

            if(Utils.get(inAppResponse, "success")) {
                //console.log("AMP", inAppResponse);
                this.triggerAmplitudeLogin(Utils.get(inAppResponse, "data.id"));
                // Api.apiLoadProfile((profileResponse) => {
                //     pr.setUser(profileResponse);
                //     pr.onLoginEmail(inAppResponse);
                //     this.triggerStripeIntent('user_and_email')
                // });
                this.setState({
                    showCheckEmailPage: true,
                });
            } else {
               this.triggerAmplitudeLoginFail();
               this.setState({phoneEmailPromptErrors: Utils.get(inAppResponse, "errors", "")}, () => {
                   pr.onLoginEmail(inAppResponse);
               });
            }
        });
    }

    ticketLogin() {
        const pr = this.props;
        const st = this.state;

        this.setState({
            phoneEmailPromptErrors: [],
        });
        const phoneEmailPromptIsValid = this.validateLogin();

        if(!phoneEmailPromptIsValid) {
            return;
        }

        if(st.phoneNumberText.length && this.smsVerificationEnabled()) {
            this.setResendCodePending()

            this.setState({
                phoneNumberText: this.getIntlPhoneNumber(),
            });

            const resendCodeData = {
                primary_phone1: st.phoneNumberText,
                //keya: this.encryptRegisterCookie(),
            };

            Api.getPhoneValidation(st.phoneNumberText, (phoneResponse) => {
                if(Utils.get(phoneResponse, "errors", []).length) {
                    this.setState({
                        phoneEmailPromptErrors: Errors.getErrorMessages(phoneResponse.errors, true, st.phoneNumberText),
                    });
                } else {
                    Api.resendVerifyCode(resendCodeData, (response) => {
                        if(Utils.get(response, "success")) {
                            this.setState({
                                webappBody: "verifyCode",
                            }, () => {
                                pr.onLoginPhone(response);
                            });
                        } else {
                            this.setState({phoneEmailPromptErrors: Utils.get(response, "errors", "")}, () => {
                                pr.onLoginPhone(response);
                            });
                        }
                    });
                }
            });
        } else {
            let loginFormData = { ...{
                email: st.emailText,
                password: st.passwordText,
                format: "json",
            }, ...pr.loginFormData};

            Api.postLogin(loginFormData, (loginResponse) => {
                this.setState({loginResponse: loginResponse});

                if(Utils.get(loginResponse, "success")) {
                    //console.log("AMP", loginResponse);
                    Analytics.triggerAmplitudeLogin(Utils.get(loginResponse, "data.id"));
                    Api.apiLoadProfile((profileResponse) => {
                        pr.setUser(profileResponse);
                        pr.onLoginEmail(loginResponse);
                        this.triggerStripeIntent('user_and_email')
                    });
                } else {
                   Analytics.triggerAmplitudeLoginFail();
                   this.setState({phoneEmailPromptErrors: Utils.get(loginResponse, "errors", "")}, () => {
                       pr.onLoginEmail(loginResponse);
                   });
                }
            });
        }
    }

    ticketSignUpSetPhone() {
        if(this.state.uiVersion != "240201") {
            this.setState({
                phoneEmailPromptErrors: [],
                phoneNumberText: this.getIntlPhoneNumber(),
                phoneDialCode: this.getPhoneDialCode(),
                countryCode: this.getCountryCode(),
            }, () => {
                this.ticketSignUp();
            });
        } else {
            this.setState({
                phoneEmailPromptErrors: [],
                phoneNumberText: "",
                phoneDialCode: "",
                countryCode: "",
            }, () => {
                this.ticketSignUp();
            });
        }
    }

    ticketSignUp() {
        const pr = this.props;
        const st = this.state;

        if(st.uiVersion != "240201") {
            const phoneEmailPromptIsValid = this.validatePhoneEmailPrompt();

            if(!phoneEmailPromptIsValid) {
                return;
            }

            this.setResendCodePending();
        }

        // NOTE: duplicate users with phone number and email?
        // NOTE: "is this you?" code // in guardian page
        let signUpFormData = { ...{
            full_name: st.fullNameText,
            first_name: st.firstNameText,
            last_name: st.lastNameText,
            // birthdate: Utils.formatBirthdate(st.dayText, st.monthText, st.yearText),
            password: st.passwordText,
            primary_phone1: st.phoneNumberText,
            // phone_dial_code: st.phoneDialCode,
            phone_dial_code: this.getPhoneDialCode(),
            country_code: st.countryCode,
            email: st.emailText,
            format: "json",
            uiVersion: st.uiVersion,
        }, ...pr.signUpFormData};
        if(st.uiVersion != "240201") {
            signUpFormData['full_name'] = `${st.firstNameText} ${st.lastNameText}`;
        }
        if(Utils.getRequest('verified_hotkey')) {
            signUpFormData['verifiedHotkey'] = Utils.getRequest('verified_hotkey');
        }


        if(st.uiVersion != "240201") {
            Api.getPhoneValidation(this.smsVerificationEnabled() ? st.phoneNumberText : "4153324999", (phoneResponse) => {
                if(Utils.get(phoneResponse, "errors").length) {
                    this.setState({
                        phoneEmailPromptErrors: Errors.getErrorMessages(phoneResponse.errors, true, st.phoneNumberText),
                        resendCodePending: false,
                    });
                } else {
                    let phoneFromTwilio = phoneResponse.data;
                    if(this.smsVerificationEnabled()) {
                        signUpFormData.primary_phone1 = phoneFromTwilio;
                    }

                    Api.postRegisterWebAppRegister(signUpFormData, (response) => {
                        if(response == null) {
                            return;
                        }

                        if(Utils.get(response, "success")) {
                            Analytics.triggerAmplitudeRegister(Utils.get(response, "data.id"));
                            if(this.smsVerificationEnabled()) {
                                const nextBody = "verifyCode";

                                this.setState({
                                    webappBody: nextBody,
                                    newCustomerId: Utils.get(response, "data.id"),
                                    phoneEmailPromptErrors: [],
                                }, () => {
                                    pr.onSignUpPhone(response);
                                });
                            } else {
                                this.setState({
                                    newCustomerId: Utils.get(response, "data.id"),
                                });
                                Api.apiLoadProfile((profileResponse) => {
                                    pr.setUser(profileResponse);
                                    pr.onConfirmVerificationCodeSignUp(response);
                                    this.triggerStripeIntent('user_and_email');

                                    // setTimeout(() => {
                                    //     window.location.reload();
                                    // }, 500);
                                });
                            }
                        } else {
                            Analytics.triggerAmplitudeRegisterFail();
                            const errors = Utils.get(response, "errors", []);
                            let accountAlreadyExists = false;
                            for (var i = 0; i < errors.length; i++) {
                                if(errors[i].id == 372934 || errors[i].id == 37293413) {
                                    accountAlreadyExists = true;
                                }
                            }
                            this.setState({
                                phoneEmailPromptErrors: Utils.get(response, "errors", ""),
                                isLogin: accountAlreadyExists,
                            });
                        }
                    });
                }
            });
        } else {
            Api.postRegisterWebAppRegister(signUpFormData, (response) => {
                if(response == null) {
                    return;
                }

                if(Utils.get(response, "success")) {
                    Analytics.triggerAmplitudeRegister(Utils.get(response, "data.id"));
                        this.setState({
                            newCustomerId: Utils.get(response, "data.id"),
                        });
                        Api.apiLoadProfile((profileResponse) => {
                            pr.setUser(profileResponse);
                            pr.onConfirmVerificationCodeSignUp(response);
                            this.triggerStripeIntent('user_and_email');

                            // setTimeout(() => {
                            //     window.location.reload();
                            // }, 500);
                        });
                } else {
                    Analytics.triggerAmplitudeRegisterFail();
                    const errors = Utils.get(response, "errors", []);
                    let accountAlreadyExists = false;
                    for (var i = 0; i < errors.length; i++) {
                        if(errors[i].id == 372934 || errors[i].id == 37293413) {
                            accountAlreadyExists = true;
                        }
                    }
                    this.setState({
                        phoneEmailPromptErrors: Utils.get(response, "errors", ""),
                        isLogin: accountAlreadyExists,
                    });

                }
            })

        }

    }

    editPhoneNumberSetPhone() {
        this.setState({
            editPhoneNumberText: this.getIntlPhoneNumber(),
        }, () => {
            this.editPhoneNumber();
        });
    }

    editPhoneNumber() {
        const pr = this.props;
        const st = this.state;

        if(st.isEditPhoneNumber) {
            if(st.editPhoneNumberText != st.phoneNumberText) {
                if(st.editPhoneNumberText.length < 4) {
                    this.setState({
                        phoneEmailPromptErrors: [{id:9990, message:"Phone number must be at least 4 numbers."}],
                    });
                    return;
                }
                this.setState({
                    phoneEmailPromptErrors: [],
                });

                const updatePhoneInfo = {primary_phone1: st.editPhoneNumberText, old_phone: st.phoneNumberText};

                Api.postVerifyCode(updatePhoneInfo, (res) => {
                    if(Utils.get(res, "success")) {
                        this.setResendCodePending();
                        pr.setUser(res);
                        this.setState({
                            isEditPhoneNumber: false,
                            phoneNumberText: st.editPhoneNumberText,
                            editPhoneNumberText: "",
                        });
                    } else {
                        this.setState({
                            phoneEmailPromptErrors: Utils.get(res, "errors"),
                        });
                    }
                });
            } else {
                this.setState({
                    isEditPhoneNumber: false,
                });
            }
        } else {
            this.setState({
                isEditPhoneNumber: true,
                editPhoneNumberText: st.phoneNumberText,
            });
        }
    }

    renderPhoneEmailErrors() {
        const st = this.state;
        const sty = this.styles;

        if(!st.phoneEmailPromptErrors) {
            return null;
        }

        return (
            <Div
                className="errorMessageRowContainer"
                style={sty.errorMessageRowContainer}
            >
                {st.phoneEmailPromptErrors.map((error, errorIndex) => {
                    return (
                        <Div
                            key={`errorMessageRow-${errorIndex}`}
                            className="errorMessageRow"
                            style={sty.errorMessageRow}
                        >
                            <Image
                                src={errorIcon}
                                style={{width: 16, marginRight: 8}}
                            />
                            {error.message}
                        </Div>
                    )
                })}
            </Div>
        )
    }

    renderPhoneNumber() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div
                className="phoneNumberTextStyle"
                style={sty.phoneNumberTextStyle}
            >
                {Utils.formatPhoneNumber(st.phoneNumberText)}
            </Div>
        )
    }

    renderVerifyCode() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        // const tempVerificationCode = `${st.verificationCode1}${st.verificationCode2}${st.verificationCode3}${st.verificationCode4}`

        return (
            <Div className="verifyCodeBody" style={{padding: 25}}>
                <Div className="pleaseEnterText" style={{marginBottom: 10, fontSize: 16, color: Colors.gray}}>
                    Please enter Verification code sent to
                </Div>

                {st.isLogin ?
                    this.renderPhoneNumber()
                    :
                    <Div
                        className="editPhoneNumberContainer"
                        style={sty.editPhoneNumberContainer}
                    >
                        {st.isEditPhoneNumber ?
                            <div key="uniqueKey3">
                                <input
                                    className="editPhoneNumberLabel internationalPhoneInput"
                                    ref={this.phoneInputRefEditNumberFunc}
                                    type="tel"
                                    value={st.editPhoneNumberText}
                                    onChange={this.phoneNumberEditChange.bind(this)}
                                    placeholder="Phone Number"
                                />
                            </div>
                            :
                            this.renderPhoneNumber()
                        }

                        <Button
                            className="editPhoneNumberButton"
                            style={{minWidth: 60, marginLeft: 20, marginBottom: 10}}
                            onClick={this.editPhoneNumberSetPhone.bind(this)}
                            size="small"
                        >
                            {st.isEditPhoneNumber ? "Save" : "Edit"}
                        </Button>
                    </Div>
                }

                <Input
                    inputType="placeholderLabel"
                    value={st.verificationCode}
                    style={sty.inputStyle}
                    placeholder="Verification Code"
                    importance="Required"
                    onChange={this.verificationCodeNewChange.bind(this)}
                />

                <Button
                    onClick={this.confirmVerificationCode.bind(this)}
                    color="redGradient"
                    style={{width: "100%", marginTop: 30, marginBottom: 25}}
                    disabled={st.verificationCode.length < 6}
                >
                    Continue
                </Button>

                <Button
                    type="text"
                    onClick={this.resendVerificationCode.bind(this)}
                    style={{fontWeight: 300, textAlign: "center"}}
                    disabled={st.resendCodePending}
                >
                    Resend Code
                </Button>
                {this.renderPhoneEmailErrors()}

                {/* <Input
                    id="codeInput1"
                    value={st.verificationCode1}
                    style={sty.inputCodeStyle}
                    placeholder=""
                    onChange={this.verificationCodeChange.bind(this, 1)}
                />

                <Input
                    id="codeInput2"
                    value={st.verificationCode2}
                    style={sty.inputCodeStyle}
                    placeholder=""
                    onChange={this.verificationCodeChange.bind(this, 2)}
                />

                <Input
                    id="codeInput3"
                    value={st.verificationCode3}
                    style={sty.inputCodeStyle}
                    placeholder=""
                    onChange={this.verificationCodeChange.bind(this, 3)}
                />

                <Input
                    id="codeInput4"
                    value={st.verificationCode4}
                    style={sty.inputCodeStyle}
                    placeholder=""
                    onChange={this.verificationCodeChange.bind(this, 4)}
                />

                {tempVerificationCode.length >= 4 ?
                    <Button
                        style={{...{width: "100%"}, ...sty.guardianButton}}
                        onClick={this.ticketBodyChange.bind(this, "verifyCode", st.type === 1 ? "paymentSuccess" : "ticketInfoAndQuantity")}
                        color="red"
                    >
                        Next
                    </Button>
                    :
                    null
                } */}
            </Div>
        )
    }

    getGoogleButtonWidth() {
        const pr = this.props;

        let googleButtonWidth = "100%";
        if(pr.screen.mobile) {
            googleButtonWidth = pr.screen.width - 40
            if(pr.isModalForGoogle) {
                googleButtonWidth = pr.screen.width - 40 - 20
            }
        } else {
            googleButtonWidth = pr.screen.width
        }

        return googleButtonWidth;
    }

    showGoogleOAuthProvider() {
        const pr = this.props;
        // TODO: need physical tablet to fully test
        // console.log("Utils.getDeviceType() >>>>", Utils.getDeviceType());
        //const deviceDetector = new DeviceDetector();
        //const device = deviceDetector.parse(navigator.userAgent);
        // console.log("device >>>>", device);
        // alert(Utils.getDeviceType());
        let showType = 'none';
        if(!pr.screen.mobile && !Browser.isInInstagramApp() || (Utils.getRequest('google') == 11 && pr.screen.mobile) ) {
            showType = 'popup';
        } else if(false) {
            showType = 'redirect';
        }
        return showType;
    }

    renderPhoneNumberMessage() {
        return (
            <Div
                className="phoneNumberNote"
                style={{fontSize: 14, marginBottom: 10, textAlign: "center"}}
            >
                Select your country's flag from the menu above
            </Div>
        );
    }

    renderOrSeparator() {
        const sty = this.styles;

        return (
            <Div style={sty.orSeparatorContainer}>
                <Div style={sty.whiteLineSeparator} />
                <Div style={{color: "#A0A0A0"}}>
                    OR
                </Div>
                <Div style={sty.whiteLineSeparator} />
            </Div>

        )
    }

    renderShowCheckEmailPage() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="showCheckEmailPage" style={sty.showCheckEmailPage}>
                <Image
                    src={paperPlaneInApp}
                    style={{width: 44, marginBottom: 24}}
                />
                <Div style={{fontSize: 28, fontWeight: 400, marginBottom: 8}}>
                    Check your email
                </Div>

                <Div style={{marginBottom: 24}}>
                    We've sent an email to {st.emailText} with a Login Link to login without a password
                </Div>

                <Button
                    className="resendEmailInApp"
                    onClick={this.ticketInApp.bind(this)}
                    type="outlined"
                    style={{width: "100%"}}
                >
                    Resend Email
                </Button>
            </Div>
        );
    }

    renderPhoneEmailPrompt() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const responseMessage2 = (response) => {
            console.log("GOOGLE1 LOGIN response: ", response);
            Api.postGoogleRegisterLogin(response, (o) => {
                console.log("registerlogin", o);
                this.setState({
                    newCustomerId: Utils.get(response, "data.id"), // TODO: This looks like a bug, but it seems to work, so leaving for now
                });
                var newCustomerId = Utils.get(o, "data");
                var isRegister = parseInt(Utils.get(o, "register"));
                if(isRegister) {
                    if(newCustomerId) {
                        Analytics.triggerAmplitudeRegister(newCustomerId);
                    } else {
                        Analytics.triggerAmplitudeRegisterFail();
                    }
                } else {
                    if(newCustomerId) {
                        Analytics.triggerAmplitudeLogin(newCustomerId);
                    } else {
                        Analytics.triggerAmplitudeLoginFail();
                    }
                }
                Api.apiLoadProfile((profileResponse) => {
                    this.props.setUser(profileResponse);
                    this.props.onConfirmVerificationCodeSignUp(response);
                    this.triggerStripeIntent('user_and_email');
                });
            });

        };
        const errorMessage2 = (error) => {
            console.log("GOOGLE2 LOGIN error",error);
        };

        const login = (
            <Div id="webappLogin" className="webappLogin">
                <Div className="loginTitle" style={{fontWeight: 400, fontSize: 28, textAlign: "left"}}>
                    Sign In
                </Div>

                <Div
                    className="googleAfterpartyOauthContainer"
                    style={sty.googleAfterpartyOauthContainer}
                >
                    { this.showGoogleOAuthProvider() == 'popup' ?
                        <GoogleOAuthProvider
                            className="googleLoginButtonCustom2"
                            clientId={this.state.googleLoginClientId}
                        >
                            <GoogleLogin
                                className="googleLoginButtonCustom"
                                onSuccess={responseMessage2}
                                onError={errorMessage2}
                                responseType="code"
                                flow='auth-code'
                                text="continue_with"
                                size="large"
                                shape="pill"
                                width={this.getGoogleButtonWidth()}
                            />
                            {/*

                            <GoogleCustomLoginButton
                                onSuccess={responseMessage}
                                onError={errorMessage}
                            />
                            */}
                        </GoogleOAuthProvider>
                        :
                        this.showGoogleOAuthProvider() == 'redirect' ?
                            <Div>
                                <form action="https://pdqueue.com/google-redirect-login" method="POST" className="googleLogin frmLogin">
                                    <input type="image" src="/images/logo/google_login_button.png" name="submit" />
                                </form>
                            </Div>
                            :
                            null
                    }

                </Div>

                <Div className="subheaderText" style={sty.subheaderText}>
                    Sign in with Email
                </Div>

                {this.smsVerificationEnabled() ?
                    // NOTE: needed to add the wrapper and unique key to deal with react ref
                    <div key="uniqueKey1">
                        <input
                            className="internationalPhoneInput"
                            ref={this.phoneInputRefLoginFunc}
                            type="tel"
                            value={st.phoneNumberText}
                            onChange={this.phoneNumberChange.bind(this)}
                            placeholder="Phone Number"
                        />
                        {this.renderPhoneNumberMessage()}
                    </div>
                    :
                    null
                }

                <Div>
                    {this.smsVerificationEnabled() ?
                        <Div className="orSeparator" style={sty.orSeparator}>
                            OR
                        </Div>
                        :
                        null
                    }

                    <Input
                        id="login.email"
                        inputType="placeholderLabel"
                        value={st.emailText}
                        style={sty.inputStyle}
                        placeholder="Email Address"
                        onChange={this.emailChange.bind(this)}
                        type="email"
                    />
                    <Input
                        id="login.password"
                        inputType="placeholderLabel"
                        value={st.passwordText}
                        style={sty.inputStyle}
                        placeholder="Password"
                        type="password"
                        onChange={this.passwordChange.bind(this)}
                        onKeyDown={this.handleKeyDown.bind(this)}
                    />
                </Div>

                <Button
                    className="continueLoginButton"
                    id="login.continue"
                    onClick={this.ticketLoginSetPhone.bind(this)}
                    color="redGradient"
                    style={{width: "100%", marginBottom: 16, color: "white"}}
                    disabled={!(st.phoneNumberText.length >= 3 || st.emailText.length >= 3)}
                >
                    Continue
                </Button>

                <Div className="forgotPasswordContainer" style={sty.forgotPasswordContainer}>
                    Forgot your password?&nbsp;&nbsp;<Button type="text" onClick={Utils.gotoRoute.bind(this, pr, "forgot")}>Reset it</Button>
                </Div>

                {this.renderPhoneEmailErrors()}

                {this.renderOrSeparator()}

                <Div className="toggleLoginButton" style={sty.toggleLoginButton}>
                    <Button
                        type="outlined"
                        onClick={this.loginToggle.bind(this)}
                        style={{width: "100%"}}
                    >
                        Don't have an account? Create one
                    </Button>
                </Div>
            </Div>
        );

        const loginInApp = (
            <Div id="webappLoginInApp" className="webappLoginInApp">
                <Div className="loginTitle" style={{fontWeight: 400, fontSize: 28, textAlign: "left", marginBottom: 16}}>
                    Login or Sign Up
                </Div>

                <Input
                    id="login.email"
                    inputType="placeholderLabel"
                    value={st.emailText}
                    style={sty.inputStyle}
                    placeholder="Email Address"
                    onChange={this.emailChange.bind(this)}
                    type="email"
                />
                <Button
                    className="continueLoginButton"
                    id="login.continue"
                    onClick={this.ticketInApp.bind(this)}
                    color="redGradient"
                    style={{width: "100%", marginBottom: 16, color: "white"}}
                    disabled={!(st.phoneNumberText.length >= 3 || st.emailText.length >= 3)}
                >
                    Continue
                </Button>
                <Div style={{textAlign: "center"}}>
                    We'll send you an email with a magic link.
                </Div>
            </Div>
        );

        const responseMessage = (response) => {
            console.log("GOOGLE3 LOGIN response: ", response);
            Api.postGoogleRegisterLogin(response, (o) => {
                console.log("registerlogin", o);
                this.setState({
                    newCustomerId: Utils.get(response, "data.id"),
                });
                Analytics.triggerAmplitudeLogin(Utils.get(o, "data"));
                Api.apiLoadProfile((profileResponse) => {
                    this.props.setUser(profileResponse);
                    this.props.onConfirmVerificationCodeSignUp(response);
                    this.triggerStripeIntent('user_and_email');
                });
            });

        };
        const errorMessage = (error) => {
            console.log("GOOGLE4 LOGIN error",error);
        };
        const uiVersion = st.uiVersion;

        const signUp = (
            <Div id="webappSignUp" className="webappSignUp">

                <Div className="signUpTitleName" style={{fontWeight: 400, fontSize: 28, textAlign: "left"}}>
                    Create Account
                </Div>

                <Div
                    className="googleAfterpartyOauthContainer"
                    style={sty.googleAfterpartyOauthContainer}
                >
                    { this.showGoogleOAuthProvider() == 'popup' ?
                        <GoogleOAuthProvider
                            className="googleLoginButtonCustom2"
                            clientId={this.state.googleLoginClientId}
                        >
                            <GoogleLogin
                                onSuccess={responseMessage}
                                onError={errorMessage}
                                responseType="code"
                                flow='auth-code'
                                text="continue_with"
                                size="large"
                                shape="pill"
                                width={this.getGoogleButtonWidth()}
                            />
                            {/*

                            <GoogleCustomLoginButton
                                onSuccess={responseMessage}
                                onError={errorMessage}
                            />
                            */}
                        </GoogleOAuthProvider>
                        :
                        null
                    }

                </Div>
                <Div className="subheaderText" style={sty.subheaderText}>
                    Create New Account with Email
                </Div>

                { uiVersion != "240201" ?
                    <Div>
                        <Div style={{display: "flex", gap: 16, width: "100%"}}>
                            <Input
                                id="register.firstname"
                                inputType="placeholderLabel"
                                value={st.firstNameText}
                                style={sty.inputStyle}
                                placeholderLabelStyle={{width: "100%"}}
                                placeholder="First Name"
                                onChange={this.firstNameChange.bind(this)}
                            />

                            <Input
                                id="register.lastname"
                                inputType="placeholderLabel"
                                value={st.lastNameText}
                                style={sty.inputStyle}
                                placeholderLabelStyle={{width: "100%"}}
                                placeholder="Last Name"
                                onChange={this.lastNameChange.bind(this)}
                            />
                        </Div>
                    </Div>
                    :
                    <Div style={{display: "flex", gap: 16, width: "100%"}}>
                        <Input
                            id="register.full_name"
                            inputType="placeholderLabel"
                            value={st.fullNameText}
                            style={sty.inputStyle}
                            placeholderLabelStyle={{width: "100%"}}
                            placeholder="Name"
                            onChange={this.fullNameChange.bind(this)}
                        />
                    </Div>
                }


                { uiVersion != "240201" ?
                    <Div>
                        {/* NOTE: needed to add the wrapper and unique key to deal with react ref */}
                        <div key="uniqueKey2">
                            <input
                                id="register.phone"
                                className="internationalPhoneInput"
                                ref={this.phoneInputRefFunc}
                                type="tel"
                                value={st.phoneNumberText}
                                onChange={this.phoneNumberChange.bind(this)}
                                placeholder="Phone Number"
                                // style={{position: "absolute", zIndex: 10000}}
                            />
                        </div>

                        {this.renderPhoneNumberMessage()}
                    </Div>
                    :
                    null
                }

                <Input
                    id="register.email"
                    inputType="placeholderLabel"
                    value={st.emailText}
                    style={sty.inputStyle}
                    placeholder="Email Address"
                    onChange={this.emailChange.bind(this)}
                    type="email"
                />


                {/* <Div style={{marginTop: 20, marginBottom: 10, textAlign: "center"}}>
                    Birthday (enter numbers only)
                </Div>
                <Div style={{gap: 10, display: "flex", maxWidth: 287, marginBottom: 10}}>
                    <Input
                        inputType="placeholderLabel"
                        value={st.monthText}
                        style={sty.inputStyleBirthday}
                        placeholder="Month"
                        onChange={this.monthChange.bind(this)}
                    />
                    <Input
                        inputType="placeholderLabel"
                        value={st.dayText}
                        style={sty.inputStyleBirthday}
                        placeholder="Day"
                        onChange={this.dayChange.bind(this)}
                    />
                    <Input
                        inputType="placeholderLabel"
                        value={st.yearText}
                        style={sty.inputStyleBirthday}
                        placeholder="Year"
                        onChange={this.yearChange.bind(this)}
                    />
                </Div> */}

                <Input
                    id="register.password"
                    inputType="placeholderLabel"
                    value={st.passwordText}
                    style={sty.inputStyle}
                    placeholder="Password"
                    type="password"
                    onChange={this.passwordChange.bind(this)}
                />

                { uiVersion != "240201" ?
                    <Div className="agreeToTermsContainer" style={sty.agreeToTermsContainer}>
                        <Checkbox
                            id="register.termscheck"
                            color="white"
                            checked={st.agreeToTerms}
                            style={{marginLeft: 10, marginRight: 10}}
                            onClick={this.toggleAgreeToTerms.bind(this)}
                        />
                        <Div style={{display: "flex"}}>
                            I agree with &nbsp;<Button id="register.termslink" type="text" onClick={this.showTermsOfServiceModal.bind(this)}>terms of service</Button>
                        </Div>
                    </Div>
                    :
                    null
                }

                <Button
                    className="continueSignUpButton"
                    id="register.continue"
                    onClick={this.ticketSignUpSetPhone.bind(this)}
                    color="redGradient"
                    style={{width: "100%", marginBottom: 12}}
                    disabled={ uiVersion != "240201" ? (!st.agreeToTerms || st.resendCodePending) : false }
                >
                    Continue
                </Button>

                <Div className="bySigningUpMessage" style={{marginBottom: 20, color: "#8592A7"}}>
                    By signing up, you are creating an Afterparty Account and agree to Afterparty's <Button type="text" isSpan onClick={Utils.gotoRoute.bind(this, pr, "tos", "_blank")} style={{fontWeight: 400}}>Terms</Button> and <Button type="text" isSpan onClick={Utils.gotoRoute.bind(this, pr, "privacypolicy", "_blank")} style={{fontWeight: 400}}>Privacy Policy</Button>
                </Div>

                {this.renderPhoneEmailErrors()}

                {this.renderOrSeparator()}

                <Div className="toggleLoginButton" style={sty.toggleLoginButton}>
                    <Button
                        type="outlined"
                        onClick={this.loginToggle.bind(this)}
                        style={{width: "100%"}}
                    >
                        Already have an account? Log in
                    </Button>
                </Div>
            </Div>
        )

        if(st.showCheckEmailPage) {
            return this.renderShowCheckEmailPage();
        } else if(st.isLogin && Browser.isInInstagramApp()) {
            return loginInApp;
        } else if(st.isLogin) {
            return login;
        } else {
            return signUp;
        }
    }

    phoneInputRefFunc = (phoneInputRef) => {
        if(phoneInputRef) {
            this.telInput = intlTelInput(phoneInputRef, {
              // options here
              initialCountry: "us",
              preferredCountries: Constant.DEFAULT_COUNTRIES,
              formatOnDisplay: false,
              geoIpLookup: function(success, failure) {
                  fetch('https://ipinfo.io')
                    .then(response => response.json())
                    .then(json => success(json.country))
                    .catch(failure);
                },
                utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.js"
            });
        }
    }

    phoneInputRefLoginFunc = (phoneInputRefLogin) => {
        if(phoneInputRefLogin) {
            this.telInputLogin = intlTelInput(phoneInputRefLogin, {
              // options here
              initialCountry: "us",
              preferredCountries: Constant.DEFAULT_COUNTRIES,
              formatOnDisplay: false,
              geoIpLookup: function(success, failure) {
                  fetch('https://ipinfo.io')
                    .then(response => response.json())
                    .then(json => success(json.country))
                    .catch(failure);
                },
                utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.js"
            });
        }
    }

    phoneInputRefEditNumberFunc = (phoneInputRefEditNumber) => {
        if(phoneInputRefEditNumber) {
            this.telInputEditNumber = intlTelInput(phoneInputRefEditNumber, {
              // options here
              initialCountry: "us",
              preferredCountries: Constant.DEFAULT_COUNTRIES,
              formatOnDisplay: false,
              geoIpLookup: function(success, failure) {
                  fetch('https://ipinfo.io')
                    .then(response => response.json())
                    .then(json => success(json.country))
                    .catch(failure);
                },
                utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.js"
            });
        }
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let webappLoginRegisterElement = StyleUtils.getMStyle(sty, 'webappLoginRegisterElement', pr.screen.width, "md");

        return (
            <Div id="webappLoginAndRegisterElement" className="webappLoginRegisterElement autocompleteFix" style={{...sty.webappLoginRegisterElement, ...pr.style}}>
                {st.webappBody == "phoneEmailPrompt" ? this.renderPhoneEmailPrompt() : null}
                {st.webappBody == "verifyCode" ? this.renderVerifyCode() : null}
            </Div>
        )
    }

    styles = {
        webappLoginRegisterElement: {
            color: "white",
            backgroundColor: Colors.indigo,
            minHeight: "100vh",
            fontFamily: "Nyata",
            fontWeight: 300,
            padding: 0,
        },
        inputStyle: {
            marginBottom: 10,
        },
        inputStyleBirthday: {
        },
        errorMessageRowContainer: {
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            width: 250,
            textAlign: "center",
        },
        orSeparator: {
            fontSize: 32,
            fontWeight: 500,
            textAlign: "center",
            marginTop: 10,
            marginBottom: 10,
        },
        toggleLoginButton: {
            display: "flex",
            justifyContent: "center",
            marginTop: 13,
            marginBottom: 20,
            textAlign: "center",
        },
        agreeToTermsContainer: {
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 20,
        },
        editPhoneNumberContainer: {
            display: "flex",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 10,
        },
        phoneNumberTextStyle: {
            fontSize: 18,
            marginBottom: 10,
        },

        forgotPasswordContainer: {
            display: "flex",
            marginBottom: 20,
        },
        googleAfterpartyOauthContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "20px 0px",
            width: "100%",
        },
        orSeparatorContainer: {
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: 10,
            marginTop: 40,
        },
        whiteLineSeparator: {
            height: 1,
            width: "100%",
            backgroundColor: "#A0A0A0",
        },
        subheaderText: {
            fontSize: 18,
            paddingTop: 8,
            marginBottom: 16,
        },
        showCheckEmailPage: {
            display: "flex",
            flexDirection: "column",
        },
    }
}
