import React from 'react';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/utils';
import intlTelInput from 'intl-tel-input';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import Validate from '../modules/Validate';
import Errors from '../modules/Errors';
import Constant from '../modules/Constant';

import Button from './Button';
import Image from './Image';
import Modal from './Modal';
import Input from './Input';
import Checkbox from './Checkbox';
import Icon from './Icon';
import Span from './Span';
import Spinner from './Spinner';

import Div from '../components/Div';

import errorIcon from "../assets/images/ticketflow/error icon.png"
import circleCheck from "../assets/images/circle-check.png"
import verificationEmail from "../assets/images/verificationEmail.png"
import verificationPhone from "../assets/images/verificationPhone.png"

export default class EmailPhoneVerification extends React.Component {
    constructor(props) {
        super(props);

        const isEmail = props.startState == "startEmail" || !props.startState;

        const isEmailVerified = Utils.get(props, "user.is_email_verified") == 1;
        const isPhoneVerified = Utils.get(props, "user.is_phone_verified") == 1;

        const isUserVerified = isEmailVerified && isPhoneVerified;

        this.state = {
            emailText: "",
            phoneNumberText: "",
            verificationCode: "",

            emailState: isEmail ? "startEmail" : "startBoth", // startEmail, validatedEmail, confirmedEmail, startBoth
            phoneState: "startPhone", // startPhone, validatedPhone, confirmedPhone

            phoneEmailPromptErrors: [],

            isEmail: isEmail || props.startState == "startBoth",

            codeResetCountdown: 0,

            isLoadingConfirmation: false,

            countryCode: props.user.country_code ?? "us",
            phoneDialCode: props.user.phone_dial_code ?? "+1",

            hideAfterVerified: props.hideAfterVerified && isUserVerified,
            isSupportedCountryCode: true,
        }

        this.overrideMessages = {
            82846731: "This email has already been verified",
            82846732: "This phone has already been verified",
        };

        if(Utils.getRequest("fillin") == 17) {
            const randNum = Utils.randInt();
            this.state["phoneNumberText"] = `310555${randNum}`;
            this.state["emailText"] = `my${randNum}@aol.com`;
        }

        this.phoneInputRef = React.createRef();

        this.supportedCountryCodes = [
            'us', 'ca', 'mx', 'pr', 'ae', 'jp', 'kr', 'sg', 'ge', 'fr',
            'it', 'se', 'ua', 'de', 'ie', 'nl', 'pt', 'ch', 'gb', 'at',
            'dk', 'no', 'es', 'tr', 'ar', 'co', 'br', 'za', 'as', 'au',
            'nz', 'gu'
        ];
    }

    componentDidMount() {
        const pr = this.props;

        this.loadInitialProps(pr);

        this.chooseToDefaultVerification(pr);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.userLoaded != nextProps.userLoaded) {
            this.loadInitialProps(nextProps);

            this.chooseToDefaultVerification(nextProps)
        }
    }

    componentWillUnmount() {
        if (this.telInput) {
            const phoneInputRef = this.telInput._input;
            if(phoneInputRef) {
                phoneInputRef.removeEventListener('countrychange', this.handleCountryChange);
            }
        }
    }

    isUserVerified() {
        const user = this.props.user;
        const isEmail = Utils.get(user, "is_email_verified") == 1;
        const isPhone = Utils.get(user, "is_phone_verified") == 1;

        return isEmail && isPhone;
    }

    chooseToDefaultVerification(props) {
        if(Utils.get(props, "user.is_email_verified") == 1) {
            this.setState({
                emailState: "confirmedEmail",
            });
        }

        if(Utils.get(props, "user.is_phone_verified") == 1) {
            this.setState({
                phoneState: "confirmedPhone",
            });
        }
    }

    loadInitialProps(props) {
        if(props.userLoaded) {
            this.setState({
                emailText: Utils.get(props, "user.email", ""),
                phoneNumberText: Utils.get(props, "user.primary_phone1", ""),
                countryCode: props.user.country_code ?? "us",
                phoneDialCode: props.user.phone_dial_code ?? "+1",
            });
        }
    }

    countryCodeIsSupported() {
        return this.supportedCountryCodes.includes(this.state.countryCode);
    }

    switchVerifyMode(mode) {
        const st = this.state;

        const isEmail = mode == "email";

        // const emailText = isEmail ? "" : st.emailText;
        const emailText = st.emailText;
        // const phoneNumberText = !isEmail ? "" : st.phoneNumberText;
        const phoneNumberText = st.phoneNumberText;

        if(!isEmail) {
            if(this.countryCodeIsSupported()) {
                this.setState({
                    isSupportedCountryCode: true,
                })
            } else {
                this.setState({
                    phoneEmailPromptErrors: [{message: "SMS not supported in this country"}],
                    isSupportedCountryCode: false,
                })
            }
        }

        this.setState({
            isEmail: isEmail,
            emailState: "startEmail",
            phoneState: "startPhone",
            emailText: emailText,
            phoneNumberText: phoneNumberText,
            verificationCode: "",
            codeResetCountdown: 0,
        });
    }

    phoneNumberChange(e) {
        this.setState({phoneNumberText: e.target.value});
    }

    emailChange(e) {
        this.setState({emailText: e.target.value});
    }

    editEmail() {
        this.setState({
            emailState: "startEmail",
        });
    }

    editPhone() {
        this.setState({
            phoneState: "startPhone",
        });
    }

    handleKeyDown(e) {
        const st = this.state;

        if(!(st.phoneNumberText.length >= 3 || st.emailText.length >= 3)) {
            return;
        }

        if (e.key === 'Enter') {
            // this.ticketLoginSetPhone();
        }
    }

    smsVerificationEnabled() {
        const pr = this.props;

        return Utils.get(pr.user, "smsVerificationEnabled", 0);
    }

    verificationCodeNewChange(e) {
        this.setState({verificationCode: e.target.value});
    }

    getIntlPhoneNumber() {
        const st = this.state;

        let formattedPhoneNumber = "";

        formattedPhoneNumber = this.telInput.getNumber();

        const phoneDialCode = this.getPhoneDialCode();
        const countryCode = this.getCountryCode();

        this.setState({
            phoneDialCode: phoneDialCode,
            isSupportedCountryCode: this.countryCodeIsSupported(),
            countryCode: countryCode,
        });

        if(!formattedPhoneNumber.includes("+")) {
            formattedPhoneNumber = `+${phoneDialCode}${formattedPhoneNumber}`
        }

        return formattedPhoneNumber;
    }

    getPhoneDialCode() {
        const phoneDialCode = $(".iti__selected-flag").attr("title").split("+")[1];
        return phoneDialCode;
    }

    getCountryCode() {
        const countryCode = $(".iti__flag").attr("class").replaceAll("iti__", "").split(" ")[1];
        return countryCode;
    }

    triggerStripeIntent(sourceString) {
        const pr = this.props;
        // necessary to render StripePayment within Post.js right w/in paypal modal
        if (pr.triggerStripeIntent) {
            pr.triggerStripeIntent()
        }
    }

    resendVerificationCode() {
        const pr = this.props;
        const st = this.state;

        this.sendConfirmationCode();

        this.setState({
            codeResetCountdown: 10,
        }, () => {
            setInterval(() => {
                if(this.state.codeResetCountdown > 0) {
                    this.setState({
                        codeResetCountdown: this.state.codeResetCountdown - 1,
                    });
                }
            }, 1000);
        });

        if(this.state.codeResetCountdown <= 0) {
            return;
        }
    }

    sendConfirmationCode() {
        // NOTE: step 1 EMAIL OR PHONE
        const pr = this.props;
        const st = this.state;

        this.setState({
            phoneEmailPromptErrors: [],
        });
        const promptIsValid = st.isEmail ? Validate.email(st.emailText) : Validate.phone(st.phoneNumberText);

        if(!promptIsValid.success) {
            this.setState({
                phoneEmailPromptErrors: Errors.getErrorMessages(promptIsValid.errors, true, null, this.overrideMessages),
            });
            return;
        }

        let verifyData = {};
        if(st.isEmail) {
            verifyData.email = st.emailText;
            this.sendConfirmationCodeApiCall(verifyData);
        } else {
            verifyData.phone = this.getIntlPhoneNumber();

            // NOTE: need to validate phone with twilio
            Api.getPhoneValidation(verifyData.phone, (phoneResponse) => {
                if(Utils.get(phoneResponse, "errors", []).length) {
                    this.setState({
                        phoneEmailPromptErrors: Errors.getErrorMessages(phoneResponse.errors, true, verifyData.phone, this.overrideMessages),
                        resendCodePending: false,
                    });
                }

                let phoneFromTwilio = phoneResponse.data;
                if(this.smsVerificationEnabled()) {
                    verifyData.phone = phoneFromTwilio;
                }

                this.sendConfirmationCodeApiCall(verifyData);
            });
        }
    }

    sendConfirmationCodeApiCall(verifyData) {
        Api.postConfirmEmailPhone(verifyData, (res) => {
            if(Utils.get(res, "errors", []).length) {
                this.setState({
                    phoneEmailPromptErrors: Errors.getErrorMessages(res.errors, true, null, this.overrideMessages),
                });
                return;
            }

            // NOTE: token should automatically be sent
            this.setState({
                emailState: "validatedEmail",
                phoneState: "validatedPhone",
                phoneEmailPromptErrors: [],
            });
        });
    }

    confirmCode() {
        // NOTE: step 2
        const pr = this.props;
        const st = this.state;

        this.setState({
            isLoadingConfirmation: true,
        });

        let confirmCodeData = {
            code: st.verificationCode,
        }

        if(st.isEmail) {
            // NOTE: email payload
            confirmCodeData.email = st.emailText;
        } else {
            // NOTE: phone payload
            confirmCodeData.phone = this.getIntlPhoneNumber();
            confirmCodeData.phone_dial_code = this.getPhoneDialCode();
            confirmCodeData.country_code = this.getCountryCode();
        }
        Api.postVerifyCodeVerification(confirmCodeData, (res) => {
            setTimeout(() => {
                this.setState({
                    isLoadingConfirmation: false,
                });
            }, 2000);
            if(Utils.get(res, "errors", []).length) {
                const xxx = Errors.getErrorMessages(res.errors, true, null, this.overrideMessages);
                this.setState({
                    phoneEmailPromptErrors: Errors.getErrorMessages(res.errors, true, null, this.overrideMessages),
                });
                return;
            }

            Api.apiLoadProfile(pr.setUser.bind(this));

            this.setState({
                code: "",
                emailState: "confirmedEmail",
                phoneState: "confirmedPhone",
                phoneEmailPromptErrors: [],
            });
        });
    }

    renderContinueToNextPageButton() {
        const pr = this.props;

        let continueFunction = Utils.gotoRoute.bind(this, pr, "/home");

        if(pr.continueFunction == "hideContinueButton") {
            return null;
        } else if(pr.continueFunction) {
            continueFunction = pr.continueFunction;
        }

        return (
            <Button
                className="backToAfterpartyButton"
                onClick={continueFunction.bind(this)}
                color="pinkGradient"
                style={{width: "100%", marginBottom: 20}}
            >
                Continue
            </Button>
        )
    }

    renderVerificationHeaderImage() {
        const st = this.state;
        const sty = this.styles;

        if(st.isEmail) {
            return (
                <Image
                    className="verificationEmail verificationHeaderImage"
                    style={sty.verificationHeaderImage}
                    src={verificationEmail}
                />
            );
        } else {
            return (
                <Image
                    className="verificationPhone verificationHeaderImage"
                    style={sty.verificationHeaderImage}
                    src={verificationPhone}
                />
            );
        }

    }

    renderVerifyForFreeTokensText() {
        const sty = this.styles;

        return (
            <Div className="verifyTokensText" style={sty.verifyTokensText}>
                Verify For Free Messages
            </Div>
        )
    }

    renderVerifiedMethod(method) {
        const st = this.state;
        const sty = this.styles;

        let methodMessage = "";

        if(method == "email") {
            methodMessage = "Email address verified"
        } else {
            methodMessage = "Phone number verified"
        }

        return (
            <Div className="checkboxContainer" style={sty.checkboxContainer}>
                {this.renderStaticCheckbox()} {methodMessage}
            </Div>
        );
    }

    renderVerifyOtherMethod(method) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        // NOTE: everything in this method is inverted on purpose

        const isEmail = method == "email";

        const isMethodVerified = isEmail ? Utils.get(pr, "user.is_phone_verified") == 1 : Utils.get(pr, "user.is_email_verified") == 1;
        if(isMethodVerified) {
            return this.renderVerifiedMethod(isEmail ? "phone" : "email");
        }

        return (
            <>
                <Button
                    className="verifyOtherButton"
                    onClick={this.switchVerifyMode.bind(this, isEmail ? "phone" : "email")}
                    color="indigoLight"
                    style={sty.smallButtonStyle}
                    size="small"
                >
                    Verify your {isEmail ? "phone number" : "email address"} →
                </Button>
                {pr.showTokenBadges &&
                    <Div className="apTokens" style={sty.apTokens}>
                        +{isEmail ? "6" : "4"} messages
                    </Div>
                }
            </>
        );
    }

    renderPwaMethod() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        // NOTE: everything in this method is inverted on purpose

        // const isMethodVerified = isEmail ? Utils.get(pr, "user.is_phone_verified") == 1 : Utils.get(pr, "user.is_email_verified") == 1;
        // if(isMethodVerified) {
        //     return this.renderVerifiedMethod(isEmail ? "phone" : "email");
        // }

        return (
            <>
                <Button
                    className="verifyOtherButton"
                    onClick={Utils.gotoRoute.bind(this, pr, "/pwa")}
                    color="indigoLight"
                    style={sty.smallButtonStyle}
                    size="small"
                >
                    Install Afterparty App →
                </Button>
                {pr.showTokenBadges &&
                    <Div className="apTokens" style={sty.apTokens}>
                        +4 messages
                    </Div>
                }
            </>
        );
    }

    renderVerifySection() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="borderGradientContainer border-gradient-purple" style={sty.borderGradientContainer}>
                <Div className="border-gradient-inner" style={sty.borderGradientInner}>
                    {this.renderVerifyForFreeTokensText()}

                    {st.isLoadingConfirmation?
                        <Spinner />
                        :
                        <>
                            {this.renderVerifyOtherMethod("email")}
                            {this.renderVerifyOtherMethod("phone")}
                            {/* this.renderPwaMethod() */}
                        </>
                    }

                    {/* {this.renderVerifiedMethod("email")} */}
                    {/* {Utils.get(pr, "user.is_email_verified") == 1 ?
                        this.renderVerifiedMethod("email")
                        :
                        null
                    } */}
                </Div>
            </Div>
        );
    }

    renderTryAgain() {
        const st = this.state;

        if(st.codeResetCountdown > 0) {
            return `Try again in ${st.codeResetCountdown}s`;
        }

        return "Try Again →";
    }

    renderTryAgainButton() {
        const st = this.state;
        const sty = this.styles;

        return (
            <Button
                className="didntReceiveEmailCode"
                onClick={this.resendVerificationCode.bind(this)}
                color="indigoLight"
                style={sty.smallButtonStyle}
                size="small"
                disabled={st.codeResetCountdown > 0}
            >
                Didn't Receive the Code? {this.renderTryAgain()}
            </Button>
        );
    }

    renderStaticCheckbox() {
        return (
            <Image
                className="circleCheck"
                src={circleCheck}
                style={{width: 16, height: 16}}
            />
        )
    }

    renderSupportTicketMessage(mode) {
        const pr = this.props;
        const sty = this.styles;

        const errorLinkClick = {
            fontWeight: 700,
            textDecoration: "underline",
            cursor: "pointer",
        };

        return (
            <>
                This {mode} is already in use, if you're experiencing issues with verification please fill out a <Span style={errorLinkClick} onClick={Utils.gotoRoute.bind(this, pr, "/p/afterparty_contact_form")}>support ticket</Span>
            </>
        );
    }

    renderCustomMessage(error) {
        switch (error.id) {
            case 34758349788:
                return this.renderSupportTicketMessage("email");
            case 3438298643:
                return this.renderSupportTicketMessage("phone");
            default:
                return error.message;
        }
    }

    renderPhoneEmailErrors() {
        const st = this.state;
        const sty = this.styles;

        if(!st.phoneEmailPromptErrors) {
            return null;
        }

        return (
            <Div
                className="errorMessageRowContainer"
                style={sty.errorMessageRowContainer}
            >
                {st.phoneEmailPromptErrors.map((error, errorIndex) => {
                    let errorMessage = this.renderCustomMessage(error);

                    return (
                        <Div
                            key={`errorMessageRow-${errorIndex}`}
                            className="errorMessageRow"
                            style={sty.errorMessageRow}
                        >
                            <Image
                                src={errorIcon}
                                style={{width: 16, marginRight: 8}}
                            />
                            {errorMessage}
                        </Div>
                    )
                })}
            </Div>
        );
    }

    renderPhoneNumber() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div
                className="phoneNumberTextStyle"
                style={sty.phoneNumberTextStyle}
            >
                {Utils.formatPhoneNumber(st.phoneNumberText)}
            </Div>
        )
    }

    renderEmailVerifyBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let readOnlyInputStyle = null;

        if(st.emailState == "validatedEmail") {
            readOnlyInputStyle = {
                backgroundColor: Colors.indigo,
                border: "1px solid " + Colors.indigoLighter,
            }
        }

        switch (st.emailState) {
            case "startEmail":
                return (
                    <>
                        <Div className="emailTitle" style={{fontWeight: 500, fontSize: 28, textAlign: "center"}}>
                            Confirm Your Email
                            {/* <Span style={{color: Colors.neonGreen}}>&nbsp;"{st.emailState}"</Span> */}
                        </Div>

                        <Div className="mainSubText" style={sty.mainSubText}>
                            Please enter your email address for verification
                        </Div>

                        <Div
                            className="inputWithButtonInsideContainer"
                            style={sty.inputWithButtonInsideContainer}
                        >
                            <Input
                                inputType="placeholderLabel"
                                placeholderLabelStyle={{...readOnlyInputStyle, ...sty.inputStyle}}
                                value={st.emailText}
                                placeholder="Email Address"
                                onChange={this.emailChange.bind(this)}
                                type="email"
                            />
                        </Div>

                        {/* // NOTE: email Validate CONTINUE button */}
                        <Button
                            className="validateEmailButton"
                            onClick={this.sendConfirmationCode.bind(this)}
                            color="pinkGradient"
                            style={{width: "100%"}}
                            disabled={st.emailText.length <= 3}
                        >
                            Continue
                        </Button>

                        {pr.history.location.pathname == "/settings" ?
                            <Button
                                type="text"
                                style={{fontWeight: 400, marginTop: 16, textAlign: "center"}}
                                onClick={Utils.gotoRoute.bind(this, pr, "home")}
                            >
                                Back to Home
                            </Button>
                            :
                            null
                        }
                    </>
                );
            case "validatedEmail":
                return (
                    <>
                        <Div className="emailTitle" style={{fontWeight: 500, fontSize: 28, textAlign: "center"}}>
                            Confirm Your Email
                            {/* <Span style={{color: Colors.neonGreen}}>&nbsp;"{st.emailState}"</Span> */}
                        </Div>

                        <Div className="mainSubText" style={sty.mainSubText}>
                            Enter the code we sent to {st.emailText}, If you didn’t get the email check your junk folder.
                        </Div>

                        <Div
                            className="inputWithButtonInsideContainer"
                            style={sty.inputWithButtonInsideContainer}
                        >
                            <Input
                                inputType="placeholderLabel"
                                placeholderLabelStyle={{...readOnlyInputStyle, ...sty.inputStyle}}
                                value={st.emailText}
                                placeholder="Email Address"
                                onChange={this.emailChange.bind(this)}
                                type="email"
                                readOnly
                            />
                            <Button
                                onClick={this.editEmail.bind(this)}
                                size="small"
                                style={sty.editButton}
                            >
                                Edit Email
                            </Button>
                        </Div>

                        <Input
                            inputType="placeholderLabel"
                            value={st.verificationCode}
                            placeholderLabelStyle={sty.inputStyle}
                            placeholder="Verification Code"
                            onChange={this.verificationCodeNewChange.bind(this)}
                        />

                        <Button
                            className="confirmEmailButton"
                            onClick={this.confirmCode.bind(this)}
                            color="pinkGradient"
                            style={{width: "100%", marginBottom: 20}}
                            disabled={st.verificationCode.length <= 3}
                        >
                            Confirm Email
                        </Button>
                        {this.renderTryAgainButton()}
                    </>
                );
            case "confirmedEmail":
                return (
                    <>
                        <Div className="emailTitle" style={{fontWeight: 500, fontSize: 28, textAlign: "center"}}>
                            Success!
                            {/* <Span style={{color: Colors.neonGreen}}>&nbsp;"{st.emailState}"</Span> */}
                        </Div>

                        <Div className="mainSubText" style={sty.mainSubText}>
                            Thanks, your email address has now been verified.<br/>You can now return to Afterparty!
                        </Div>

                        {/* NOTE: confirmedEmail CONTINUE button */}
                        {this.renderContinueToNextPageButton()}

                        <Div className="orLineContainer" style={{display: "flex", alignItems: "center", gap: 12, marginBottom: 20, maxWidth: 336, marginLeft: "auto", marginRight: "auto"}}>
                            <Div className="orLine" style={sty.orLine} />
                            <Div style={sty.orText}>
                                OR
                            </Div>
                            <Div className="orLine" style= {sty.orLine} />
                        </Div>

                        {this.renderVerifySection()}
                    </>
                );
            case "startBoth":
                return (
                    <>
                        <Div className="orLineContainer" style={{display: "flex", alignItems: "center", gap: 12, marginBottom: 20, maxWidth: 336, marginLeft: "auto", marginRight: "auto"}}>
                            <Div className="orLine" style={sty.orLine} />
                            <Div style={sty.orText}>
                                OR
                            </Div>
                            <Div className="orLine" style= {sty.orLine} />
                        </Div>

                        {this.renderVerifySection()}
                    </>
                );
        }
    }

    renderPhoneVerifyBody() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let readOnlyInputStyle = null;

        if(st.phoneState == "validatedPhone") {
            readOnlyInputStyle = {
                backgroundColor: Colors.indigo,
                border: "1px solid " + Colors.indigoLighter,
            }
        }

        switch (st.phoneState) {
            case "startPhone":
                return (
                    <>
                        <Div className="phoneTitle" style={{fontWeight: 500, fontSize: 28, textAlign: "center"}}>
                            Confirm Your Phone
                            {/* <Span style={{color: Colors.neonGreen}}>&nbsp;"{st.phoneState}"</Span> */}
                        </Div>

                        <Div className="mainSubText" style={sty.mainSubText}>
                            Please enter your phone address for verification
                        </Div>

                        {/* NOTE: needed to add the wrapper and unique key to deal with react ref */}
                        <div key="uniqueKey2">
                            <input
                                className="internationalPhoneInput"
                                ref={this.phoneInputRefFunc}
                                type="tel"
                                value={st.phoneNumberText}
                                onChange={this.phoneNumberChange.bind(this)}
                                placeholder="Phone Number"
                            />
                        </div>

                        <Button
                            className="validatePhoneButton"
                            onClick={this.sendConfirmationCode.bind(this)}
                            color="pinkGradient"
                            style={{width: "100%"}}
                            disabled={st.phoneNumberText.length <= 3 || !st.isSupportedCountryCode}
                        >
                            Continue
                        </Button>
                    </>
                );
            case "validatedPhone":
                return (
                    <>
                        <Div className="phoneTitle" style={{fontWeight: 500, fontSize: 28, textAlign: "center"}}>
                            Confirm Your Phone
                            {/* <Span style={{color: Colors.neonGreen}}>&nbsp;"{st.phoneState}"</Span> */}
                        </Div>

                        <Div className="mainSubText" style={sty.mainSubText}>
                            Enter the code we sent to your phone number
                        </Div>

                        <Div
                            className="inputWithButtonInsideContainer"
                            style={sty.inputWithButtonInsideContainer}
                        >
                            <div key="uniqueKey2">
                                <input
                                    className="internationalPhoneInput"
                                    ref={this.phoneInputRefFunc}
                                    type="tel"
                                    value={st.phoneNumberText}
                                    onChange={this.phoneNumberChange.bind(this)}
                                    placeholder="Phone Number"
                                    readOnly
                                />
                            </div>
                            <Button
                                className="editPhoneButton"
                                onClick={this.editPhone.bind(this)}
                                size="small"
                                style={sty.editButton}
                            >
                                Edit Phone
                            </Button>
                        </Div>

                        <Input
                            inputType="placeholderLabel"
                            value={st.verificationCode}
                            placeholderLabelStyle={sty.inputStyle}
                            placeholder="Verification Code"
                            onChange={this.verificationCodeNewChange.bind(this)}
                        />

                        <Button
                            className="confirmPhoneNumber"
                            onClick={this.confirmCode.bind(this)}
                            color="pinkGradient"
                            style={{width: "100%", marginBottom: 20}}
                            disabled={st.verificationCode.length <= 0}
                        >
                            Confirm Phone Number
                        </Button>
                        {this.renderTryAgainButton()}
                    </>
                );
            case "confirmedPhone":
                return (
                    <>
                        <Div className="emailTitle" style={{fontWeight: 500, fontSize: 28, textAlign: "center"}}>
                            Success!
                            {/* <Span style={{color: Colors.neonGreen}}>&nbsp;"{st.phoneState}"</Span> */}
                        </Div>

                        <Div className="mainSubText" style={sty.mainSubText}>
                            Thanks, your phone number has now been verified.<br/>You can now return to Afterparty!
                        </Div>

                        {/* NOTE: confirmedPhone CONTINUE button */}
                        {this.renderContinueToNextPageButton()}

                        <Div style={{display: "flex", alignItems: "center", gap: 12, marginBottom: 20}}>
                            <Div style= {sty.orLine} />
                            <Div style={sty.orText}>
                                OR
                            </Div>
                            <Div style= {sty.orLine} />
                        </Div>

                        {this.renderVerifySection()}
                    </>
                );
        }
    }

    renderPhoneEmailPrompt() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(!pr.userLoaded) {
            return (
                <Spinner
                    containerStyle={{padding: 20, marginTop: 20}}
                    message="Loading verification system..."
                />
            )
        }

        const emailVerifyBody = (
            <Div className="emailVerifyBody">
                {this.renderEmailVerifyBody()}
                {this.renderPhoneEmailErrors()}
            </Div>
        )

        const phoneVerifyBody = (
            <Div className="phoneVerifyBody">
                {this.renderPhoneVerifyBody()}
                {this.renderPhoneEmailErrors()}
            </Div>
        )

        if(st.isEmail) {
            return emailVerifyBody;
        } else {
            return phoneVerifyBody;
        }
    }

    phoneInputRefFunc = (phoneInputRef) => {
        const st = this.state;
        if(phoneInputRef) {
            this.telInput = intlTelInput(phoneInputRef, {
              // options here
              initialCountry: this.state.countryCode,
              preferredCountries: Constant.DEFAULT_COUNTRIES,
              formatOnDisplay: false,
              geoIpLookup: function(success, failure) {
                  fetch('https://ipinfo.io')
                    .then(response => response.json())
                    .then(json => success(json.country))
                    .catch(failure);
                },
                utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.js"
            });

            phoneInputRef.addEventListener('countrychange', this.handleCountryChange.bind(this));
        }
    }

    handleCountryChange() {
        const countryData = this.telInput.getSelectedCountryData();
        console.log("Country code changed to: >>>>", countryData);
        // Update the component's state or perform other actions here
        this.setState({
            countryCode: countryData.iso2,
        }, () => {
            const isSupportedCountryCode = this.countryCodeIsSupported();
            this.setState({
                isSupportedCountryCode: isSupportedCountryCode,
                phoneEmailPromptErrors: isSupportedCountryCode ? [] : [{message: "SMS not supported in this country"}],
            })
        });
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if(st.hideAfterVerified) {
            return null;
        }

        return (
            <Div className="emailPhoneVerificationElement autocompleteFix" style={{...sty.emailPhoneVerificationElement, ...pr.style}}>
                {pr.showHeaderImage ? this.renderVerificationHeaderImage() : null}
                {this.renderPhoneEmailPrompt()}
            </Div>
        );
    }

    styles = {
        emailPhoneVerificationElement: {
            display: "flex",
            flexDirection: "column",
            color: "white",
            fontFamily: "Nyata",
            fontWeight: 300,
            padding: 16,
            width: "100%",
            maxWidth: 400,
        },
        inputStyle: {
            marginBottom: 20,
        },
        errorMessageRowContainer: {
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            width: 250,
            textAlign: "center",
        },
        orSeparator: {
            fontSize: 32,
            fontWeight: 500,
            textAlign: "center",
            marginTop: 10,
            marginBottom: 10,
        },
        mainSubText: {
            display: "flex",
            justifyContent: "center",
            marginTop: 13,
            marginBottom: 20,
            textAlign: "center",
        },
        editPhoneNumberContainer: {
            display: "flex",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 10,
        },
        phoneNumberTextStyle: {
            fontSize: 18,
            marginBottom: 10,
        },
        editButton: {
            borderRadius: 8,
            backgroundColor: Colors.indigoLight,
            position: "absolute",
            top: 8,
            right: 24,
        },
        inputWithButtonInsideContainer: {
            position: "relative",
        },
        smallButtonStyle: {
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 8
        },
        orText: {
            fontWeight: 400,
            fontSize: 24,
        },
        orLine: {
            width: "100%",
            height: 3,
            borderRadius: 10,
            backgroundColor: Colors.indigoLighter,
        },
        borderGradientContainer: {
            display: "flex",
            borderRadius: 20,
            padding: 3,
            maxWidth: 336,
            marginLeft: "auto",
            marginRight: "auto",
        },
        borderGradientInner: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 18,
            gap: 18,
            backgroundColor: Colors.indigo,
            padding: 20,
        },
        checkboxContainer: {
            display: "flex",
            gap: 8,
            justifyContent: "center",
            alignItems: "center",
        },
        apTokens: {
            borderRadius: 100,
            border: "1px solid #6321EF",
            background: "linear-gradient(180deg, #6321EF 0%, #8600B1 100%)",
            color: "white",
            width: "fit-content",
            padding: "4px 6px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: -24,
            fontSize: 12,
        },
        verifyTokensText: {
            fontSize: 16,
            fontWeight: 400,
        },
        verificationHeaderImage: {
            width: 80,
            height: 80,
            alignSelf: "center",
            marginBottom: 20,
        },
        errorLinkClick: {
            fontWeight: 700,
            textDecoration: "underline",
            cursor: "pointer",
        },
    }
}
