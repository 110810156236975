import React from 'react';
import ReactModal from 'react-modal';

import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import Utils from '../modules/Utils';

import Div from '../components/Div';

export default class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
        }
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    }

    getPurpleModal() {
        let sty = this.styles;
        sty.content.backgroundColor = Colors.plum;
        sty.content.color = "white";
        return sty;
    }

    getBlackModal() {
        let sty = this.styles;
        sty.content.backgroundColor = Colors.black;
        sty.content.color = "white";
        return sty;
    }

    getEggplantModal() {
        let sty = this.styles;
        sty.content.backgroundColor = Colors.eggplant;
        sty.content.color = "white";
        return sty;
    }

    getIndigoModal() {
        let sty = this.styles;
        sty.content.backgroundColor = Colors.indigo;
        sty.content.color = "white";
        return sty;
    }

    getIndigoLightModal() {
        let sty = this.styles;
        sty.content.backgroundColor = Colors.indigoLight;
        sty.content.color = "white";
        return sty;
    }

    getCustomStyle(customStyles) {
        let sty = this.styles;
        for(var idx in customStyles) {
            sty.content[idx] = customStyles[idx];
        }
        return sty;
    }

    render() {
        const pr = this.props;
        const st = this.state;
        let sty = this.styles;

        const color = Utils.get(pr, 'color');

        sty = color === "purple" ? this.getPurpleModal() : sty;
        sty = color === "black" ? this.getBlackModal() : sty;
        sty = color === "eggplant" ? this.getEggplantModal() : sty;
        sty = color === "indigo" ? this.getIndigoModal() : sty;
        sty = color === "indigoLight" ? this.getIndigoLightModal() : sty;
        if(color && color.substr(0,1) == "#") {
            let customStyle = this.styles;
            customStyle.content.backgroundColor = color;
            customStyle.content.color = "white";
            sty = customStyle;
        }

        let closeModalButtonColor = {color: Colors.magenta};

        if(color === "black") {
            closeModalButtonColor = {color: Colors.red};
        }

        if(color === "indigo" || pr.color === "eggplant" || pr.color === "indigoLight") {
            closeModalButtonColor = {color: "white"};
        }

        if(!color) {
            sty = this.getIndigoModal();
        }

        if(pr.style) {
            sty = this.getCustomStyle(pr.style);
        }

        let content = StyleUtils.getMediaStyle('content', sty.content, sty, StyleUtils.getWidthType(pr.screen.width, 'sm'));
        const closeModalButton = StyleUtils.getMediaStyle('closeModalButton', sty.closeModalButton, sty, StyleUtils.getWidthType(pr.screen.width));

        if(pr.fullScreen) {
            Object.assign(content, sty.fullScreen);
            sty.overlay.padding = 0;
            sty.content.position = "absolute";
        }

        if(pr.overlayStyle) {
            sty.overlay = {...sty.overlay, ...pr.overlayStyle};
        }

        if(!pr.screen.mobile && pr.fullScreen && pr.fullScreenLeft) {
            Object.assign(content, sty.fullScreenLeft);
            sty.overlay.padding = 0;
            sty.content.position = "absolute";
        }

        let bottom = null;
        if(pr.bottom) {
            sty.overlay = {...sty.overlay, ...{paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0}};
            content = {...content, ...{marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0}};
        }

        const customOverlayElement = (props, contentElement) => (
            <div {...props} id={pr.overlayId ?? ""}>
                {contentElement}
            </div>
        );

        return (
            <ReactModal
                overlayElement={customOverlayElement}
                {...pr}
                isOpen
                style={{...sty, ...{content: content}, ...bottom, ...pr.style}}
                className={`modalElement ${pr.className}`}
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
                preventScroll
            >
                {Utils.get(pr, "hideCloseButton", false) ?
                    null
                    // NOTE: leave this for now <Div style={{padding: 12}}></Div>
                    :
                    <Div
                        style={{...closeModalButton, ...closeModalButtonColor}}
                        onClick={Utils.get(pr, "onRequestClose", () => { }).bind(this)}
                    >
                        ×
                    </Div>
                }

                {pr.title ? <Div className="modalTitle" style={sty.modalTitle}>{pr.title}</Div> : null}
                {pr.children}
            </ReactModal>
        );
    }

    styles = {
        overlay: {
            position: "fixed",
            display: "flex",
            margin: "auto",
            alignItems: "center",
            justifyContent: "center",
            overflow: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            padding: "32px 10px 32px 10px",
            zIndex: 1055,
            overscrollBehavior: "none",
        },
        content: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            border: "none",
            width: "100%",
            maxWidth: this.props.wide ? 1055 : 700,
            borderRadius: 18,
            padding: "18px 34px 40px 34px",
            margin: "auto",
            backgroundColor: "white",
            outline: "none",
        },
        content_sm: {
            padding: "18px 20px 20px 20px",
        },
        closeModalButton: {
            display: "flex",
            marginLeft: "auto",
            fontSize: 32,
            cursor: "pointer",
            color: Colors.white,
            writable: true,
            marginTop: -10,
            marginRight: -15,
            fontFamily: "Montserrat",
            fontWeight: 500,
        },
        closeModalButton_sm: {
            marginRight: 3,
        },
        closeModalButton_xs: {
            marginRight: 3,
        },
        modalTitle: {
            fontWeight: 400,
            fontSize: 28,
            textAlign: "center",
            marginBottom: 10,
        },
        fullScreen: {
            // position: "absolute",
            width: "100%",
            maxWidth: "100%",
            minHeight: Utils.cssCalc(),
            borderRadius: 0,
        },
        fullScreenLeft: {
            left: 0,
            maxWidth: 515,

        }
    }
}
